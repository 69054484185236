import { useEffect, useState } from 'react';
import { useFlagsmith } from 'flagsmith/react';

import { getOldLiveAppBaseUrl } from './utils/application';
import { FEATURE_FLAGS, isFlagEnabled } from './utils/flagsmith';

interface FlagsmithSetupProps {
  accountId: string;
  children: React.ReactNode;
}

export function FlagsmithSetup({ accountId, children }: FlagsmithSetupProps) {
  const flagsmith = useFlagsmith();
  const [hasIdentifiedFlagsmith, setHasIdentifiedFlagsmith] = useState(false);

  useEffect(() => {
    if (!import.meta.env.PUBLIC_FLAGSMITH_ENV_ID) {
      setHasIdentifiedFlagsmith(true);
      return;
    }

    const handleIdentify = async () => {
      if (flagsmith.initialised && !flagsmith.getContext().identity) {
        await flagsmith.setContext({ identity: { identifier: accountId } });
        setHasIdentifiedFlagsmith(true);
      }
    };

    void handleIdentify();
  }, [accountId, flagsmith]);

  if (!hasIdentifiedFlagsmith) {
    return null;
  }

  // If the account doesn't have access, redirect to the old live app
  if (
    !isFlagEnabled(FEATURE_FLAGS.full_nextgen_access) &&
    !isFlagEnabled(FEATURE_FLAGS.only_nextgen_access) &&
    !import.meta.env.DEV
  ) {
    window.location.replace(getOldLiveAppBaseUrl());
    return null;
  }

  return children;
}
