import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon } from 'react-icons/hi2';
import { RichTextRenderer } from '@knack/asterisk-react';

import { type LiveAppPageRule } from '@/types/schema/LiveAppPage';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

interface PageRulesMessagesProps {
  pageRulesWithMessage: LiveAppPageRule[];
}

function PageRuleMessage({ pageRule }: { pageRule: LiveAppPageRule }) {
  const [t] = useTranslation();

  const { theme } = useThemingContext();

  const [isDismissed, setIsDismissed] = useState(false);

  const sanitizedValue = useMemo(() => sanitizeHTML(pageRule.message), [pageRule.message]);

  if (pageRule.action !== 'message') {
    return null;
  }

  if (isDismissed) {
    return null;
  }

  return (
    <div
      key={pageRule.key}
      className={cn('relative p-2', {
        'bg-destructive text-destructive': pageRule.type === 'alert',
        'bg-warning text-warning-default': pageRule.type === 'warning',
        'bg-subtle text-default': pageRule.type === 'neutral',
        'bg-success text-success-default': pageRule.type === 'confirmation',
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    >
      <RichTextRenderer
        dangerouslySetInnerHTML={{
          __html: sanitizedValue
        }}
      />

      {pageRule.close_link && (
        <button
          type="button"
          className={cn('absolute right-2 top-2 size-4 p-0', {
            'rounded-md': theme.appearance.corners === 'rounded'
          })}
          onClick={() => setIsDismissed(true)}
        >
          <CloseIcon size={14} />
          <span className="sr-only">{t('actions.close')}</span>
        </button>
      )}
    </div>
  );
}

export function PageRulesMessages({ pageRulesWithMessage }: PageRulesMessagesProps) {
  if (pageRulesWithMessage.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {pageRulesWithMessage.map((pageRule, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PageRuleMessage key={index} pageRule={pageRule} />
      ))}
    </div>
  );
}
