import { Spinner } from '@knack/asterisk-react';

import { type TableView } from '@/types/schema/views/TableView';
import {
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { ViewWithTableContent } from '@/components/views/common/ViewWithTableContent';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

export function TableViewRender({ sourceData }: { sourceData?: ViewRecordsResponse }) {
  const { view } = useViewContext<TableView>();
  const { params } = useViewQueryParamsContext();

  const { data: pagedViewRecords, isLoading } = useViewMultipleRecordsQuery({
    view,
    options: {
      rowsPerPage: params.rowsPerPage,
      page: params.page,
      ...(params.search && { search: params.search }),
      ...(params.sortOrder && { sortOrder: params.sortOrder }),
      ...(params.sortField && { sortField: params.sortField }),
      ...(params.filters && { filters: JSON.stringify(params.filters) })
    }
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ViewHeaderSection view={view} className="mb-4" />
      <ViewToolbarSection className="mb-4" />
      <ViewWithTableContent
        pagedViewRecords={pagedViewRecords}
        sourceData={sourceData}
        view={view}
      />
    </>
  );
}
