import { type KnackField } from '@/types/schema/KnackField';
import { type FormView, type FormViewInput } from '@/types/schema/views/FormView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { type TimeValues } from '@/components/views/form/inputs/date-time/types';
import { type Address } from './inputs/address/AddressInput';
import { type GoogleMapsAddressComponent, type GoogleMapsPlace } from './inputs/address/types';
import { getFormattedBooleanDefaultValue } from './inputs/boolean/helper';
import { getDefaultDatePayload } from './inputs/date-time/dateTimeUtils';
import { getDefaultTimerData } from './inputs/timer/helper';

export const formatTimeFromString = (
  value: string,
  is12HourFormat: boolean,
  mode: 'AM' | 'PM' | ''
): { time: TimeValues; timePeriodFormat: 'AM' | 'PM' } => {
  const [stringHours, stringMinutes] = value.split(':');
  const hours = parseInt(stringHours, 10);
  const minutes = parseInt(stringMinutes, 10);

  if (value === '') {
    return {
      time: { hours: 0, minutes: 0, am_pm: 'AM', rawTime: '00:00' },
      timePeriodFormat: 'AM'
    };
  }

  let time: TimeValues = {
    hours,
    minutes: minutes || 0,
    am_pm: 'AM',
    rawTime: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
  };
  let timePeriodFormat: 'AM' | 'PM' = mode === 'AM' ? 'AM' : 'PM';

  if (is12HourFormat) {
    // Convert hours from 24h format to 12h format
    const cappedHours = hours > 23 ? 0 : hours;
    const adjustedHours = cappedHours > 12 ? cappedHours - 12 : cappedHours;
    time = {
      hours: cappedHours,
      minutes: minutes || 0,
      am_pm: 'PM',
      rawTime: `${adjustedHours < 10 ? `0${adjustedHours}` : adjustedHours}:${
        minutes < 10 ? `0${minutes}` : minutes || '00'
      }`
    };
    timePeriodFormat = cappedHours > 12 ? 'PM' : 'AM';
  } else {
    // If hours is more than 23, set it to 23
    const cappedHours = hours > 23 ? 23 : hours;
    time = {
      hours: cappedHours,
      minutes: minutes || 0,
      am_pm: 'AM',
      rawTime: `${cappedHours < 10 ? `0${cappedHours}` : cappedHours}:${
        minutes < 10 ? `0${minutes}` : minutes || '00'
      }`
    };
  }

  return { time, timePeriodFormat };
};

export function formatGoogleAutocomplete(googleMapsPlace: GoogleMapsPlace): Address {
  const { lat, lng } = googleMapsPlace.geometry?.location?.toJSON() || {};
  const addressComponents = googleMapsPlace.address_components || [];

  const componentValues: Record<string, string> = {};

  addressComponents.forEach((component: GoogleMapsAddressComponent) => {
    if (component.types.includes('street_number') || component.types.includes('route')) {
      componentValues.street =
        (componentValues.street ? `${componentValues.street} ` : '') + component.long_name;
    }
    if (component.types.includes('locality')) {
      componentValues.city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      componentValues.state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      componentValues.zip = component.long_name;
    }
    if (component.types.includes('country')) {
      componentValues.country = component.long_name;
    }
  });

  return {
    street: componentValues.street || '',
    street2: '',
    city: componentValues.city || '',
    state: componentValues.state || '',
    zip: componentValues.zip || '',
    country: componentValues.country || '',
    latitude: lat && lng ? String(lat) : '',
    longitude: lat && lng ? String(lng) : ''
  };
}

export const formatConnectionInputIdentifier = (value: string | number) => {
  // The identifier can be a number, which means it should be converted to a string
  if (typeof value === 'number') {
    return value.toString();
  }

  // Otherwise, we just need to strip out any HTML tags
  const doc = new DOMParser().parseFromString(value, 'text/html');
  return doc.body.textContent || '';
};

export function getFormViewInputs(viewGroups: FormView['groups']) {
  return viewGroups.reduce<FormViewInput[]>((allInputs, group) => {
    const inputsInGroup = group.columns.reduce<FormViewInput[]>(
      (inputsInColumn, column) => [...inputsInColumn, ...column.inputs],
      []
    );
    return [...allInputs, ...inputsInGroup];
  }, []);
}

export function getDefaultInputValue(field: KnackField) {
  switch (field.type) {
    case 'short_text':
    case 'paragraph_text':
    case 'rich_text':
    case 'number':
    case 'currency':
    case 'phone':
      return field.default || '';
    case 'email': {
      return { email: '', label: '' };
    }
    case 'equation': {
      return '0';
    }
    case 'link': {
      return { url: field.default || '', label: '' };
    }
    case 'file':
    case 'image': {
      return '';
    }
    case 'date_time': {
      return getDefaultDatePayload(field.format);
    }
    case 'timer': {
      return getDefaultTimerData(field.format);
    }
    case 'name': {
      return {
        title: 'none',
        first: '',
        middle: '',
        last: ''
      };
    }
    case 'address': {
      return {
        street: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        latitude: '',
        longitude: ''
      };
    }
    case 'multiple_choice': {
      if (field.format.type === 'checkboxes' || field.format.type === 'multi') {
        return !field.format.default || field.format.default === 'kn-blank'
          ? []
          : [field.format.default];
      }
      return field.format.default || '';
    }
    case 'boolean': {
      if (field.format.input === 'checkbox') {
        return field.format.default;
      }
      return getFormattedBooleanDefaultValue(field.format.default, field.format);
    }
    case 'signature': {
      return null;
    }
    case 'connection': {
      return [];
    }
    default:
      return undefined;
  }
}

export const getDefaultFormValues = (inputs: FormViewInput[], sourceTableFields: KnackField[]) =>
  inputs.reduce<Record<string, any>>((acc, input) => {
    if (input.type === 'section_break' || input.type === 'divider') return acc;

    const field = sourceTableFields.find((f) => f.key === input.field.key);
    if (!field) return acc;

    return {
      ...acc,
      [input.field.key]: getDefaultInputValue(field)
    };
  }, {});

export const getNormalizedFormValuesFromRecord = (
  record: ViewRecord,
  inputs: FormViewInput[],
  sourceTableFields: KnackField[]
) => {
  const normalizedRecord: ViewRecord = { ...record };

  inputs.forEach((input) => {
    if (input.type === 'section_break' || input.type === 'divider') {
      return;
    }

    const field = sourceTableFields.find((f) => f.key === input.field.key);

    if (!field) {
      return;
    }

    // Sanitize record values for date/time field inputs
    if (field.type === 'date_time') {
      const dateRecord = record[input.field.key];
      const defaultDateTimeValue = getDefaultDatePayload(field.format);

      // If the date is not present, set it to the default value
      if (!dateRecord) {
        normalizedRecord[input.field.key] = defaultDateTimeValue;
      } else {
        normalizedRecord[input.field.key] = {
          ...dateRecord
        };
      }

      // If the field is set to use a range (field.format.calendar) and the 'to' date is not present, set it to the default value
      if (field.format.calendar && !dateRecord?.to) {
        normalizedRecord[input.field.key].to = defaultDateTimeValue.to;
      }
    }
  });

  return normalizedRecord;
};
