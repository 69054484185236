import { useCallback } from 'react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import {
  type DetailsDisplayRuleAction,
  type DetailsView,
  type DetailsViewDisplayRule
} from '@/types/schema/views/DetailsView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';

export interface FormattedDetailsDisplayRuleAction {
  renamedLabel: string;
  shouldHideField: boolean;
  valueStyles: {
    bgColor?: string;
    textColor?: string;
    bold?: boolean;
    italic?: boolean;
    strikethrough?: boolean;
  };
}

export function useDetailsDisplayRules(
  recordRawValues: ViewRecord | undefined,
  displayRules: DetailsViewDisplayRule[] | undefined
) {
  const { sourceTable } = useViewContext<DetailsView>();

  const getActiveDetailsDisplayRulesActions = useCallback(() => {
    const actions: DetailsDisplayRuleAction[] = [];

    if (!displayRules || !recordRawValues) {
      return actions;
    }

    displayRules.forEach((rule) => {
      if (isEveryCriteriaMet(recordRawValues, sourceTable.fields, rule.criteria)) {
        rule.actions.forEach((action) => {
          const isExistingType = actions.some((a) => a.action === action.action);

          // If the action type already exists, only keep the last one
          if (isExistingType) {
            const existingAction = actions.find((a) => a.action === action.action);

            if (existingAction) {
              if (existingAction.field === action.field) {
                actions.splice(actions.indexOf(existingAction), 1);
              }
            }
          }
          actions.push(action);
        });
      }
    });

    return actions;
  }, [displayRules, recordRawValues, sourceTable.fields]);

  const processDisplayRuleActions = useCallback(
    (fieldKey: KnackFieldKey): FormattedDetailsDisplayRuleAction => {
      const defaultRules: FormattedDetailsDisplayRuleAction = {
        renamedLabel: '',
        shouldHideField: false,
        valueStyles: {
          bgColor: '',
          textColor: '',
          bold: false,
          italic: false,
          strikethrough: false
        }
      };

      const activeDisplayRulesActions = getActiveDetailsDisplayRulesActions().filter(
        (ruleAction) => ruleAction.field === fieldKey
      );

      if (activeDisplayRulesActions.length) {
        const extractedDisplayRules = activeDisplayRulesActions.reduce((acc, action) => {
          switch (action.action) {
            case 'show':
              acc.shouldHideField = false;
              break;

            case 'hide':
              acc.shouldHideField = true;
              break;

            case 'label':
              acc.renamedLabel = action.value;
              break;

            case 'bg-color':
              acc.valueStyles.bgColor = action.value;
              break;

            case 'text-color':
              acc.valueStyles.textColor = action.color || '';
              break;

            case 'text-style':
              acc.valueStyles.bold = action.bold;
              acc.valueStyles.italic = action.italic;
              acc.valueStyles.strikethrough = action.strikethrough;
              break;

            default:
              break;
          }
          return acc;
        }, defaultRules);

        return extractedDisplayRules;
      }

      return defaultRules;
    },
    [getActiveDetailsDisplayRulesActions]
  );

  return {
    processDisplayRuleActions
  };
}
