import { type UniqueIdentifier } from '@dnd-kit/core';

import {
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { DetailsInputEditor } from './DetailsInputEditor';

interface DragOverlayContentProps {
  items: DetailsViewOuterColumn[];
  beingDraggedInputId: UniqueIdentifier | null;
  record: FormattedViewRecord;
}

function getInputById(
  outerColumns: DetailsViewOuterColumn[],
  beingDraggedInputId: UniqueIdentifier | null
) {
  let foundInput: DetailsViewInput | null = null;

  outerColumns.forEach((outerColumn) => {
    outerColumn.groups[0].columns[0].forEach((input) => {
      if (input.id === beingDraggedInputId) {
        foundInput = input;
      }
    });
  });

  return foundInput;
}

export function DragOverlayContent({
  items,
  record,
  beingDraggedInputId
}: DragOverlayContentProps) {
  const input = getInputById(items, beingDraggedInputId);

  if (!input) {
    return null;
  }

  return <DetailsInputEditor input={input} record={record} isDragOverlay />;
}
