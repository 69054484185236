import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  type FormDisplayRuleAction,
  type FormDisplayRuleActionType,
  type FormViewFieldInput,
  type FormViewRules
} from '@/types/schema/views/FormView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';

type FormDisplayRuleContextState = {
  activeDisplayRuleActions: FormDisplayRuleAction[];
  processDisplayRulesActions: (input: FormViewFieldInput) => {
    inputLabel: string;
    isHidden: boolean;
  };
};

type FormDisplayRulesContextProviderProps = {
  displayRules: FormViewRules;
  values: ViewRecord;
  children: React.ReactNode;
};

type ActionTypePriorityMap = {
  [key in FormDisplayRuleActionType]: number;
};

const FormDisplayRuleContext = createContext<FormDisplayRuleContextState | null>(null);

export function FormDisplayRulesContextProvider({
  displayRules,
  values,
  children
}: FormDisplayRulesContextProviderProps) {
  const { sourceTable } = useViewContext();
  const [activeDisplayRuleActions, setActiveDisplayRulesActions] = useState<
    FormDisplayRuleAction[]
  >([]);

  const displayRulesFields = displayRules?.fields || [];

  if (!sourceTable) {
    throw new Error(
      'FormDisplayRulesContextProvider must be used within an ViewContextProvider that provides a sourceTable'
    );
  }

  function getHighestImportanceConflictingRules(displayRulesActions: FormDisplayRuleAction[]) {
    const result: { [key: string]: FormDisplayRuleAction } = {};
    // Action types with a higher priority will take precedence over the rest
    const actionTypePriorityMap: Omit<ActionTypePriorityMap, 'label'> = {
      'hide-show': 1,
      'show-hide': 1,
      hide: 2,
      show: 2
    };

    displayRulesActions.forEach((action) => {
      const { field, action: actionType } = action;

      if (!result[field] || result[field].action === 'label' || actionType === 'label') {
        result[field] = action;
      } else {
        const currentActionType = result[field].action;
        const newActionType = actionType;

        // Choose the action with the highest importance
        if (actionTypePriorityMap[newActionType] < actionTypePriorityMap[currentActionType]) {
          result[field] = action;
        } else if (
          actionTypePriorityMap[newActionType] === actionTypePriorityMap[currentActionType]
        ) {
          // When the actions have the same importance, the last action in the array is the most important
          const lastAction = displayRulesActions.findLast((a) => a.field === field);

          result[field] = lastAction || action;
        }
      }
    });

    return Object.values(result);
  }

  const processDisplayRuleActions = useCallback(
    (displayRuleActions: FormDisplayRuleAction[], input: FormViewFieldInput) => {
      const highestImportanceConflictingRules =
        getHighestImportanceConflictingRules(displayRuleActions);

      const filteredValidActions = highestImportanceConflictingRules.filter((conflictingRule) =>
        highestImportanceConflictingRules.some(
          (conflictRule) =>
            conflictRule.field === conflictingRule.field &&
            conflictRule.action === conflictingRule.action
        )
      );

      const { inputLabel, isHidden } =
        filteredValidActions.reduce(
          (acc, action) => {
            if (action.field === input.field.key) {
              switch (action.action) {
                case 'hide':
                case 'hide-show':
                  acc.isHidden = true;
                  break;
                case 'label':
                  acc.inputLabel = action.value;
                  break;
                case 'show':
                case 'show-hide':
                  acc.isHidden = false;
                  break;
                default:
                  break;
              }
            }
            return acc;
          },
          { inputLabel: input.label || '', isHidden: false }
        ) || {};

      return { inputLabel, isHidden };
    },
    []
  );

  function replaceReactiveDisplayRuleAction(
    reactiveActions: FormDisplayRuleAction[],
    action: FormDisplayRuleAction
  ) {
    const { action: actionType, field, value } = action;

    const oppositeActionIndex = reactiveActions.findIndex(
      (a) => a.field === action.field && a.action === actionType
    );

    if (oppositeActionIndex !== -1) {
      reactiveActions.splice(oppositeActionIndex, 1);
    }

    reactiveActions.push({
      action: actionType === 'show-hide' ? 'hide-show' : 'show-hide',
      field,
      value
    });
  }

  const handleActiveDisplayRulesActionsChange = useCallback(
    (data: ViewRecord, rules: FormViewRules['fields']) => {
      const oneTimeActions: FormDisplayRuleAction[] = [];
      const reactiveActions: FormDisplayRuleAction[] = [];

      activeDisplayRuleActions.forEach((action) => {
        if (action.action === 'show' || action.action === 'hide' || action.action === 'label') {
          oneTimeActions.push(action);
        } else if (action.action === 'show-hide' || action.action === 'hide-show') {
          reactiveActions.push(action);
        }
      });

      rules.forEach((rule) => {
        if (isEveryCriteriaMet(data, sourceTable.fields, rule.criteria)) {
          rule.actions.forEach((action) => {
            // Check if the action already exists
            const isExistingAction = oneTimeActions.some(
              (oneTimeAction) =>
                oneTimeAction.field === action.field && oneTimeAction.action === action.action
            );

            if (isExistingAction) {
              return;
            }

            if (action.action === 'show-hide' || action.action === 'hide-show') {
              const isSameField = reactiveActions.some((a) => a.field === action.field);
              // If we have a reactive action affecting the same field, we only take the last action set in the Display Rules.
              if (isSameField) {
                const reactiveActionsIndex = reactiveActions.findIndex(
                  (a) => a.field === action.field
                );
                reactiveActions.splice(reactiveActionsIndex, 1);
                reactiveActions.push(action);
                return;
              }
              reactiveActions.push(action);
              return;
            }

            oneTimeActions.push(action);
          });
        } else {
          // For Reactive Actions, if the criteria is not met, we need to replace the action to the opposite one.
          rule.actions.forEach((action) => {
            if (action.action === 'show' || action.action === 'hide' || action.action === 'label') {
              return;
            }

            replaceReactiveDisplayRuleAction(reactiveActions, action);
          });
        }
      });

      const newActions = [...oneTimeActions, ...reactiveActions];
      // Prevent unnecessary updates if the actions haven't changed
      const haveActionsChanged =
        newActions.length !== activeDisplayRuleActions.length ||
        newActions.some(
          (action, index) =>
            action.field !== activeDisplayRuleActions[index]?.field ||
            action.action !== activeDisplayRuleActions[index]?.action
        );

      if (haveActionsChanged) {
        setActiveDisplayRulesActions(newActions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceTable.fields, activeDisplayRuleActions]
  );

  const processDisplayRules = useCallback(
    (input: FormViewFieldInput) => {
      if (!activeDisplayRuleActions.length) {
        return { inputLabel: input.label || '', isHidden: false };
      }
      return processDisplayRuleActions(activeDisplayRuleActions, input);
    },
    [activeDisplayRuleActions, processDisplayRuleActions]
  );

  useEffect(() => {
    if (displayRulesFields && displayRulesFields.length > 0) {
      const initialFormState = values;
      handleActiveDisplayRulesActionsChange(initialFormState, displayRulesFields);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, displayRulesFields]);

  const contextValue = useMemo(
    () => ({ activeDisplayRuleActions, processDisplayRulesActions: processDisplayRules }),
    [activeDisplayRuleActions, processDisplayRules]
  );

  return (
    <FormDisplayRuleContext.Provider value={contextValue}>
      {children}
    </FormDisplayRuleContext.Provider>
  );
}

export const useFormDisplayRulesContext = ({ enabled = true }: { enabled?: boolean }) => {
  const context = useContext(FormDisplayRuleContext);

  if (!enabled) {
    return null;
  }

  if (!context) {
    throw new Error(
      'useFormDisplayRulesContext must be used within an FormDisplayRulesContextProvider'
    );
  }

  return context;
};
