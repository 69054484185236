import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, useToast } from '@knack/asterisk-react';
import { type Cell } from '@tanstack/react-table';

import { useDeleteRecordMutation } from '@/hooks/api/mutations/useDeleteRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { useViewSubmitRules } from '@/hooks/useViewSubmitRules';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { useViewContext } from '@/components/views/ViewContext';
import { ActionButton } from './ActionButton';
import { DeleteRecordConfirmDialog } from './DeleteRecordConfirmDialog';
import { FallbackActionButton } from './FallbackActionButton';

interface ViewActionButtonProps {
  cell: Cell<ViewRecord, unknown>;
  column: ViewWithTableColumn;
}

export function TableActionButton({ column, cell }: ViewActionButtonProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { view, sourceTable } = useViewContext();
  const { getActivePagePathname } = usePageSegmentsHelpers();
  const { handleSubmitRuleAction } = useViewSubmitRules();

  const { mutate: deleteRecord } = useDeleteRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({ viewKey: view.key });

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const record = cell.row.original;
  const recordRaw = cell.getContext().table.options.meta?.rawValues[cell.row.index] as ViewRecord;
  const actionColumnIndex = cell.column.columnDef.meta?.actionColumnIndex;
  const shouldRenderTextLinkButton =
    column.type === 'link' && (!column.link_type || column.link_type === 'text');
  const shouldRenderFieldLinkButton = column.type === 'link' && column.link_type === 'field';

  const sanitizedValue = useMemo(
    () => sanitizeHTML(record?.[column.field?.key]),
    [column.field?.key, record]
  );

  if (!record.id) return null;

  const handleAction = (actionLinkIndex?: number) => {
    if (!record.id) return;

    switch (column.type) {
      case 'delete':
        deleteRecord(
          {
            viewKey: view.key,
            recordId: record.id
          },
          {
            onSuccess: () => {
              presentToast({
                title: t('components.views.table.record_delete_success')
              });
              setIsDeleteConfirmationModalOpen(false);
            },
            onError: () => {
              presentToast({
                title: t('components.views.table.record_delete_error'),
                intent: 'destructive'
              });
            }
          }
        );
        break;
      default:
        updateRecord(
          {
            data: {
              id: record.id,
              action_link_index: actionColumnIndex
            },
            recordId: record.id
          },
          {
            onSuccess: () => {
              if (actionLinkIndex === undefined || !column.action_rules) return;

              const applicableSubmitRule = column.action_rules[actionLinkIndex].submit_rules[0];

              if (!applicableSubmitRule) return;

              handleSubmitRuleAction({
                applicableRule: applicableSubmitRule,
                shouldShowToast: true
              });
            }
          }
        );
    }
  };

  if (shouldRenderTextLinkButton) {
    return (
      <ActionButton
        icon={column.icon}
        label={column.link_text}
        linkTo={`${getActivePagePathname()}/${column.scene}/${record.id}`}
      />
    );
  }

  if (shouldRenderFieldLinkButton) {
    if (!record?.[column.field?.key]) return null;

    return (
      <span className="flex items-center">
        <LabelWithIcon icon={column.icon?.icon} iconPosition={column.icon?.align || 'left'}>
          <Button intent="link" asChild>
            <Link
              to={`${getActivePagePathname()}/${column.scene}/${record.id}`}
              dangerouslySetInnerHTML={{ __html: sanitizedValue }}
            />
          </Button>
        </LabelWithIcon>
      </span>
    );
  }

  if (column.type === 'action_link') {
    if (!column.action_rules) return null;

    // When adding a Dynamic Action from the Page Editor, we need to display a temporary button until the user adds text to it.
    const isPageEditorAddingNewDynamicAction = isPageEditor() && column.action_rules.length === 0;

    if (isPageEditorAddingNewDynamicAction) {
      return <FallbackActionButton />;
    }

    let actionLinkIndex: number = 0;

    if (isPageEditor()) {
      // Don't check the criteria in the Page Editor and return the first action rule button text
      return <FallbackActionButton label={column.action_rules[0].link_text} />;
    }

    // Find the first set of rules that is met to show the button - we only display one button per cell
    const activeActionRule = column.action_rules.find((rule, index) => {
      actionLinkIndex = index;
      return (
        isEveryCriteriaMet(recordRaw, sourceTable?.fields || [], rule.criteria) ||
        rule.criteria.length === 0
      );
    });

    if (!activeActionRule) {
      return null;
    }

    return (
      <ActionButton
        onClick={() => handleAction(actionLinkIndex)}
        icon={column.icon}
        label={activeActionRule.link_text}
      />
    );
  }

  if (column.type === 'delete') {
    return (
      <DeleteRecordConfirmDialog
        isOpen={isDeleteConfirmationModalOpen}
        onOpenChange={setIsDeleteConfirmationModalOpen}
        onConfirm={handleAction}
      >
        <ActionButton
          icon={column.icon}
          label={column.link_text}
          isDelete
          onClick={() => {
            setIsDeleteConfirmationModalOpen(true);
          }}
        />
      </DeleteRecordConfirmDialog>
    );
  }

  return (
    <ActionButton onClick={() => handleAction()} icon={column.icon} label={column.link_text} />
  );
}
