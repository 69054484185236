import {
  formatViewRecordsResponse,
  type PagedViewRecords,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { isPageEditor } from '@/utils/iframe';
import { getEmptyColumns } from '@/components/views/common/table/helper';
import { SortableTableWrapper } from '@/components/views/common/table/sortable/SortableTableWrapper';
import { ViewTable } from '@/components/views/common/table/ViewTable';
import { EmptyColumnsFallback } from '@/components/views/table/EmptyColumnsFallback';
import { ViewFooterSection } from '@/components/views/view-footer/ViewFooterSection';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';
import { useThemingContext } from '@/context/ThemingContext';
import { type ViewWithTable } from './table/types';

type ViewWithTableContentProps = {
  pagedViewRecords?: PagedViewRecords;
  sourceData?: ViewRecordsResponse;
  view: ViewWithTable;
};

function getViewColumns(view: ViewWithTable) {
  if (view.type === 'search') {
    return view.results.columns;
  }

  return view.columns;
}

export function ViewWithTableContent({
  view,
  pagedViewRecords,
  sourceData
}: ViewWithTableContentProps) {
  const { theme } = useThemingContext();
  const { params } = useViewQueryParamsContext();

  const parsedTableData = sourceData ? formatViewRecordsResponse(sourceData) : pagedViewRecords;
  const formattedRecords = parsedTableData
    ? parsedTableData.records?.map((record) => ({
        values: record.values,
        rawValues: record.rawValues
      }))
    : [];

  // If we are in the page editor, we need to react to changes in the settings about showing a record limit
  const tableRecords =
    isPageEditor() && view.source.limit
      ? formattedRecords.slice(0, Number(view.source.limit))
      : formattedRecords;

  const { totalPages: totalPagesNumber = 1, totalRecords = 0 } = pagedViewRecords || {};

  // If we are in the page editor, we need to calculate the total records for the page editor and the total page number to be reactive
  const totalRecordsForPageEditor =
    Number(view.source.limit) > totalRecords ? totalRecords : Number(view.source.limit);
  const totalPageNumberForPageEditor = Math.ceil(
    (totalRecordsForPageEditor || totalRecords) / Number(params.rowsPerPage)
  );

  const columns = getViewColumns(view);
  const emptyColumnsKeys = getEmptyColumns(formattedRecords);
  const availableTableColumns =
    view.hide_empty && emptyColumnsKeys.length > 0
      ? columns.filter((column) => !emptyColumnsKeys.includes(column.field?.key))
      : columns;

  if (availableTableColumns.length === 0) {
    return <EmptyColumnsFallback theme={theme} />;
  }

  const shouldApplyFooterClasses = totalPagesNumber > 1 || view.allow_limit;
  const footerProps = {
    totalPageNumber: isPageEditor() ? totalPageNumberForPageEditor : totalPagesNumber,
    className: 'bg-default'
  };

  return isPageEditor() ? (
    <SortableTableWrapper
      columns={availableTableColumns}
      records={tableRecords}
      shouldApplyFooterClasses={shouldApplyFooterClasses}
      footer={<ViewFooterSection {...footerProps} />}
    >
      <ViewTable
        columns={availableTableColumns}
        records={tableRecords}
        shouldApplyFooterClasses={shouldApplyFooterClasses}
        footer={<ViewFooterSection {...footerProps} />}
      />
    </SortableTableWrapper>
  ) : (
    <ViewTable
      columns={availableTableColumns}
      records={tableRecords}
      shouldApplyFooterClasses={shouldApplyFooterClasses}
      footer={<ViewFooterSection {...footerProps} />}
    />
  );
}
