import { useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { InputWithDisabledText } from '@knack/asterisk-react';

import { type AutoIncrementField } from '@/types/schema/fields';

type AutoIncrementInputProps = {
  field: AutoIncrementField;
  intent: 'default' | 'destructive';
  formValueKey: string;
};

export function AutoIncrementInput({ intent, formValueKey }: AutoIncrementInputProps) {
  const { getValues, register, setValue } = useFormContext();

  const inputValue = getValues(formValueKey);
  const { ref: formRef, onBlur, ...formRegister } = register(formValueKey);

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: 0,
      padFractionalZeros: true
    },
    {
      defaultValue: inputValue,
      onAccept: (value: string) => {
        setValue(formValueKey, value);
      }
    }
  );

  return (
    <InputWithDisabledText
      ref={inputRef}
      intent={intent}
      value={typedValue !== undefined ? iMaskValue : inputValue}
      {...formRegister}
    />
  );
}
