import { Spinner } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import {
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { isPageEditor } from '@/utils/iframe';
import { SampleDataBadgeWhenLoginRequired } from '@/components/views/common/SampleDataBadgeWhenLoginRequired';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';
import { ListViewContent } from './ListViewContent';

export function ListViewRender({ sourceData }: { sourceData?: ViewRecordsResponse }) {
  const { view } = useViewContext<ListView>();
  const { params } = useViewQueryParamsContext();

  const { data: pagedViewRecords, isLoading } = useViewMultipleRecordsQuery({
    view,
    enabled: !sourceData,
    options: {
      rowsPerPage: params.rowsPerPage,
      page: params.page,
      ...(params.search && { search: params.search }),
      ...(params.filters && { filters: JSON.stringify(params.filters) })
    }
  });

  const shouldShowSampleDataBadge = isPageEditor() && view.source.authenticated_user;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="w-full">
      <ViewHeaderSection view={view} className="mb-4" />
      <ViewToolbarSection className="mb-4" />
      {shouldShowSampleDataBadge && <SampleDataBadgeWhenLoginRequired />}
      <ListViewContent view={view} pagedViewRecords={pagedViewRecords} sourceData={sourceData} />
    </div>
  );
}
