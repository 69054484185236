import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

export function DeleteRecordConfirmDialog({
  isOpen,
  onOpenChange,
  onConfirm,
  children
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  children: React.ReactNode;
}) {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content>
        <Dialog.MainContent className="space-y-6">
          <Dialog.Title>{t('components.views.table.delete_record')}</Dialog.Title>
          <Dialog.Description>
            {t('components.views.table.delete_record_confirmation')}
          </Dialog.Description>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button intent="destructive" data-testid="delete-record-button" onClick={onConfirm}>
            {t('components.views.table.delete_record')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
