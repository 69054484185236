import { memo, useEffect, useState } from 'react';
import type {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  pointerWithin
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import { type MenuView, type MenuViewLink } from '@/types/schema/views/MenuView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { MenuLinkDragOverlay } from './MenuLinkDragOverlay';
import { SortableMenuLink } from './SortableMenuLink';

function MenuLinksSortable({ view }: { view: MenuView }) {
  const { updatePage, setIsDraggingActive } = usePageEditorContext();
  const { optimizedSensors } = useDndUtils();

  // These are the links that are used for the drag and drop operations. They are stored in the state so that the UI can be updated when the items are reordered.
  const [sortableLinks, setSortableLinks] = useState<MenuViewLink[]>(view.links);

  // State for keeping track of the id of the link being dragged. Needed for rendering the dragging overlay
  const [beingDraggedLinkId, setBeingDraggedLinkId] = useState<UniqueIdentifier | null>(null);

  // Custom collision detection strategy
  const collisionDetectionStrategy: CollisionDetection = (args) => {
    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin({
      ...args
    });

    // If there are collisions with the pointer, return them
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // Otherwise, return a closestCorners collision detection
    return closestCorners({
      ...args
    });
  };

  const resetDraggingState = () => {
    setBeingDraggedLinkId(null);
    setIsDraggingActive(false);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setBeingDraggedLinkId(active.id);
    setIsDraggingActive(true);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!active || !over) {
      return;
    }

    const oldIndex = sortableLinks.findIndex((l) => l.id === active.id);
    const newIndex = sortableLinks.findIndex((l) => l.id === over.id);
    const updatedLinks = arrayMove(sortableLinks, oldIndex, newIndex);

    // Update the page with the updated links
    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: {
        ...view,
        links: updatedLinks
      }
    });

    // Update the local items state with the updated links for immediate UI update
    setSortableLinks(updatedLinks);

    resetDraggingState();
  };

  useEffect(() => {
    // Since the links can change from outside this dnd/sortable container (e.g. adding/deleting a link from the page editor),
    // we need to update the sortable items whenever the view changes to keep the order in sync
    setSortableLinks(view.links);
  }, [view]);

  return (
    <DndContext
      sensors={optimizedSensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={resetDraggingState}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.BeforeDragging
        }
      }}
    >
      <SortableContext items={sortableLinks.map((link) => link.id)}>
        {sortableLinks.map((link) => (
          <SortableMenuLink key={link.id} link={link} />
        ))}
      </SortableContext>

      <DragOverlay>
        <MenuLinkDragOverlay links={sortableLinks} beingDraggedLinkId={beingDraggedLinkId} />
      </DragOverlay>
    </DndContext>
  );
}

const memoized = memo(MenuLinksSortable);
export { memoized as MenuLinksSortable };
