import { useTranslation } from 'react-i18next';
import { type AppTheme } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export function EmptyColumnsFallback({ theme }: { theme: AppTheme }) {
  const [t] = useTranslation();

  return (
    <div
      className={cn('border border-dashed p-2 text-center', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    >
      {t('components.views.table.add_column_to_display')}
    </div>
  );
}
