import { type ViewRecordsResponse } from '@/hooks/api/queries/useViewMultipleRecordsQuery';

export const listDemoData: ViewRecordsResponse = {
  location_fail: false,
  total_pages: 40,
  current_page: 1,
  total_records: 1000,
  records: [
    {
      id: '66b23116ffa83100ac719f86',
      field_54: 'An example of short text',
      field_54_raw: 'An example of short text',
      field_22: 'Some short text',
      field_22_raw: 'Some short text',
      field_58: '$ 1,234.56',
      field_58_raw: '$ 1,234.56',
      field_69: '(+34) 888 888 888',
      field_69_raw: '(+34) 888 888 888',
      field_66: 'allison.lubin@knack.com',
      field_66_raw: 'allison.lubin@knack.com',
      field_65: '1234 Example St\nSeattle, WA\n98043',
      field_65_raw: '1234 Example St\nSeattle, WA\n98043',
      field_59: 'Silvain Whyley',
      field_59_raw: 'Silvain Whyley'
    },
    {
      id: '66b23116ffa83100ac719f25',
      field_54: 'An example of short text',
      field_54_raw: 'An example of short text',
      field_22: 'Some short text',
      field_22_raw: 'Some short text',
      field_58: '$ 1,234.56',
      field_58_raw: '$ 1,234.56',
      field_69: '(+34) 888 888 888',
      field_69_raw: '(+34) 888 888 888',
      field_66: 'allison.lubin@knack.com',
      field_66_raw: 'allison.lubin@knack.com',
      field_65: '1234 Example St\nSeattle, WA\n98043',
      field_65_raw: '1234 Example St\nSeattle, WA\n98043',
      field_59: 'Silvain Whyley',
      field_59_raw: 'Silvain Whyley'
    },
    {
      id: '66b23116ffa83100ac719f98',
      field_54: 'An example of short text',
      field_54_raw: 'An example of short text',
      field_22: 'Some short text',
      field_22_raw: 'Some short text',
      field_58: '$ 1,234.56',
      field_58_raw: '$ 1,234.56',
      field_69: '(+34) 888 888 888',
      field_69_raw: '(+34) 888 888 888',
      field_66: 'allison.lubin@knack.com',
      field_66_raw: 'allison.lubin@knack.com',
      field_65: '1234 Example St\nSeattle, WA\n98043',
      field_65_raw: '1234 Example St\nSeattle, WA\n98043',
      field_59: 'Silvain Whyley',
      field_59_raw: 'Silvain Whyley'
    },
    {
      id: '66b23116ffa83100ac719f52',
      field_54: 'An example of short text',
      field_54_raw: 'An example of short text',
      field_22: 'Some short text',
      field_22_raw: 'Some short text',
      field_58: '$ 1,234.56',
      field_58_raw: '$ 1,234.56',
      field_69: '(+34) 888 888 888',
      field_69_raw: '(+34) 888 888 888',
      field_66: 'allison.lubin@knack.com',
      field_66_raw: 'allison.lubin@knack.com',
      field_65: '1234 Example St\nSeattle, WA\n98043',
      field_65_raw: '1234 Example St\nSeattle, WA\n98043',
      field_59: 'Silvain Whyley',
      field_59_raw: 'Silvain Whyley'
    }
  ]
};
