import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowSmallLeft as ArrowIcon, HiDocument as DocumentIcon } from 'react-icons/hi2';
import { Link, type To } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

export function NotAuthorizedPage() {
  const [t, { language }] = useTranslation();

  return (
    <>
      <div className="mx-auto my-20 flex max-w-2xl flex-col gap-8 rounded-xl bg-card px-16 py-20 text-center sm:px-32">
        <div className="flex flex-col items-center gap-2">
          <DocumentIcon size={42} className="text-brand" />
          <h2 className="text-2xl font-medium text-emphasis">
            {t('components.not_authorized.title')}
          </h2>
          <p className="text-base">{t('components.not_authorized.message')}</p>
        </div>
        <Button asChild intent="primary">
          <Link to={-1 as To} className="flex gap-2">
            <ArrowIcon size={14} />
            {t('components.not_authorized.button_text')}
          </Link>
        </Button>
      </div>
      <Helmet>
        <title lang={language}>{`${t('titles.forbidden')} - ${t('titles.app_name')}`}</title>
      </Helmet>
    </>
  );
}
