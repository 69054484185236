import { useTranslation } from 'react-i18next';
import { Chip } from '@knack/asterisk-react';
import { snakeCase } from 'lodash';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { useViewFilters } from '@/hooks/useViewFilters';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { useViewContext } from '@/components/views/ViewContext';
import { type ViewWithQueryParams } from '@/components/views/ViewQueryParamsContext';

const filterLimit = 3;

export function ViewFiltersChips({ visibleFilters }: { visibleFilters: ViewFilters }) {
  const [t] = useTranslation();

  const { view, sourceTable } = useViewContext<ViewWithQueryParams>();
  const { removeFilter } = useViewFilters(view);

  const getFilterFieldName = (filter: KnackCriteria) =>
    sourceTable.fields.find((field) => field.key === filter.field)?.name || filter.field;

  return (
    <div className="hidden flex-wrap gap-2 md:flex" data-testid="view-filters-chips">
      {visibleFilters.rules.slice(0, filterLimit).map((filter, index) => {
        const filterField = sourceTable.fields.find((field) => field.key === filter.field);

        if (!filterField) {
          return null;
        }

        return (
          <Chip
            // eslint-disable-next-line react/no-array-index-key
            key={`${filter.field}-${index}`}
            className="m-0 h-9 text-xs font-normal"
            onClose={() => {
              removeFilter(index);
            }}
          >
            <p>
              <span className="font-semibold">{getFilterFieldName(filter)}</span>{' '}
              {t(`operators.${snakeCase(filter.operator)}`)}{' '}
              {!shouldHideValueBasedOnOperator(filter.operator) &&
                (filter.type ? (
                  <span className="font-semibold">
                    {filter.range && filter.range} {filter.type}
                  </span>
                ) : (
                  <InlineKnackValue
                    fieldKey={filter.field}
                    viewKey={view.key}
                    value={filter.value}
                  />
                ))}
            </p>
          </Chip>
        );
      })}
      {visibleFilters.rules.length > filterLimit && (
        <Chip
          className="m-0 h-9 text-xs"
          text={`+ ${visibleFilters.rules.length - filterLimit} ${t('keywords.more')}`}
        />
      )}
    </div>
  );
}
