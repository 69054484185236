import { Spinner } from '@knack/asterisk-react';

import { type DetailsView } from '@/types/schema/views/DetailsView';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import {
  useViewRecordQuery,
  type FormattedViewRecord
} from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { SampleDataBadgeWhenLoginRequired } from '@/components/views/common/SampleDataBadgeWhenLoginRequired';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { usePageContext } from '@/context/PageContext';
import { DetailsOuterColumnsSortableWrapper } from './details-columns-sortable/DetailsOuterColumnsSortableWrapper';

export function DetailsViewRender({ sourceData }: { sourceData?: FormattedViewRecord }) {
  const { activePageRecordId, recordIdFromParams } = usePageContext();
  const { view } = useViewContext<DetailsView>();

  const pageRecordId = activePageRecordId || recordIdFromParams;

  // If there is a record associated with the page, we use that record id to fetch the data
  const { data: viewRecordFromPage, isLoading: isViewRecordFromPageLoading } = useViewRecordQuery({
    view,
    enabled: !sourceData && !!pageRecordId
  });

  // If there is no record associated with the page, we use the record id associated with the view to fetch the data
  const { data: viewRecordFromView, isLoading: isViewRecordFromViewLoading } =
    useViewMultipleRecordsQuery({
      view,
      enabled: !sourceData && !pageRecordId
    });

  if (isViewRecordFromPageLoading || isViewRecordFromViewLoading) {
    return <Spinner />;
  }

  const shouldShowSampleDataBadge = isPageEditor() && view.source.authenticated_user;

  const detailsRecord = sourceData ||
    viewRecordFromPage ||
    viewRecordFromView?.records[0] || { values: {}, rawValues: {} };

  return (
    <div className="w-full">
      <ViewHeaderSection view={view} className="mb-4" />
      {shouldShowSampleDataBadge && <SampleDataBadgeWhenLoginRequired />}
      {isPageEditor() ? (
        <DetailsOuterColumnsSortableWrapper view={view} record={detailsRecord} />
      ) : (
        <DetailsOuterColumns record={detailsRecord} />
      )}
    </div>
  );
}
