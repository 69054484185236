import { type Row, type Table as TanstackTable } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { type InsertPositionState } from '@/components/views/common/table/sortable/SortableTableWrapper';
import { PageEditorTableRowRender } from './PageEditorTableRowRender';
import { TableRowRender } from './TableRowRender';

interface TableRowProps {
  table: TanstackTable<ViewRecord>;
  row: Row<ViewRecord>;
  level: number;
  rowSpacingClasses: string;
  insertPositionState?: InsertPositionState | null;
  shouldEnableInlineEditing: boolean;
}

export function TableRow(props: TableRowProps) {
  const { table } = props;

  const hasTableGrouping = table.getCanSomeRowsExpand();

  if (isPageEditor() && hasTableGrouping) {
    return <PageEditorTableRowRender {...props} />;
  }

  return <TableRowRender {...props} />;
}
