import { useEffect, useState } from 'react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { isPageEditor } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import { useMessagingContext } from '@/context/MessagingContext';
import { CalendarViewRender } from './CalendarViewRender';

function CalendarViewRenderPageEditor() {
  const { messageFromBuilder } = useMessagingContext();
  const { view } = useViewContext<CalendarView>();

  const [showEventForm, setShowEventForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (messageFromBuilder?.action === 'calendar-action') {
      if (messageFromBuilder.viewKey !== view.key) {
        return;
      }

      switch (messageFromBuilder.calendarAction) {
        case 'show-details':
          setShowDetails(true);
          break;
        case 'show-event-form':
          setShowEventForm(true);
          break;
        case 'hide-details':
          setShowDetails(false);
          break;
        case 'hide-event-form':
          setShowEventForm(false);
          break;
        default:
          break;
      }
    }
  }, [messageFromBuilder, view.key]);

  return <CalendarViewRender forceShowEventForm={showEventForm} forceShowDetails={showDetails} />;
}

export function CalendarRenderWrapper() {
  if (isPageEditor()) {
    return <CalendarViewRenderPageEditor />;
  }

  return <CalendarViewRender />;
}
