import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Badge, Tooltip } from '@knack/asterisk-react';

export function SampleDataBadgeWhenLoginRequired() {
  const [t] = useTranslation();

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Badge className="mb-4 flex items-center bg-blue-500/10 text-blue-500">
          <InfoIcon size={16} className="mr-1" />
          <span>{t('components.views.table.sample_data')}</span>
        </Badge>
      </Tooltip.Trigger>
      <Tooltip.Content>{t('components.views.table.login_to_view_records')}</Tooltip.Content>
    </Tooltip>
  );
}
