import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { IMask, useIMask } from 'react-imask';
import { Input } from '@knack/asterisk-react';

import { type PhoneField } from '@/types/schema/fields';

const EXTENSION_MASK = ' x00000';

const phoneFormatMapping = {
  '(999) 999-9999': '(000) 000-0000',
  '999.999.9999': '000.000.0000',
  '+99 999 999 99?99': '+00 000 000 0000',
  '+99 (0)999 999 99?99': '+00 (\\0)000 000 0000',
  '99 9999 9999': '00 0000 0000',
  '9999 999 999': '0000 000 000',
  any: undefined
} as const;

const emptyPlaceholders = {
  '(999) 999-9999': '(___) ___-____',
  '999.999.9999': '___.___.____',
  '+99 999 999 99?99': '+__ ___ ___ ____',
  '+99 (0)999 999 99?99': '+__ (0)___ ___ ____',
  '99 9999 9999': '__ ____ ____',
  '9999 999 999': '____ ___ ___',
  any: undefined
} as const;

type PhoneInputProps = {
  field: PhoneField;
  intent: 'default' | 'destructive';
  formValueKey: string;
};

export function PhoneInput({ field, intent, formValueKey }: PhoneInputProps) {
  const { setValue, register, getValues, watch } = useFormContext();

  const inputValue = getValues(formValueKey);
  const includeExtension = field.format.extension;
  const phoneFormat = includeExtension
    ? phoneFormatMapping[field.format.format] + EXTENSION_MASK
    : phoneFormatMapping[field.format.format];
  const placeholder = emptyPlaceholders[field.format.format];

  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      // When using lazy:false, the value will include the placeholder. It doesn't seem possible to get the value without the placeholder
      // So here we run the mask again without lazy manually to get the phone number without the placeholder characters
      const phoneMask = IMask.createPipe({
        mask: phoneFormat
      });
      return phoneMask(currentValue);
    },
    [phoneFormat]
  );

  const {
    ref: inputRef,
    typedValue,
    value: iMaskValue
  } = useIMask<HTMLInputElement>(
    {
      mask: phoneFormat,
      lazy: false,
      placeholderChar: '_'
    },
    {
      defaultValue: inputValue,
      onAccept: (value) => {
        const valueWithoutPlaceholder = getCurrentValueWithoutPlaceholder(value);
        setValue(formValueKey, valueWithoutPlaceholder);
      }
    }
  );

  const { ref: formRef, onBlur, ...formRegister } = register(formValueKey);

  if (field.format.format === 'any') {
    const formValue = watch(formValueKey);
    return (
      <Input
        intent={intent}
        placeholder={placeholder || ''}
        value={formValue}
        onChange={(e) => setValue(formValueKey, e.target.value)}
      />
    );
  }

  return (
    <Input
      ref={inputRef}
      placeholder={placeholder}
      intent={intent}
      value={typedValue !== undefined ? iMaskValue : inputValue}
      {...formRegister}
    />
  );
}
