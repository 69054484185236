import { type MenuView } from '@/types/schema/views/MenuView';

export const linkGroupDemoView: MenuView = {
  id: '665dde46059a2f04c2b6a739',
  links: [
    {
      id: '1',
      name: 'Home',
      url: '/',
      type: 'url',
      new_window: false,
      icon: {
        icon: 'home',
        align: 'left'
      },
      link_design_active: false
    },
    {
      id: '2',
      name: 'About Us',
      url: '/about',
      type: 'url',
      new_window: false,
      icon: {
        icon: 'info',
        align: 'left'
      },
      link_design_active: true
    },
    {
      id: '3',
      name: 'Services',
      url: '/services',
      type: 'url',
      new_window: false,
      icon: {
        icon: 'cog',
        align: 'left'
      },
      link_design_active: false
    },
    {
      id: '4',
      name: 'Contact',
      url: '/contact',
      type: 'url',
      new_window: false,
      icon: {
        icon: 'envelope',
        align: 'left'
      },
      link_design_active: false
    }
  ],
  inputs: [],
  groups: [],
  type: 'menu',
  name: 'Link Group',
  key: 'view_25',
  design: {},
  format: false,
  menu_links_design_active: true
};

export const linkGroupTabsDemoView: MenuView = {
  ...linkGroupDemoView,
  format: 'tabs'
};
