import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { ViewFiltersPopover } from '@/components/views/view-options/ViewFiltersPopover';

interface CalendarViewFiltersPopoverProps {
  view: CalendarView;
  sourceObject: KnackObjectResponse;
}

export function CalendarViewFiltersPopover({
  view,
  sourceObject
}: CalendarViewFiltersPopoverProps) {
  let eligibleCriteriaFields: KnackField[] = [];
  const filterFields = view.filter_fields;

  if (filterFields === 'view') {
    view.details.columns.forEach((outerColumn) => {
      outerColumn.groups.forEach((group) => {
        group.columns.forEach((innerColumn) => {
          innerColumn.forEach((input) => {
            if (input.type === 'field' && !input.connection) {
              const field = sourceObject.fields.find((f) => f.key === input.key);
              if (field) {
                eligibleCriteriaFields.push(field);
              }
            }
          });
        });
      });
    });
  }

  if (filterFields === 'object') {
    eligibleCriteriaFields = sourceObject.fields;
  }

  return <ViewFiltersPopover view={view} eligibleCriteriaFields={eligibleCriteriaFields} />;
}
