import { Table } from '@knack/asterisk-react';

import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { SampleDataBadgeWhenLoginRequired } from '@/components/views/common/SampleDataBadgeWhenLoginRequired';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { GroupingCollapsibleSection } from './grouping/GroupingCollapsibleSection';
import { TableHeadGroup } from './head/TableHeadGroup';
import { TableRow } from './row/TableRow';
import { type InsertPositionState } from './sortable/SortableTableWrapper';
import { TableSummaries } from './summaries/TableSummaries';
import { type ViewWithTable, type ViewWithTableColumn } from './types';
import { useViewTable } from './useViewTable';

interface TanstackTableProps {
  columns: ViewWithTableColumn[];
  records: FormattedViewRecord[];
  insertPositionState?: InsertPositionState | null;
  footer: React.ReactNode;
  shouldApplyFooterClasses?: boolean;
}

function isCellEditingEnabled(view: ViewWithTable) {
  if (view.type === 'table') {
    return view.options?.cell_editor ?? false;
  }
  return view.cell_editor ?? false;
}

export function ViewTable({
  columns,
  records,
  insertPositionState,
  footer,
  shouldApplyFooterClasses
}: TanstackTableProps) {
  const { view } = useViewContext<ViewWithTable>();
  const { theme } = useThemingContext();

  const { no_data_text: fallBackText, totals: columnSummaries = [] } = view;

  const { table, isGroupingEnabled } = useViewTable({
    tableColumns: columns,
    records,
    insertPositionState,
    columnSummaries
  });

  const hasRecords = records.length > 0;
  const hasSummaries = columnSummaries.length > 0;

  const tableMaxHeight = theme.tables.hasMaxHeight
    ? theme.tables.maxHeightPx
    : 'calc(100vh - 50px)';
  const rowSpacingClasses = cn({
    'px-2 py-1': theme.tables.rowSpacing === 'compact',
    'p-2': theme.tables.rowSpacing === 'default',
    'px-2 py-3': theme.tables.rowSpacing === 'comfortable',
    'hover:bg-subtle': theme.tables.hasHoverRowColor
  });

  const shouldShowSampleDataBadge = isPageEditor() && view.source.authenticated_user;

  return (
    <>
      {shouldShowSampleDataBadge && <SampleDataBadgeWhenLoginRequired />}
      {isGroupingEnabled && <GroupingCollapsibleSection table={table} />}

      <div
        className={cn('relative w-full overflow-hidden border', {
          'rounded-lg': theme.appearance.corners === 'rounded'
        })}
      >
        <div
          className={cn('overflow-auto border-b-0', {
            // If we have scrollbar, we need to remove the border radius from the last row
            '[&_th:nth-child(n+2):last-child]:rounded-none': theme.appearance.corners === 'rounded'
          })}
          style={{ maxHeight: tableMaxHeight }}
        >
          <Table className="w-full min-w-max border-separate border-spacing-0">
            <Table.Header className={cn({ 'sticky top-0 z-10': !isPageEditor() })}>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  <TableHeadGroup headers={headerGroup.headers} />
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body
              className={cn('w-full', {
                '[&_tr:last-child>td:first-child]:rounded-bl-lg [&_tr:last-child>td:last-child]:rounded-br-lg':
                  theme.appearance.corners === 'rounded' &&
                  !hasSummaries &&
                  !shouldApplyFooterClasses
              })}
            >
              {!hasRecords && !isPageEditor() && (
                <tr>
                  <td>
                    <p className="whitespace-nowrap p-2">{fallBackText}</p>
                  </td>
                </tr>
              )}
              {table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  table={table}
                  row={row}
                  level={0}
                  rowSpacingClasses={rowSpacingClasses}
                  insertPositionState={insertPositionState}
                  shouldEnableInlineEditing={isCellEditingEnabled(view)}
                />
              ))}
            </Table.Body>
            {hasSummaries && hasRecords && (
              <TableSummaries table={table} columnSummaries={columnSummaries} />
            )}
          </Table>
        </div>
        {footer && footer}
      </div>
    </>
  );
}
