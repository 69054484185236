import { type SearchView } from '@/types/schema/views/SearchView';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { ResultsDisplay } from './results-display/ResultsDisplay';
import { SearchOptions } from './search-options/SearchOptions';

export function SearchViewRender() {
  const { view } = useViewContext<SearchView>();

  return (
    <div className="flex flex-col gap-8">
      <ViewHeaderSection view={view} />
      <SearchOptions />
      <ResultsDisplay />
    </div>
  );
}
