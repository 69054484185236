import { GrFormViewHide as InvisibleFieldIcon } from 'react-icons/gr';
import { Tooltip } from '@knack/asterisk-react';

import { type DetailsView, type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { useDetailsDisplayRules } from '@/components/views/details/useDetailsDisplayRules';
import { useViewContext } from '@/components/views/ViewContext';
import { DetailsInputValue } from './DetailsInputValue';
import { getLabelStyleClassNames, getValueStylesClassNames } from './helper';

interface DetailsFieldInputProps {
  input: DetailsViewInput;
  record: FormattedViewRecord;
  isLastInGroup?: boolean;
}

export function DetailsFieldInput({ input, record, isLastInGroup }: DetailsFieldInputProps) {
  const { view, sourceTable } = useViewContext<ListView | DetailsView | MapView>();

  const { processDisplayRuleActions } = useDetailsDisplayRules(
    record ? record.rawValues : undefined,
    view.type !== 'map' ? view.rules?.fields : undefined
  );

  const shouldEnableHideFieldsOption = view.type !== 'map' ? view.hide_fields : false;
  const shouldHideFields = shouldEnableHideFieldsOption && !record?.values[input.key];
  const activeDisplayRuleActions = processDisplayRuleActions(input.key);

  if (activeDisplayRuleActions.shouldHideField) {
    return null;
  }

  if (!isPageEditor() && shouldHideFields) {
    return null;
  }

  const field = sourceTable.fields.find((f) => f.key === input.key);
  const isLabelOnTop =
    (view.type !== 'map' && view.label_format === 'top') || input.format?.label_format === 'top';
  const shouldRenderLabel =
    (view.type === 'map' && input.format?.label_custom && input.format?.label_format !== 'none') ||
    (view.type !== 'map' && view.label_format !== 'none' && input.format?.label_format !== 'none');

  const inputLabelClasses = cn(
    'bg-muted p-2 font-medium truncate',
    {
      'w-full': isLabelOnTop,
      'w-1/4 min-w-24 max-w-52 lg:min-w-32': !isLabelOnTop,
      'border-b border-default': isLabelOnTop || !isLastInGroup,
      'flex justify-between items-center': isPageEditor() && shouldHideFields
    },
    getLabelStyleClassNames(input, view)
  );
  const inputValueClasses = cn(
    'flex-1 break-normal p-2',
    {
      'w-full': isLabelOnTop,
      'min-h-8': isLabelOnTop || !shouldRenderLabel,
      'border-b border-default': !isLastInGroup
    },
    getValueStylesClassNames(input, activeDisplayRuleActions)
  );

  return (
    <div
      className={cn('flex', {
        'flex-wrap': isLabelOnTop
      })}
      key={input.id}
    >
      {shouldRenderLabel && (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <span className={inputLabelClasses}>
              {activeDisplayRuleActions.renamedLabel || input.label || input.name}
              {shouldHideFields && isPageEditor() && (
                <InvisibleFieldIcon className="text-subtle" size={16} />
              )}
            </span>
          </Tooltip.Trigger>
          <Tooltip.Content>
            {activeDisplayRuleActions.renamedLabel || input.label || input.name}
          </Tooltip.Content>
        </Tooltip>
      )}
      <DetailsInputValue
        className={inputValueClasses}
        record={record}
        input={input}
        field={field}
        style={{
          backgroundColor: activeDisplayRuleActions.valueStyles.bgColor || undefined,
          color: activeDisplayRuleActions.valueStyles.textColor || undefined
        }}
      />
    </div>
  );
}
