import { useTranslation } from 'react-i18next';
import { Spinner } from '@knack/asterisk-react';

import {
  type ChartData,
  type ReportView,
  type ReportViewChart,
  type ReportViewRow
} from '@/types/schema/views/ReportView';
import { useReportQuery } from '@/hooks/api/queries/useReportQuery';
import { isInternalBuilderIframe, isPageEditor, isPageEditorPreview } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { ChartGroupContainer } from './ChartGroupContainer';
import { ChartRender } from './ChartRender';
import { ChartsPageEditorPreview } from './charts-sortable/ChartsPageEditorPreview';
import { ChartsSortable } from './charts-sortable/ChartsSortable';

interface ReportViewRenderContentProps {
  charts: ReportViewChart[];
  layout: ReportViewRow['layout'];
  customChartsData?: ChartData[];
}

interface ReportViewRenderProps {
  customChartsData?: ChartData[];
}

function ReportViewRenderContent({
  charts,
  layout,
  customChartsData
}: ReportViewRenderContentProps) {
  const { view } = useViewContext<ReportView>();

  const { data: existingChartsData, isFetching } = useReportQuery({
    viewKey: view.key,

    // We disable this query if inside the builder, or if custom data is provided.
    // If iframed in the builder (page editor and page editor preview), we fetch the data for each chart individually (in SortableChartContext)
    enabled: !isInternalBuilderIframe() && !customChartsData
  });

  if (isFetching) {
    return <Spinner />;
  }

  const chartGroupData = customChartsData || existingChartsData;

  if (isPageEditor()) {
    return <ChartsSortable charts={charts} layout={layout} />;
  }

  if (isPageEditorPreview()) {
    return <ChartsPageEditorPreview charts={charts} layout={layout} />;
  }

  if (!chartGroupData || chartGroupData.length === 0) {
    return null;
  }

  return (
    <ChartGroupContainer layout={layout}>
      {charts.map((chart, index) => (
        <ChartRender key={chart.id} chart={chart} chartData={chartGroupData[index]} />
      ))}
    </ChartGroupContainer>
  );
}

export function ReportViewRender({ customChartsData }: ReportViewRenderProps) {
  const [t] = useTranslation();

  const { view } = useViewContext<ReportView>();

  // In next-gen apps, report views only have one row of reports/charts
  const charts = view.rows.length > 0 ? view.rows[0].reports : [];
  const rowLayout = view.rows[0].layout;

  if (charts.length === 0) {
    return (
      <div>
        <ViewHeaderSection view={view} className="mb-4" />
        {isPageEditor() && (
          <div className="py-4 text-subtle">{t('components.views.report.empty_report')}</div>
        )}
      </div>
    );
  }

  return (
    <div>
      <ViewHeaderSection view={view} className="mb-4" />
      <ReportViewRenderContent
        charts={charts}
        layout={rowLayout}
        customChartsData={customChartsData}
      />
    </div>
  );
}
