import { memo, useEffect, useState } from 'react';
import type {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  pointerWithin
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import {
  type ReportView,
  type ReportViewChart,
  type ReportViewRow
} from '@/types/schema/views/ReportView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { ChartGroupContainer } from '@/components/views/report/ChartGroupContainer';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { ChartDragOverlay } from './ChartDragOverlay';
import { SortableChart } from './SortableChart';
import { SortableChartContextProvider } from './SortableChartContext';

interface ChartsSortableProps {
  charts: ReportViewChart[];
  layout: ReportViewRow['layout'];
}

function ChartsSortable({ charts, layout }: ChartsSortableProps) {
  const { optimizedSensors } = useDndUtils();

  const { view } = useViewContext<ReportView>();
  const { updatePage, setIsDraggingActive } = usePageEditorContext();

  const [sortableCharts, setSortableCharts] = useState<ReportViewChart[]>(charts);
  const [beingDraggedChartId, setBeingDraggedChartId] = useState<UniqueIdentifier | null>(null);

  const collisionDetectionStrategy: CollisionDetection = (args) => {
    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin({
      ...args
    });

    // If there are collisions with the pointer, return them
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // Otherwise, return a closestCorners collision detection
    return closestCorners({
      ...args
    });
  };

  const resetDraggingState = () => {
    setBeingDraggedChartId(null);
    setIsDraggingActive(false);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setBeingDraggedChartId(active.id);
    setIsDraggingActive(true);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!active || !over) {
      return;
    }

    const oldIndex = sortableCharts.findIndex((chart) => chart.id === active.id);
    const newIndex = sortableCharts.findIndex((chart) => chart.id === over.id);
    const updatedCharts = arrayMove(sortableCharts, oldIndex, newIndex);

    // Update the page with the updated chart order
    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: {
        ...view,
        rows: [
          {
            ...view.rows[0],
            reports: updatedCharts
          }
        ]
      }
    });

    // Update the local charts with the updated charts for immediate UI update
    setSortableCharts(updatedCharts);

    resetDraggingState();
  };

  useEffect(() => {
    setSortableCharts(charts);
  }, [charts]);

  return (
    <DndContext
      sensors={optimizedSensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={resetDraggingState}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.BeforeDragging
        }
      }}
    >
      <SortableContext items={sortableCharts.map((chart) => chart.id)}>
        <ChartGroupContainer layout={layout}>
          {sortableCharts.map((chart) => (
            <SortableChartContextProvider key={chart.id} chart={chart}>
              <SortableChart key={chart.id} />
            </SortableChartContextProvider>
          ))}
        </ChartGroupContainer>
      </SortableContext>

      <DragOverlay modifiers={[restrictToWindowEdges]}>
        <ChartDragOverlay charts={sortableCharts} beingDraggedChartId={beingDraggedChartId} />
      </DragOverlay>
    </DndContext>
  );
}

const memoized = memo(ChartsSortable);
export { memoized as ChartsSortable };
