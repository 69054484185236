import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type DetailsView, type DetailsViewOuterColumn } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type SearchListView } from '@/types/schema/views/SearchView';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { DetailsColumn } from './DetailsColumn';

type DetailsOuterColumnsProps = {
  record: FormattedViewRecord;
  className?: string;
  onAfterDeleteAction?: () => void;
};

export function DetailsOuterColumns({
  record,
  className,
  onAfterDeleteAction
}: DetailsOuterColumnsProps) {
  const { view } = useViewContext<
    ListView | DetailsView | MapView | CalendarView | SearchListView
  >();

  let outerColumns: DetailsViewOuterColumn[] = [];
  if (view.type === 'map' || view.type === 'calendar') {
    outerColumns = view.details.columns;
  } else if (view.type === 'search') {
    outerColumns = view.results.columns;
  } else {
    outerColumns = view.columns;
  }

  return (
    <div className={cn('flex w-full flex-wrap gap-4 md:flex-nowrap', className)}>
      {outerColumns.map((outerColumn) => (
        <div
          key={outerColumn.id}
          className={cn('w-full min-w-0 sm:flex-1', {
            'sm:basis-1/2': outerColumn.width === 50,
            'sm:basis-1/3': outerColumn.width === 33,
            'sm:basis-2/3': outerColumn.width === 66,
            'sm:basis-1/4': outerColumn.width === 25,
            'sm:basis-3/4': outerColumn.width === 75
          })}
        >
          <DetailsColumn
            column={outerColumn.groups[0].columns[0]}
            record={record}
            onAfterDeleteAction={onAfterDeleteAction}
          />
        </div>
      ))}
    </div>
  );
}
