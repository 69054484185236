import { useEffect, useRef, useState } from 'react';
import { Table, Tooltip } from '@knack/asterisk-react';
import truncate from 'lodash/truncate';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import {
  calculateCharactersInWidth,
  hasElementExceededMaxLines
} from '@/components/views/common/table/helper';

export const CELL_MAX_WIDTH_IN_PX = 140;

export const tableCellClasses =
  'border-x-0 bg-muted p-2 text-emphasis hover:bg-muted border-b border-default';

interface TableViewSummaryLabelProps {
  summaryValue: string | number;
  label: string;
  align: TableViewColumn['align'];
}

export function TableViewSummaryLabel({ summaryValue, label, align }: TableViewSummaryLabelProps) {
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [shouldTruncate, setShouldTruncate] = useState(false);

  const maxNumberCharacters = calculateCharactersInWidth(CELL_MAX_WIDTH_IN_PX);

  useEffect(() => {
    if (cellRef.current) {
      setShouldTruncate(hasElementExceededMaxLines(cellRef.current));
    }
  }, [label]);

  if (label.length > maxNumberCharacters) {
    return (
      <Tooltip>
        <Tooltip.Trigger className="font-normal" asChild>
          <Table.Head ref={cellRef} className={tableCellClasses}>
            <div className="flex items-center justify-between gap-2">
              <span>
                {shouldTruncate
                  ? truncate(label, {
                      length: maxNumberCharacters
                    })
                  : label}
              </span>
              {summaryValue && summaryValue}
            </div>
          </Table.Head>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content align="start">{label}</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    );
  }
  return (
    <Table.Cell className={tableCellClasses} style={{ textAlign: align }}>
      <div className="flex items-center justify-between gap-2">
        <span>{label}</span>
        {summaryValue && summaryValue}
      </div>
    </Table.Cell>
  );
}
