import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineDotsVertical as DotsIcon } from 'react-icons/hi';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import type { LiveAppPage } from '@/types/schema/LiveAppPage';
import { cn } from '@/utils/tailwind';
import { NavBrand } from '@/components/main-navigation/NavBrand';
import { NavItemProfile } from '@/components/main-navigation/NavItemProfile';
import { useParsedEntryPages } from '@/components/main-navigation/useNavigation';
import { useThemingContext } from '@/context/ThemingContext';
import { NavHorizontalItem } from './NavHorizontalItem';

export function NavHorizontalContainer({
  containerclasses,
  navBrandRef,
  pageKeyToIgnore
}: {
  containerclasses: string;
  navBrandRef: React.RefObject<HTMLDivElement>;
  pageKeyToIgnore?: LiveAppPage['key'];
}) {
  const { theme } = useThemingContext();
  const headerRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const entryPages = useParsedEntryPages(pageKeyToIgnore);

  const [visibleItems, setVisibleItems] = useState<LiveAppPage[]>([]);
  const [hiddenItems, setHiddenItems] = useState<LiveAppPage[]>([]);
  const [isMeasuring, setIsMeasuring] = useState(true);

  const pagesWithoutParent = useMemo(
    () => entryPages.filter((page) => !page.menuPageKey && page.type !== 'user'),
    [entryPages]
  );

  const measureItems = useCallback(() => {
    if (
      !headerRef.current ||
      !navBrandRef.current ||
      !navRef.current ||
      navRef.current?.children.length === 0
    ) {
      return;
    }

    const headerWidth = headerRef.current.offsetWidth;
    const brandWidth = navBrandRef.current.offsetWidth;
    let navCurrentWidth = 0;
    const newVisibleItems: LiveAppPage[] = [];
    const newHiddenItems: LiveAppPage[] = [];

    // First, measure widths of all items
    const itemElements: HTMLCollection = navRef.current.children;
    Array.from(itemElements).forEach((itemElement, index) => {
      const itemWidth = itemElement.clientWidth + 32; // 32px gap between items
      const item = pagesWithoutParent[index];

      if (navCurrentWidth + itemWidth < headerWidth - brandWidth - 50) {
        // 50px space for the profile button
        newVisibleItems.push(item);
        navCurrentWidth += itemWidth;
      } else {
        newHiddenItems.push(item);
      }
    });

    setVisibleItems(newVisibleItems);
    setHiddenItems(newHiddenItems);
    setIsMeasuring(false);
  }, [navBrandRef, pagesWithoutParent]);

  useEffect(() => {
    // Trigger a re-measurement when the nav pages change
    setIsMeasuring(true);
  }, [pagesWithoutParent]);

  useLayoutEffect(() => {
    if (isMeasuring) {
      measureItems();
    }

    const handleResize = () => {
      setIsMeasuring(true); // Trigger re-measurement
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMeasuring, measureItems]);

  return (
    <header data-testid="header" ref={headerRef} className={cn(containerclasses, 'kn-header')}>
      <div className="kn-main-nav-horizontal-container flex items-center justify-between">
        <NavBrand navBrandRef={navBrandRef} />
        <div className="flex items-center">
          {theme.mainNavContainer.navigationMenu.isVisible && (
            <nav ref={navRef} className="kn-main-nav flex items-center gap-4 text-center">
              {(isMeasuring ? pagesWithoutParent : visibleItems).map((item) => (
                <NavHorizontalItem key={item.key} item={item} allItems={entryPages} />
              ))}
              {!isMeasuring && hiddenItems.length > 0 && (
                <DropdownMenu>
                  <DropdownMenu.Trigger asChild>
                    <Button intent="minimal" size="sm">
                      <Button.Icon icon={DotsIcon} />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    {hiddenItems.map((item) => (
                      <NavHorizontalItem
                        key={item.key}
                        item={item}
                        allItems={entryPages}
                        shouldUseSubmenu
                      />
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu>
              )}
            </nav>
          )}
          <div className="ml-4">
            <NavItemProfile />
          </div>
        </div>
      </div>
    </header>
  );
}
