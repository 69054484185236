import { useCallback } from 'react';

import { type KnackField } from '@/types/schema/KnackField';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { type ViewWithQueryParams } from '@/components/views/ViewQueryParamsContext';
import { useCriteriaHelpers } from './useCriteriaHelpers';

export function useViewFiltersHelpers() {
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const getDefaultFilters = useCallback(
    (eligibleFilterCriteriaFields: KnackField[]) => {
      // If there are no eligible fields, return a filter with empty rules
      if (eligibleFilterCriteriaFields.length === 0) {
        const defaultFilters: ViewFilters = {
          match: 'and',
          rules: []
        };

        return defaultFilters;
      }

      const criteriaField = eligibleFilterCriteriaFields[0];
      const defaultFilters: ViewFilters = {
        match: 'and',
        rules: [
          {
            field: criteriaField.key,
            operator: getDefaultCriteriaOperator(criteriaField),
            value: getDefaultCriteriaValue(criteriaField)
          }
        ]
      };

      return defaultFilters;
    },
    [getDefaultCriteriaOperator, getDefaultCriteriaValue]
  );

  const getPresetFilters = useCallback((view: ViewWithQueryParams) => {
    if (view.type === 'search') {
      return undefined;
    }

    const allowsPresetFilters =
      view.type === 'map' ? view.details.allow_preset_filters : view.allow_preset_filters;
    const filterType = view.type === 'map' ? view.details.filter_type : view.filter_type;
    const shouldHavePresetFilters =
      allowsPresetFilters && filterType !== 'menu' && filterType !== 'none';

    if (!shouldHavePresetFilters) {
      return undefined;
    }

    const presetFilterCriteria =
      view.type === 'map' ? view.details.preset_filters : view.preset_filters;

    if (!presetFilterCriteria || presetFilterCriteria.length === 0) {
      return undefined;
    }

    const presetFilter: ViewFilters = {
      match: 'and',
      rules: presetFilterCriteria
    };

    return presetFilter;
  }, []);

  const getInitialMenuFilters = useCallback((view: ViewWithQueryParams) => {
    if (view.type === 'search') {
      return undefined;
    }

    const allowsPresetFilters =
      view.type === 'map' ? view.details.allow_preset_filters : view.allow_preset_filters;
    const filterType = view.type === 'map' ? view.details.filter_type : view.filter_type;
    const shouldHaveMenuFilters = allowsPresetFilters && filterType === 'menu';

    if (!shouldHaveMenuFilters) {
      return undefined;
    }

    const menuFiltersRules = view.type === 'map' ? view.details.menu_filters : view.menu_filters;

    if (!menuFiltersRules || menuFiltersRules.length === 0) {
      return undefined;
    }

    // We only need the first menu filter rule, since that's the one that will be active by default
    const firstMenuFilterRule = menuFiltersRules[0];
    const menuFiltersFormatted: ViewFilters = {
      match: 'and',
      rules: [
        {
          field: firstMenuFilterRule.field,
          operator: firstMenuFilterRule.operator,
          value: firstMenuFilterRule.value
        }
      ]
    };

    return menuFiltersFormatted;
  }, []);

  return {
    getDefaultFilters,
    getPresetFilters,
    getInitialMenuFilters
  };
}
