import { DateTime } from 'luxon';

import { type DateTimeField, type DateTimeFieldFormat } from '@/types/schema/fields';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type CalendarFormDateDefaultValues } from '@/components/views/calendar/type';
import { defaultDateFormatMap } from './types';

export type DateConvertedFormat = 'none' | 'dd/MM/yyyy' | 'MM/dd/yyyy';

export function getInitialIsAnyDate(initialValue: KnackCriteria['value']) {
  if (initialValue && typeof initialValue === 'object' && 'date' in initialValue) {
    return initialValue.date === '';
  }

  return false;
}

export function getInitialIsAnyTime(initialValue: KnackCriteria['value']) {
  if (initialValue && typeof initialValue === 'object' && 'time' in initialValue) {
    return (
      initialValue.time === '' &&
      initialValue.minutes === '' &&
      initialValue.hours === '' &&
      initialValue.am_pm === ''
    );
  }

  return false;
}

export const checkIsValidDate = (value: string, dateFormat: DateConvertedFormat) =>
  DateTime.fromFormat(value, dateFormat).isValid ||
  DateTime.fromFormat(value, dateFormat.replace('yyyy', 'yy')).isValid;

export function isManualDateInputValid(inputValue: string) {
  // This regex does the following:
  // - Allows only numbers and slashes
  // - Disallows a double slash
  const regex = /^(?!.*\/\/)[0-9/]*\/?$/;
  return regex.test(inputValue);
}

export const getFormattedDatePickerDate = (date: string, dateFormat: DateConvertedFormat) =>
  !date || !checkIsValidDate(date, dateFormat)
    ? DateTime.now().toJSDate()
    : DateTime.fromFormat(date, dateFormat.replace('yyyy', 'yy')).toJSDate();

export const isTimeStringInMilitaryFormat = (timeString: string) => {
  // Check if the time contains AM or PM (12-hour format)
  const is12HourFormat = /[APMapm]/.test(timeString);

  // If AM or PM is present, it's a 12-hour format
  if (is12HourFormat) {
    return false;
  }

  // If no AM/PM and hour is <= 12, assume it's still 24-hour format (e.g., "05:00" can be 24-hour)
  return true;
};

export const getRepeatDefaultOptions = () => ({
  frequency: 'daily',
  interval: '1',
  SU: false,
  MO: false,
  TU: false,
  WE: false,
  TH: false,
  FR: false,
  SA: false,
  repeatby: 'dom',
  endson: 'never',
  end_count: '',
  end_date: ''
});

export const getTimeValue = (
  valueTime: string
): Record<'hours' | 'minutes' | 'rawTime', string> => {
  const [hours, minutes] = valueTime.split(':');
  return {
    hours,
    minutes,
    rawTime: valueTime
  };
};

export const transformDateToKnackDate = (fieldFormat: DateTimeField['format'], date: Date) => {
  const dateTime = DateTime.fromJSDate(date);
  const luxonDateFormat = defaultDateFormatMap[fieldFormat.date_format];
  const luxonTimeFormat = fieldFormat.time_format === 'HH:MM am' ? 'hh:mm' : 'HH:mm';

  return {
    ...(fieldFormat.date_format !== 'Ignore Date' && {
      date: dateTime.toFormat(luxonDateFormat)
    }),
    ...(fieldFormat.time_format !== 'Ignore Time' && {
      time: dateTime.toFormat(luxonTimeFormat),
      minutes: parseInt(dateTime.toFormat('mm'), 10),
      hours: parseInt(dateTime.toFormat(fieldFormat.time_format === 'HH:MM am' ? 'hh' : 'HH'), 10),
      am_pm: dateTime.toFormat('a'),
      rawTime: dateTime.toFormat(luxonTimeFormat)
    })
  };
};

export const getNormalizedDefaultTimeFormat = (time: string, fieldFormat: DateTimeFieldFormat) => {
  let format = 'HH:mm';

  if (fieldFormat.time_format === 'HH:MM am') {
    // The V3 saves the value without leading zeros, so we need to adjust the format
    // Ex.: 1:32AM
    format = time.length === 6 ? 'h:mma' : 'hh:mma';
  }

  return format;
};

export const transformCalendarFormDateToKnackDate = (
  inputFormat: DateTimeField['format'],
  date: CalendarFormDateDefaultValues
) => {
  const isRangeMode = inputFormat.calendar || false;

  return {
    ...(date.startDate && transformDateToKnackDate(inputFormat, date.startDate)),
    ...(isRangeMode && {
      to: date.endDate && transformDateToKnackDate(inputFormat, date.endDate)
    })
  };
};

export const getDefaultValueFromDateTimeField = (fieldFormat: DateTimeField['format']) => {
  let dateTime = DateTime.now();

  // If there's a default_date and default_type is "date", parse it.
  if (
    fieldFormat.date_format !== 'Ignore Date' &&
    fieldFormat?.default_type === 'date' &&
    fieldFormat?.default_date
  ) {
    const luxonDateFormat = defaultDateFormatMap[fieldFormat.date_format];

    const parsedDate = DateTime.fromFormat(fieldFormat.default_date, luxonDateFormat);
    if (parsedDate.isValid) {
      // Retain the current time but overwrite the year, month, and day.
      dateTime = dateTime.set({
        year: parsedDate.year,
        month: parsedDate.month,
        day: parsedDate.day
      });
    }
  }

  if (fieldFormat.time_format !== 'Ignore Time') {
    if (fieldFormat?.time_type === 'time' && fieldFormat?.default_time) {
      const luxonTimeFormat = getNormalizedDefaultTimeFormat(fieldFormat.default_time, fieldFormat);
      // If there's a default_time and time_type is "time", parse it.
      const parsedTime = DateTime.fromFormat(fieldFormat.default_time, luxonTimeFormat);
      if (parsedTime.isValid) {
        // Overwrite hour/minute but keep the date from above.
        dateTime = dateTime.set({
          hour: parsedTime.hour,
          minute: parsedTime.minute,
          second: 0,
          millisecond: 0
        });
      }
    }

    if (fieldFormat?.time_type === 'none') {
      dateTime = dateTime.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
    }
  }

  return dateTime.toJSDate();
};

export const getDefaultDatePayload = (inputFormat: DateTimeField['format']) => {
  const defaultStartDate = getDefaultValueFromDateTimeField(inputFormat);
  const defaultEndDate =
    (defaultStartDate && DateTime.fromJSDate(defaultStartDate).plus({ hours: 1 }).toJSDate()) ||
    null;

  return transformCalendarFormDateToKnackDate(inputFormat, {
    startDate: defaultStartDate,
    endDate: defaultEndDate
  });
};
