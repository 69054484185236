import { useTranslation } from 'react-i18next';
import { HiPrinter as PrintIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

export function PagePrint() {
  const [t] = useTranslation();

  return (
    <Button intent="minimal" aria-label={t('actions.print')} onClick={() => window.print()}>
      <PrintIcon />
    </Button>
  );
}
