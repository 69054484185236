import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, RichTextRenderer } from '@knack/asterisk-react';

import {
  type FormViewSectionBreakInput,
  type FormViewStaticInput
} from '@/types/schema/views/FormView';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { FormInputWrapper } from './FormInputWrapper';

interface FormStaticInputProps {
  input: FormViewStaticInput;
}

export function FormStaticInput({ input }: FormStaticInputProps) {
  const [t] = useTranslation();

  const sanitizedInputCopyText = useMemo(
    () => sanitizeHTML((input as FormViewSectionBreakInput).copy),
    [input]
  );

  if (input.type === 'section_break') {
    if (!input.label && !input.copy) {
      // If we are on the page editor and the input is empty, we show a placeholder
      if (isPageEditor()) {
        return (
          <FormInputWrapper>
            <p className="text-subtle">{t('components.views.empty_static_input')}</p>
          </FormInputWrapper>
        );
      }

      return null;
    }

    return (
      <FormInputWrapper>
        {input.label && <h3 className="mb-1 text-lg font-medium">{input.label}</h3>}
        {input.copy && (
          <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedInputCopyText }} />
        )}
      </FormInputWrapper>
    );
  }

  return (
    <FormInputWrapper className="py-4">
      <Divider />
    </FormInputWrapper>
  );
}
