import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { useViewContext } from '@/components/views/ViewContext';

interface FilterConnectionInputProps {
  field: ConnectionField;
  criteriaIndex: number;
}

export function FilterConnectionInput({ field, criteriaIndex }: FilterConnectionInputProps) {
  const [t] = useTranslation();

  const { view } = useViewContext();
  const { control } = useFormContext<ViewFilters>();

  const { data: connectionRecords, isPending } = useConnectionRecordsQuery({
    viewKey: view.key,
    fieldKey: field.key
  });

  const getRecordIdentifier = (recordId: string | undefined) => {
    if (!recordId) {
      return undefined;
    }

    const recordFound = connectionRecords?.records.find((record) => record.id === recordId);

    return String(recordFound?.identifier) || undefined;
  };

  return (
    <Controller
      control={control}
      name={`rules.${criteriaIndex}.value`}
      render={({ field: { value, onChange } }) => {
        const recordId = Array.isArray(value) && value.length > 0 ? value[0] : undefined;

        return (
          <Select
            onValueChange={(newValue) => {
              onChange([newValue]);
            }}
            value={recordId}
            disabled={isPending}
          >
            <Select.Trigger shouldHideValue className="h-9 w-full">
              {isPending
                ? t('keywords.loading')
                : getRecordIdentifier(recordId) || t('actions.select')}
            </Select.Trigger>
            {connectionRecords && (
              <Select.Content>
                {connectionRecords.records.length === 0 && (
                  <span className="text-subtle">
                    {t('components.view_filters.no_records_found')}
                  </span>
                )}
                {connectionRecords.records.map((record) => (
                  <Select.Item key={record.id} value={record.id}>
                    {record.identifier}
                  </Select.Item>
                ))}
              </Select.Content>
            )}
          </Select>
        );
      }}
    />
  );
}
