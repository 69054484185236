import type { KnackFieldKey } from '@/types/schema/KnackField';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';

interface ViewRecordFileFieldProps {
  rawValues: ViewRecord['rawValues'] | undefined;
  fieldKey: KnackFieldKey;
}

export function ViewRecordFileField({ rawValues, fieldKey }: ViewRecordFileFieldProps) {
  if (!rawValues?.[fieldKey]) {
    return '';
  }

  const { signed_url: signedUrl, filename } = rawValues[fieldKey];

  if (!signedUrl || !filename) {
    return '';
  }

  return (
    <a href={signedUrl} rel="noreferrer" target="_blank" className="text-brand underline">
      {filename}
    </a>
  );
}
