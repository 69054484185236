import { type Row } from '@tanstack/react-table';
import { type TFunction } from 'i18next';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type FormattedViewRecord, type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { type GroupedRecord, type ViewWithTableColumn } from './types';

const DEFAULT_COLUMN_WIDTH_IN_PX = 50;

interface GetZebraStripeClassParams {
  row: Row<ViewRecord>;
  hasTableGrouping: boolean;
  canRowExpand: boolean;
}

// This helper function is used to calculate if the user-defined Column Summary label
// exceeds the maximum allowed width in the table.
export function hasElementExceededMaxLines(div: HTMLElement, maxLines = 2) {
  if (!div) return false;

  // Create a temporary div to measure a single line height
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = `${div.clientWidth}px`;
  tempDiv.style.fontSize = window.getComputedStyle(div).fontSize;
  tempDiv.style.lineHeight = window.getComputedStyle(div).lineHeight;
  tempDiv.innerText = 'A'; // A single character for measurement

  document.body.appendChild(tempDiv);
  const singleLineHeight = tempDiv.clientHeight;
  document.body.removeChild(tempDiv);

  // Get the height of the original div
  const divHeight = div.clientHeight;

  // Compare the height of the div to twice the height of a single line
  return divHeight > singleLineHeight * maxLines;
}

export function calculateCharactersInWidth(maxWidthPx: number) {
  const fontSizePx = 14;
  // Approximate average character width in em for most common fonts
  const avgCharWidthEm = 0.5;

  // Calculate average character width in pixels
  const avgCharWidthPx = avgCharWidthEm * fontSizePx;

  // Calculate the number of characters that can fit in the given width
  const numChars = Math.floor(maxWidthPx / avgCharWidthPx);

  return numChars;
}

export const getColumnWidth = (
  column?: ViewWithTableColumn,
  fullWidth?: number
): React.CSSProperties | null => {
  let width: string | number = DEFAULT_COLUMN_WIDTH_IN_PX;

  if (!column || !fullWidth) return null;

  // Ignore the width for default type columns
  if (column.width && column.width.type === 'default') return null;

  if (column.width && column.width.type === 'custom') {
    if (column.width.units === 'px') {
      width = Number(column.width.amount);
    } else {
      width = (fullWidth / 100) * Number(column.width.amount);
    }
  }

  return { width, maxWidth: width };
};

export const getEmptyColumns = (records: FormattedViewRecord[]) => {
  const keyMap: Record<KnackFieldKey, boolean> = {};

  records.forEach((record) => {
    Object.entries(record.values).forEach(([key, value]) => {
      if (!(key in keyMap)) {
        keyMap[key as KnackFieldKey] = true;
      }
      if (value) {
        keyMap[key as KnackFieldKey] = false;
      }
    });
  });

  return Object.keys(keyMap).filter((key) => keyMap[key as KnackFieldKey]) as KnackFieldKey[];
};

export const transformDataForGrouping = (
  records: ViewRecord[],
  groupingColumns: ViewWithTableColumn[]
): ViewRecord[] | GroupedRecord[] => {
  if (groupingColumns.length === 0) return records;

  const groupByColumns = (
    data: ViewRecord[],
    columns: ViewWithTableColumn[],
    level = 0
  ): GroupedRecord[] => {
    // If there are no more columns to group by, return the data
    // with modified record IDs.
    if (level >= columns.length) {
      return data.map((record) => ({
        ...record,
        id: record.id
      })) as GroupedRecord[];
    }

    const column = columns[level];
    const groups = new Map<string, GroupedRecord>();

    data.forEach((record) => {
      const groupValue = record[column.field.key];

      if (!groups.has(groupValue)) {
        groups.set(groupValue, {
          id: record.id as string,
          groupBy: column.id,
          identifier: column.header,
          value: groupValue,
          subRows: []
        });
      }
      // Add the record to the appropriate group.
      groups.get(groupValue)!.subRows.push(record as unknown as GroupedRecord);
    });

    // Recursively group the records for each subgroup.
    return Array.from(groups.values()).map((group) => ({
      ...group,
      // Recursively call groupByColumns on the subgroup records for the next level.
      subRows: groupByColumns(group.subRows, columns, level + 1)
    }));
  };

  // Start the recursive grouping with the provided records and grouping columns.
  return groupByColumns(records, groupingColumns);
};

export const getEmptySampleData = (tableColumns: ViewWithTableColumn[], t: TFunction) => {
  const emptySampleData = tableColumns.reduce<ViewRecord>((acc, col) => {
    if (!col.field?.key) return acc;

    acc[col.field.key] = t('components.views.table.field_sample', {
      fieldName: col.header
    });

    return acc;
  }, {});

  return { id: 'empty-sample-data', ...emptySampleData };
};

export const getZebraStripeClass = ({
  row,
  hasTableGrouping,
  canRowExpand
}: GetZebraStripeClassParams) => {
  if (!hasTableGrouping || canRowExpand) {
    return null;
  }

  const subRows = row.getParentRow()?.subRows ?? [];

  const rowIndex = subRows.findIndex((tableRow) => tableRow.id === row.id);

  // Count non-grouped rows before the current row
  const previousRows = subRows.slice(0, rowIndex);
  const dataRowIndex = previousRows.filter((tableRow) => !tableRow.getCanExpand()).length;

  return dataRowIndex % 2 === 0 ? 'odd-row' : 'even-row';
};
