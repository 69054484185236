import { useMemo } from 'react';
import { RichTextRenderer } from '@knack/asterisk-react';

import { type RichTextView } from '@/types/schema/views/RichTextView';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { useViewContext } from '@/components/views/ViewContext';

export function RichTextViewRender() {
  const { view } = useViewContext<RichTextView>();
  const sanitizedValue = useMemo(() => sanitizeHTML(view.content), [view.content]);

  return (
    <RichTextRenderer
      dangerouslySetInnerHTML={{
        __html: sanitizedValue
      }}
    />
  );
}
