import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { Rating } from '@knack/asterisk-react';

import { type RatingField } from '@/types/schema/fields';

type RatingInputProps = {
  field: RatingField;
  formValueKey: string;
};

export function RatingInput({ field, formValueKey }: RatingInputProps) {
  const inputFormat = field.format;

  const handleChangeRating = (
    value: number,
    fieldValue: string,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (value === parseFloat(fieldValue)) {
      onChange('0');
      return;
    }
    onChange(value.toString());
  };

  return (
    <div className="flex items-center">
      <Controller
        name={formValueKey}
        render={({ field: { value, onChange } }) => {
          const fieldValue = value || 0;
          return (
            <Rating
              maxRatingNumber={parseInt(inputFormat.max, 10)}
              ratingNumber={Number(fieldValue)}
              allowHalf={inputFormat.allow_half}
              onChange={(val) => handleChangeRating(val, fieldValue, onChange)}
            />
          );
        }}
      />
    </div>
  );
}
