import { useTranslation } from 'react-i18next';
import { Spinner } from '@knack/asterisk-react';

import { type SearchView } from '@/types/schema/views/SearchView';
import {
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { ViewWithTableContent } from '@/components/views/common/ViewWithTableContent';
import { ListViewContent } from '@/components/views/list/ListViewContent';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';
import { ResultsDisplayEmptyState } from './ResultsDisplayEmptyState';

export function ResultsDisplay({ sourceData }: { sourceData?: ViewRecordsResponse }) {
  const [t] = useTranslation();

  const { view } = useViewContext<SearchView>();
  const { searchViewParams: params } = useViewQueryParamsContext();

  const { data: pagedViewRecords, isLoading } = useViewMultipleRecordsQuery({
    view,
    options: {
      rowsPerPage: params.rowsPerPage,
      page: params.page,
      ...(params.search && { search: params.search }),
      ...(params.sortOrder && { sortOrder: params.sortOrder }),
      ...(params.sortField && { sortField: params.sortField }),
      ...(params.filters && { filters: JSON.stringify(params.filters) })
    }
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!pagedViewRecords || pagedViewRecords.records.length === 0) {
    return (
      <ResultsDisplayEmptyState>
        {t('components.views.search.errors.no_results')}
      </ResultsDisplayEmptyState>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <ViewToolbarSection />
      {view.results_type === 'table' ? (
        <ViewWithTableContent
          sourceData={sourceData}
          pagedViewRecords={pagedViewRecords}
          view={view}
        />
      ) : (
        <ListViewContent view={view} pagedViewRecords={pagedViewRecords} sourceData={sourceData} />
      )}
    </div>
  );
}
