import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { type ConnectionRecord } from './types';

function getParentFieldInput(form: FormView, fieldKey: KnackFieldKey) {
  let fieldInput: FormViewConnectionInput | undefined;

  form.groups.forEach((group) => {
    group.columns.forEach((column) => {
      column.inputs.forEach((input) => {
        if (input.type === 'connection' && input.field.key === fieldKey) {
          fieldInput = input;
        }
      });
    });
  });

  return fieldInput;
}

export function useConnectionParentInput({
  input,
  view
}: {
  input: FormViewConnectionInput;
  view: FormView;
}) {
  const { watch, setValue } = useFormContext();

  const parentFieldKey =
    input.source?.connections &&
    input.source.connections.length > 0 &&
    input.source.connections[0].source.type === 'input'
      ? input.source.connections[0].source.field.key
      : undefined;
  const parentInput = parentFieldKey ? getParentFieldInput(view, parentFieldKey) : undefined;
  const parentInputValues: ConnectionRecord[] | undefined = parentInput
    ? watch(parentInput.field.key)
    : undefined;
  const parentInputSelectedRecordIds: string[] | undefined = parentInputValues?.map(
    (value) => value.id
  );

  // If the parent input value changes and it's empty, we need to reset the value of the current input
  useEffect(() => {
    if (parentInputSelectedRecordIds && parentInputSelectedRecordIds.length === 0) {
      setValue(input.field.key, []);
    }
  }, [input.field.key, parentInputSelectedRecordIds, setValue]);

  return {
    parentInput,
    parentInputSelectedRecordIds
  };
}
