import { useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { InputWithDisabledText } from '@knack/asterisk-react';

import { type CurrencyField } from '@/types/schema/fields';

type CurrencyInputProps = {
  field: CurrencyField;
  intent: 'default' | 'destructive';
  formValueKey: string;
};

export function CurrencyInput({ field, intent, formValueKey }: CurrencyInputProps) {
  const { register, getValues, setValue } = useFormContext();

  const inputValue = getValues(formValueKey);
  const currencyFormat = field.format?.format;

  const { ref: formRef, onBlur, ...formRegister } = register(formValueKey);

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: 2,
      thousandsSeparator: ',',
      radix: '.',
      padFractionalZeros: true
    },
    {
      defaultValue: inputValue,
      onAccept: (rawValue: string) => {
        let value = rawValue;
        // We need this transformation since the backend is not able
        // to properly filter records if the value contains the delimiter
        value = value.replace(/,/g, '');
        setValue(formValueKey, value);
      }
    }
  );

  return (
    <InputWithDisabledText
      ref={inputRef}
      intent={intent}
      value={typedValue !== undefined ? iMaskValue : inputValue}
      {...(currencyFormat === '€_after'
        ? { disabledTextRight: '€' }
        : { disabledTextLeft: currencyFormat })}
      {...formRegister}
    />
  );
}
