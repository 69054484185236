import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster, Tooltip } from '@knack/asterisk-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (import.meta.env.PUBLIC_LOGROCKET_APP_ID) {
  LogRocket.init(import.meta.env.PUBLIC_LOGROCKET_APP_ID, {
    rootHostname: import.meta.env.PUBLIC_LOGROCKET_ROOT_HOSTNAME ?? 'knack.com',
    dom: {
      baseHref: `${window.location.protocol}//${window.location.hostname}/`
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body?.includes('"password":')) {
          const params = JSON.parse(request.body);
          params.password = '********';
          request.body = JSON.stringify(params);
        }

        // otherwise log the request normally
        return request;
      }
    }
  });
  setupLogRocketReact(LogRocket);
}

export function AppProviders({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `%c Knack Live-app (${APP_VERSION})`,
      'background: #982a86; color: #ffffff; font-size: 20px; padding:5px'
    );
    // eslint-disable-next-line no-console
    console.log(`%cLast modification: ${APP_LAST_MOD}`, 'font-size: 14px;');
  }, []);

  return (
    <FlagsmithProvider
      options={{
        environmentID: import.meta.env.PUBLIC_FLAGSMITH_ENV_ID,
        enableAnalytics: true
      }}
      flagsmith={flagsmith}
    >
      <HelmetProvider>
        <Tooltip.Provider>{children}</Tooltip.Provider>
        <Toaster />
        <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
      </HelmetProvider>
    </FlagsmithProvider>
  );
}
