import { useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { InputWithDisabledText } from '@knack/asterisk-react';

import { type EquationField, type NumberField } from '@/types/schema/fields';

const numberDelimiterMapping = {
  comma: ',',
  period: '.',
  space: ' ',
  none: ''
} as const;

type NumberEquationInputProps = {
  field: NumberField | EquationField;
  intent: 'default' | 'destructive';
  formValueKey: string;
};

export function NumberEquationInput({ field, intent, formValueKey }: NumberEquationInputProps) {
  const { getValues, register, setValue } = useFormContext();

  const decimalDelimiter = field.format?.mark_decimal || 'none';
  const thousandDelimiter = field.format?.mark_thousands || 'none';
  const currencyFormat = field.format?.format;

  const inputValue = getValues(formValueKey);
  const { ref: formRef, onBlur, ...formRegister } = register(formValueKey);

  const formatDecimalDelimiter = () => {
    const hasSameDelimiter =
      (decimalDelimiter === thousandDelimiter && decimalDelimiter !== 'none') ||
      (decimalDelimiter === thousandDelimiter && decimalDelimiter !== 'space');

    if (decimalDelimiter === 'none') return '';

    if (hasSameDelimiter) {
      return decimalDelimiter === 'comma' ? '.' : ',';
    }

    return numberDelimiterMapping[decimalDelimiter];
  };

  const getNumberPrecision = () => {
    if (!field.format.precision || decimalDelimiter === 'none') {
      return 0;
    }

    if (typeof field.format.precision === 'string') {
      return parseInt(field.format.precision, 10);
    }

    return field.format.precision;
  };

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: getNumberPrecision(),
      padFractionalZeros: true,
      ...(thousandDelimiter !== 'none' && {
        thousandsSeparator: numberDelimiterMapping[thousandDelimiter]
      }),
      ...(decimalDelimiter !== 'none' && {
        radix: formatDecimalDelimiter()
      })
    },
    {
      defaultValue: inputValue.toString(),
      onAccept: (rawValue: string) => {
        let value = rawValue;
        if (thousandDelimiter !== 'none') {
          // We need this transformation since the backend is not able
          // to properly filter records if the value contains the delimiter
          const delimiter = numberDelimiterMapping[thousandDelimiter];
          value = value.replace(new RegExp(`\\${delimiter}`, 'g'), '');
        }
        setValue(formValueKey, value);
      }
    }
  );

  return (
    <InputWithDisabledText
      ref={inputRef}
      intent={intent}
      value={typedValue !== undefined ? iMaskValue : inputValue}
      {...(currencyFormat === '€_after'
        ? { disabledTextRight: '€' }
        : { disabledTextLeft: currencyFormat })}
      {...formRegister}
    />
  );
}
