import { type KnackField } from '@/types/schema/KnackField';
import { type FormViewFieldInput } from '@/types/schema/views/FormView';

export const getInlineEditInput = (selectedField: KnackField) =>
  ({
    id: selectedField.key,
    type: selectedField.type,
    field: {
      key: selectedField.key
    },
    instructions: '',
    label: '',
    format: selectedField.format
  }) as FormViewFieldInput;
