import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type ViewFilters, type ViewSort } from '@/types/schema/LiveAppView';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type SearchView, type SearchViewCriteria } from '@/types/schema/views/SearchView';
import { type TableView } from '@/types/schema/views/TableView';
import { useViewFiltersHelpers } from '@/hooks/helpers/useViewFiltersHelpers';

export type ViewWithQueryParams = TableView | ListView | MapView | CalendarView | SearchView;
export type ViewQueryParam = keyof ViewQueryParams;

export interface ViewQueryParamsBase {
  rowsPerPage?: string;
  page?: number;
  search?: string;
  sortField?: KnackFieldKey;
  sortOrder?: ViewSort['order'];
}

export interface ViewQueryParams extends ViewQueryParamsBase {
  filters?: ViewFilters;
}

export interface SearchViewQueryParams extends ViewQueryParamsBase {
  filters?: SearchViewCriteria[];
}

type ViewQueryParamsContextState = {
  params: ViewQueryParams;
  searchViewParams: SearchViewQueryParams;
  actions: {
    setViewQueryParam: (newParams: Partial<ViewQueryParams | SearchViewQueryParams>) => void;
  };
};

type ViewQueryParamsContextProviderProps = {
  view: ViewWithQueryParams;
  children: React.ReactNode;
};

const ViewQueryParamsContext = createContext<ViewQueryParamsContextState | null>(null);

const getSearchViewFiltersFromParams = (filtersParam: string): SearchViewCriteria[] | undefined => {
  const parsedFilters: SearchViewCriteria = JSON.parse(filtersParam);
  return Array.isArray(parsedFilters) ? parsedFilters : undefined;
};

const getFiltersFromParams = (filtersParam: string): ViewFilters | undefined => {
  const parsedFilters: ViewFilters = JSON.parse(filtersParam);
  if (Array.isArray(parsedFilters)) {
    return undefined;
  }

  return parsedFilters.rules.length > 0
    ? { ...parsedFilters, rules: parsedFilters.rules }
    : undefined;
};

const getDefaultTableViewSorting = (view: TableView) => {
  const columnFieldKeys: KnackFieldKey[] = [];
  const viewSourceSorting = view.source?.sort || [];

  view.columns.forEach((column) => {
    // Only field columns can be sorted - skip Action Columns
    if (column.type === 'field') {
      columnFieldKeys.push(column.field.key);
    }
  });

  // If the column is not visible in the element, it can't be sorted by it
  const availableSourceSorting = viewSourceSorting.filter((sort) =>
    columnFieldKeys.includes(sort.field)
  );

  const sortField =
    availableSourceSorting.length > 0 ? availableSourceSorting[0]?.field : undefined;
  const sortOrder: ViewSort['order'] =
    availableSourceSorting.length > 0 ? availableSourceSorting[0]?.order : 'asc';

  return { sortField, sortOrder };
};

export function ViewQueryParamsContextProvider({
  view,
  children
}: ViewQueryParamsContextProviderProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { getPresetFilters, getInitialMenuFilters } = useViewFiltersHelpers();

  const isInitialRender = useRef(true);

  const presetFilters = useMemo(() => getPresetFilters(view), [getPresetFilters, view]);
  const initialMenuFilters = useMemo(
    () => getInitialMenuFilters(view),
    [getInitialMenuFilters, view]
  );

  const searchViewQueryParams = useMemo(() => {
    const defaultParams: SearchViewQueryParams = {
      rowsPerPage: view.type !== 'calendar' ? view.rows_per_page : view.source.limit || undefined,
      page: 1,
      search: '',
      ...(view.type === 'table' && getDefaultTableViewSorting(view))
    };

    const viewParams = Object.fromEntries(searchParams.entries());

    if (!viewParams || Object.keys(viewParams).length === 0) {
      return defaultParams;
    }

    const extractedParams = Object.keys(viewParams).reduce((accumulatedParams, param) => {
      if (param.startsWith(`${view.key}_`)) {
        const extractedParam = param.substring(view.key.length + 1);
        const value =
          extractedParam === 'filters'
            ? getSearchViewFiltersFromParams(viewParams[param])
            : viewParams[param];

        const extractedFilters = extractedParam === 'filters' ? value : {};
        return {
          ...accumulatedParams,
          [extractedParam]: value,
          ...extractedFilters
        };
      }
      return accumulatedParams;
    }, defaultParams);

    return extractedParams;
  }, [searchParams, view]);

  const queryParams = useMemo(() => {
    const defaultParams: ViewQueryParams = {
      rowsPerPage: view.type !== 'calendar' ? view.rows_per_page : view.source.limit || undefined,
      page: 1,
      search: '',
      ...(view.type === 'table' && getDefaultTableViewSorting(view)),

      // We only set the preset and menu filters (if they exist) on the initial render
      ...(presetFilters &&
        !initialMenuFilters &&
        isInitialRender.current && { filters: presetFilters }),
      ...(initialMenuFilters &&
        !presetFilters &&
        isInitialRender.current && { filters: initialMenuFilters })
    };

    const viewParams = Object.fromEntries(searchParams.entries());

    if (!viewParams || Object.keys(viewParams).length === 0) {
      return defaultParams;
    }

    const extractedParams = Object.keys(viewParams).reduce((accumulatedParams, param) => {
      if (param.startsWith(`${view.key}_`)) {
        const extractedParam = param.substring(view.key.length + 1);
        const value =
          extractedParam === 'filters'
            ? getFiltersFromParams(viewParams[param])
            : viewParams[param];

        const extractedFilters = extractedParam === 'filters' ? value : {};
        return {
          ...accumulatedParams,
          [extractedParam]: value,
          ...extractedFilters
        };
      }
      return accumulatedParams;
    }, defaultParams);

    return extractedParams;
  }, [presetFilters, initialMenuFilters, searchParams, view]);

  const setViewQueryParam = useCallback(
    (newParams: Partial<ViewQueryParams | SearchViewQueryParams>) => {
      Object.entries(newParams).forEach(([key, value]) => {
        const prefixedKey = `${view.key}_${key as ViewQueryParam}` as const;
        if (value === undefined) {
          searchParams.delete(prefixedKey);
          return;
        }
        if (value) {
          if (key === 'filters') {
            searchParams.set(prefixedKey, JSON.stringify(value));
            return;
          }
          if (typeof value === 'number') {
            searchParams.set(prefixedKey, value.toString());
            return;
          }
          searchParams.set(prefixedKey, value as string);
        }
      });
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams, view.key]
  );

  const contextValue = useMemo(
    () => ({
      params: queryParams,
      searchViewParams: searchViewQueryParams,
      actions: {
        setViewQueryParam
      }
    }),
    [queryParams, searchViewQueryParams, setViewQueryParam]
  );

  // This effect handles the setting of the preset and menu filters as search params in the URL when the component is first mounted
  useEffect(() => {
    const viewParams = Object.fromEntries(searchParams.entries());
    const hasPresetFilters = presetFilters && presetFilters.rules.length > 0;
    const hasMenuFilters = initialMenuFilters && initialMenuFilters.rules.length > 0;
    const hasEmptyViewFiltersParams = !viewParams[`${view.key}_filters`];

    if (isInitialRender.current && hasEmptyViewFiltersParams) {
      if (hasPresetFilters) {
        setViewQueryParam({ filters: presetFilters });
      } else if (hasMenuFilters) {
        setViewQueryParam({ filters: initialMenuFilters });
      }
    }

    isInitialRender.current = false;

    // Reset/cleanup the isInitialRender flag when the component is first unmounted. This is necessary for the component to work correctly in React's StrictMode.
    return () => {
      isInitialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ViewQueryParamsContext.Provider value={contextValue}>
      {children}
    </ViewQueryParamsContext.Provider>
  );
}

export const useViewQueryParamsContext = () => {
  const context = useContext(ViewQueryParamsContext);

  if (!context) {
    throw new Error(
      'useViewQueryParamsContext must be used within an ViewQueryParamsContextProvider'
    );
  }

  return context;
};
