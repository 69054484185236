import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';

import { type ViewTotalSummary } from '@/types/schema/LiveAppView';
import { type FormattedViewRecord, type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { TableCell } from './cell/TableCell';
import { GroupingCell } from './grouping/GroupingCell';
import { TableHeadContent } from './head/TableHeadContent';
import { getEmptySampleData, transformDataForGrouping } from './helper';
import { type InsertPositionState } from './sortable/SortableTableWrapper';
import { type ViewWithTableColumn } from './types';

interface UseViewTableProps {
  tableColumns: ViewWithTableColumn[];
  records: FormattedViewRecord[];
  insertPositionState?: InsertPositionState | null;
  columnSummaries: ViewTotalSummary[];
}

const columnHelper = createColumnHelper<ViewRecord>();

const createColumns = (tableColumns: ViewWithTableColumn[]) => {
  let dynamicActionColumnIndex = 0;

  const formattedColumns = tableColumns.map((col) => {
    // For Dynamic Actions Columns we need to keep an index to send to the backend in the execution
    const isDynamicActionColumn = col.type === 'action_link';
    const currentActionColumnIndex = dynamicActionColumnIndex;

    if (isDynamicActionColumn) {
      dynamicActionColumnIndex += 1;
    }

    return columnHelper.accessor(col.id, {
      header: () => <TableHeadContent column={col} />,
      cell: ({ cell, table }) => <TableCell table={table} viewColumn={col} cell={cell} />,
      meta: {
        column: col,
        actionColumnIndex: isDynamicActionColumn ? currentActionColumnIndex : undefined
      }
    });
  });

  const collapsibleColumn = columnHelper.display({
    id: 'expander',
    cell: ({ row, table }) => <GroupingCell row={row} table={table} viewColumns={tableColumns} />
  });

  return [collapsibleColumn, ...formattedColumns];
};

export function useViewTable({
  tableColumns,
  records,
  insertPositionState,
  columnSummaries
}: UseViewTableProps) {
  const [t] = useTranslation();
  const [recordValues, rawRecordValues] = useMemo(
    () =>
      records.reduce<[ViewRecord[], ViewRecord[]]>(
        (acc, record) => {
          acc[0].push(record.values);
          acc[1].push(record.rawValues);
          return acc;
        },
        [[], []]
      ),
    [records]
  );

  const [groupingColumns, baseColumns] = tableColumns.reduce<ViewWithTableColumn[][]>(
    (acc, column: ViewWithTableColumn) => {
      if (column.grouping) {
        acc[0].push(column);
      } else {
        acc[1].push(column);
      }
      return acc;
    },
    [[], []]
  );

  const columns = useMemo(() => createColumns(baseColumns), [baseColumns]);
  const isGroupingEnabled = groupingColumns.length > 0;

  const tableRecords =
    records.length === 0 && isPageEditor() ? [getEmptySampleData(tableColumns, t)] : recordValues;

  const transformedData = useMemo(
    () => transformDataForGrouping(tableRecords, groupingColumns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordValues]
  );

  const table = useReactTable({
    data: transformedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows || [],
    meta: {
      rawValues: rawRecordValues,
      insertPositionState,
      columnSummaries
    }
  });

  return { table, isGroupingEnabled };
}
