import { type ReactNode } from 'react';

import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type LiveAppView } from '@/types/schema/LiveAppView';
import { ViewContextProvider } from '@/components/views/ViewContext';
import { ViewQueryParamsContextProvider } from '@/components/views/ViewQueryParamsContext';
import { AuthFlowContextProvider } from '@/pages/page/AuthFlowContext';
import { tableDemo } from './tableDemo';

export function DemoView({
  view,
  children,
  sourceTable
}: {
  view: LiveAppView;
  children: ReactNode;
  sourceTable?: KnackObjectResponse;
}) {
  if (
    view.type === 'table' ||
    view.type === 'list' ||
    view.type === 'map' ||
    view.type === 'calendar'
  ) {
    return (
      <AuthFlowContextProvider>
        <ViewContextProvider view={view} demoSourceTable={sourceTable || tableDemo}>
          <ViewQueryParamsContextProvider view={view}>{children}</ViewQueryParamsContextProvider>
        </ViewContextProvider>
      </AuthFlowContextProvider>
    );
  }

  return (
    <AuthFlowContextProvider>
      <ViewContextProvider view={view} demoSourceTable={sourceTable || tableDemo}>
        {children}
      </ViewContextProvider>
    </AuthFlowContextProvider>
  );
}
