import { useContext, useRef, type ComponentPropsWithoutRef, type CSSProperties } from 'react';
import { Chart, ThemeProviderContext, type ChartConfig } from '@knack/asterisk-react';
import {
  Label,
  LabelList,
  Pie,
  PieChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';
import { type ViewBox } from 'recharts/types/util/types';

import type { ChartData, ChartRecord, ReportViewChart } from '@/types/schema/views/ReportView';
import { getDefaultChartLayout } from '@/utils/schema/patch/reports';
import { useThemingContext } from '@/context/ThemingContext';
import { ChartDataTable } from './shared/ChartDataTable';
import { ChartEmptyState } from './shared/ChartEmptyState';
import { ChartExportButton } from './shared/ChartExportButton';
import { ChartHeader } from './shared/ChartHeader';
import { getColors } from './shared/colors';
import { CustomLabel } from './shared/CustomLabel';
import { LIMIT_BEFORE_GROUPING, useChartHelpers } from './shared/useChartHelpers';

interface PieChartRenderProps extends ComponentPropsWithoutRef<'div'> {
  chart: ReportViewChart;
  chartData: ChartData;
  cssProps: CSSProperties;
}

const getKeyForChartRecord = (record: ChartRecord) =>
  typeof record.group_0 === 'string'
    ? record.group_0
        ?.replaceAll(' ', '-')
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .toLowerCase()
    : record.group_0;

export function PieChartRender({ chart, chartData, cssProps, ...rest }: PieChartRenderProps) {
  const { theme } = useThemingContext();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const chartRef = useRef<HTMLDivElement>(null);
  const { aggregateData, limitRecords, limitDecimalPlaces } = useChartHelpers();

  if (!chartData || !chartData.records || chartData.records.length === 0) {
    return <ChartEmptyState />;
  }

  const parsedRecords = limitDecimalPlaces(chartData.records);
  const aggregatedData = aggregateData(parsedRecords);
  const limitedData = limitRecords(aggregatedData, LIMIT_BEFORE_GROUPING);

  const chartRecords: {
    group_0: string | number;
    fill: string;
    calc_0?: number;
  }[] = limitedData.map((record) => {
    const key = getKeyForChartRecord(record);
    return {
      ...record,
      group_0: key,
      fill: `var(--color-${key})`,
      calc_format: chart.calculations[0].field_format?.format
        ? `${chart.calculations[0].field_format?.format}${record.calc_0}`
        : record.calc_0
    };
  });

  const total = chartRecords.reduce((acc, record) => acc + (record.calc_0 || 0), 0);

  const totalLabel = (props: { viewBox?: ViewBox }) => {
    const { viewBox } = props; // Extract viewBox from props, which could be undefined
    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
      return (
        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
          <tspan x={viewBox.cx} y={viewBox.cy} className="fill-current text-3xl font-bold">
            {total}
          </tspan>
          <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-current">
            {chart.calculations[0].label}
          </tspan>
        </text>
      );
    }
    return null;
  };

  const chartLayout = chart.layout || getDefaultChartLayout();
  const chartConfig: ChartConfig = {};

  const colors = getColors(theme, isDarkMode, chart.layout?.colors) || [];
  while (colors.length < chartRecords.length) {
    colors.push(...colors);
  }

  limitedData.forEach((record, index) => {
    const key = getKeyForChartRecord(record);
    chartConfig[key] = {
      label: record.group_0,
      color: colors[index % colors.length] as string
    };
  });

  const customLabel = ({
    payload,
    ...props
  }: {
    payload: { raw_0: string; calc_format: string };
    [key: string]: unknown;
  }) => <CustomLabel chartConfig={chartConfig} payload={payload} {...props} />;

  const labelValue = chartLayout.data_labels ? customLabel : undefined;
  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';
  const { shouldShowDataTable, export_links: shouldShowExportButton } = chart.options;

  return (
    <div className="flex-col">
      <div className="kn-pie-chart" data-testid="pie-chart" {...rest}>
        <ChartHeader chart={chart} />
        {shouldShowExportButton && <ChartExportButton chart={chart} chartRef={chartRef} />}
        <Chart ref={chartRef} config={chartConfig} className="w-full" style={cssProps}>
          <PieChart style={cssProps}>
            <RechartsTooltip content={<Chart.TooltipContent hideLabel total={total} />} />
            {chartLayout.legend !== 'none' && (
              <RechartsLegend
                content={<Chart.LegendContent nameKey="group_0" />}
                layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
                verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
                align={
                  chartLayout.legend === 'bottom'
                    ? 'center'
                    : (chartLayout.legend as HorizontalAlignmentType)
                }
              />
            )}
            <Pie
              data={chartRecords}
              dataKey="calc_0"
              valueKey="calc_format"
              nameKey="group_0"
              innerRadius={chartLayout.pie_type === 'donut' ? '50%' : undefined}
              labelLine={chartLayout.label_position === 'outside_with_line'}
              label={chartLayout.label_position !== 'inside' && labelValue}
            >
              {chartLayout.data_labels && chartLayout.label_position === 'inside' && (
                <LabelList
                  dataKey="group_0"
                  fill="white"
                  stroke="none"
                  fontSize={12}
                  formatter={(value: keyof typeof chartConfig) => chartConfig[value]?.label}
                />
              )}
              {chartLayout.shouldShowTotal && chartLayout.pie_type === 'donut' && (
                <Label content={totalLabel} />
              )}
            </Pie>
          </PieChart>
        </Chart>
      </div>
      {shouldShowDataTable && (
        <ChartDataTable chart={chart} chartRecords={chartRecords} chartConfig={chartConfig} />
      )}
    </div>
  );
}
