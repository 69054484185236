import { ReportViewRender } from '@/components/views/report/ReportViewRender';
import { DemoView } from '@/pages/theme/demo/DemoView';
import { areaChartViewDemoView } from './areaChartViewDemo';
import { barChartViewDemoView } from './barChartViewDemo';
import { chartData } from './chartData';
import { lineChartViewDemoView } from './lineChartViewDemo';
import { pieChartData } from './pieChartData';
import { pieChartViewDemoView } from './pieChartViewDemo';

export function Charts() {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div>
        <DemoView view={pieChartViewDemoView}>
          <ReportViewRender customChartsData={pieChartData} />
        </DemoView>
      </div>
      <div>
        <DemoView view={barChartViewDemoView}>
          <ReportViewRender customChartsData={chartData} />
        </DemoView>
      </div>
      <div>
        <DemoView view={areaChartViewDemoView}>
          <ReportViewRender customChartsData={chartData} />
        </DemoView>
      </div>
      <div>
        <DemoView view={lineChartViewDemoView}>
          <ReportViewRender customChartsData={chartData} />
        </DemoView>
      </div>
    </div>
  );
}
