import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@knack/asterisk-react';

import { type MapView } from '@/types/schema/views/MapView';
import type { PagedViewRecords } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { cn } from '@/utils/tailwind';
import { DetailsOuterColumnsSortableWrapper } from '@/components/views/details/details-columns-sortable/DetailsOuterColumnsSortableWrapper';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { MapDetailsEmptyState } from '@/components/views/map/details/MapDetailsEmptyState';
import { MapDetailsItemContainer } from '@/components/views/map/details/MapDetailsItemContainer';
import { ViewFooterSection } from '@/components/views/view-footer/ViewFooterSection';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';

export type MapDetailsItemsContentProps = {
  pagedViewRecords?: PagedViewRecords;
  selectedRecord?: FormattedViewRecord;
  onSelectRecord?: (record: FormattedViewRecord) => void;
  isEmptyState: boolean;
  isSmallVersion: boolean;
  isLoading: boolean;
  searchTerm?: string;
  noDataText?: string;
  titleFieldKey: string;
};

export function MapDetailsItemsContent({
  pagedViewRecords,
  selectedRecord,
  onSelectRecord,
  isEmptyState,
  searchTerm,
  isLoading,
  isSmallVersion,
  noDataText,
  titleFieldKey
}: MapDetailsItemsContentProps) {
  const [t] = useTranslation();

  const { view } = useViewContext<MapView>();
  const { theme } = useThemingContext();

  const sanitizedRecords = useMemo(
    () =>
      (pagedViewRecords?.records || []).map((record) => ({
        ...record,
        sanitizedValue: sanitizeHTML(record.values[titleFieldKey])
      })),
    [pagedViewRecords, titleFieldKey]
  );

  // If there are no records to display, we show an empty state, but only if we are not in the page editor
  if (!isPageEditor() && sanitizedRecords.length === 0 && (isEmptyState || !searchTerm)) {
    return (
      <MapDetailsEmptyState>
        <p>{t('components.views.map.enter_search_term')}</p>
      </MapDetailsEmptyState>
    );
  }

  if (isLoading && sanitizedRecords.length === 0) {
    return (
      <MapDetailsEmptyState>
        <p>{t('components.views.map.searching')}</p>
      </MapDetailsEmptyState>
    );
  }

  // If there are no records to display after a location search, we show an empty state, but only if we are not in the page editor
  if (!isPageEditor() && searchTerm && sanitizedRecords.length === 0) {
    return (
      <MapDetailsEmptyState>
        <p>{noDataText || t('components.views.map.errors.no_results')}</p>
        <p>{searchTerm}</p>
        <p className="mt-2 font-light">{t('components.views.map.change_search')}</p>
      </MapDetailsEmptyState>
    );
  }

  return (
    <>
      <div
        className={cn('relative flex size-full flex-col overflow-auto', {
          'rounded-t-lg': theme.appearance.corners === 'rounded',

          // We need to set the position to relative when inside the page editor to allow for the floating UI editor elements to be positioned correctly
          relative: isPageEditor()
        })}
      >
        {/* When on the page editor: if there are no records to display, we show a sample details item so the user can edit the details settings */}
        {isPageEditor() && sanitizedRecords.length === 0 && (
          <MapDetailsItemContainer
            record={{ values: {}, rawValues: {} }}
            selectedRecord={selectedRecord}
            onClick={onSelectRecord}
          >
            <DetailsOuterColumnsSortableWrapper
              view={view}
              record={{ values: {}, rawValues: {} }}
              recordIndex={0}
              outerColumnsClassname="p-3 gap-3"
            />
          </MapDetailsItemContainer>
        )}

        {sanitizedRecords.map((record, recordIndex) => (
          <MapDetailsItemContainer
            key={record.values.id}
            record={record}
            selectedRecord={selectedRecord}
            onClick={onSelectRecord}
          >
            {recordIndex !== 0 && <Divider />}
            <div className="flex items-center justify-between gap-2 px-3 pt-3">
              <div
                className="line-clamp-2 font-bold"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: record.sanitizedValue
                }}
              />
              {record.rawValues.geo_distance && (
                <span
                  className={cn('whitespace-nowrap bg-subtle p-1', {
                    'rounded-md': theme.appearance.corners === 'rounded'
                  })}
                >
                  {record.rawValues.geo_distance}
                </span>
              )}
            </div>
            {isPageEditor() ? (
              <DetailsOuterColumnsSortableWrapper
                view={view}
                record={record}
                recordIndex={recordIndex}
                outerColumnsClassname="p-3 gap-3"
              />
            ) : (
              <DetailsOuterColumns className="gap-3 p-3" record={record} />
            )}
          </MapDetailsItemContainer>
        ))}
      </div>

      <Divider />
      <ViewFooterSection
        totalPageNumber={pagedViewRecords?.totalPages || 0}
        totalRecords={pagedViewRecords?.totalRecords}
        isSmallFooter={isSmallVersion}
      />
    </>
  );
}
