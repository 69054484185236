import { useState } from 'react';
import { Popover } from '@knack/asterisk-react';
import { type Cell } from '@tanstack/react-table';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { EditableCellForm } from './EditableCellForm';
import { getInlineEditInput } from './helper';

interface EditableCellWrapperProps {
  cell: Cell<ViewRecord, unknown>;
  viewColumn: ViewWithTableColumn;
  children: React.ReactNode;
}

export const nonEditableInputTypes: KnackFieldType[] = [
  'concatenation',
  'auto_increment',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'equation'
];

export function EditableCellWrapper({ cell, viewColumn, children }: EditableCellWrapperProps) {
  const { theme } = useThemingContext();
  const { sourceTable } = useViewContext<TableView>();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const selectedField = sourceTable.fields.find((field) => field.key === viewColumn.field.key);

  if (!selectedField) {
    return children;
  }

  const inlineEditInput = getInlineEditInput(selectedField);

  if (nonEditableInputTypes.includes(selectedField.type)) {
    return children;
  }

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
  };

  // Check if we are interacting on the Google autocomplete list and don't close the popover
  const GoogleAutoCompleteListItemClass = 'pac-';
  const isGoogleAutocompleteList = (targetElement: HTMLElement) =>
    targetElement.classList.value.includes(GoogleAutoCompleteListItemClass);

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger
        asChild
        className={cn({
          '!border-x-2 border-y-2 border-emphasis': isPopoverOpen
        })}
      >
        {children}
      </Popover.Trigger>
      <Popover.Content
        align="start"
        className={cn('space-y-3 p-3', {
          'rounded-tl-none': theme.appearance.corners === 'rounded'
        })}
        style={{ minWidth: 280 }}
        sideOffset={1}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (isGoogleAutocompleteList(e.target as HTMLElement)) {
              e.preventDefault();
            }
          }
        }}
        onInteractOutside={(e) => {
          if (isGoogleAutocompleteList(e.target as HTMLElement)) {
            e.preventDefault();
          }
        }}
      >
        <EditableCellForm
          cell={cell}
          input={inlineEditInput}
          viewColumn={viewColumn}
          handleClosePopover={handleClosePopover}
        />
      </Popover.Content>
    </Popover>
  );
}
