import { memo, useEffect, useState } from 'react';
import type {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import {
  type FormView,
  type FormViewGroup,
  type FormViewGroupColumn
} from '@/types/schema/views/FormView';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { DragOverlayContent } from './DragOverlayContent';
import { DroppableFormGroupColumn } from './DroppableFormGroupColumn';

function FormGroupsSortable({ view }: { view: FormView | CalendarView }) {
  const { updatePage, setIsDraggingActive } = usePageEditorContext();

  const formGroups = view.type === 'calendar' ? view.form.groups : view.groups;

  // These are the items that are used for the drag and drop operations. They are stored in the state so that the UI can be updated when the items are reordered.
  // The structure of the items is the same as the structure of the form groups
  const [items, setItems] = useState<FormViewGroup[]>(formGroups);

  // Sensors for the drag and drop operations
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        tolerance: 5,
        // Require to press for 100ms to start dragging, this can reduce the chance of dragging accidentally due to page scroll
        delay: 100
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // State for keeping track of the id of the input being dragged. Needed for rendering the dragging overlay
  const [beingDraggedInputId, setBeingDraggedInputId] = useState<UniqueIdentifier | null>(null);

  // Custom collision detection strategy optimized for multiple containers
  const collisionDetectionStrategy: CollisionDetection = (args) => {
    const droppableContainers = args.droppableContainers.filter((droppableContainer) => {
      if (droppableContainer.data.current?.type === 'input') {
        // If the droppable input is the same as the active input, we only want it if it's being dragged within same column
        if (droppableContainer.id === args.active.id) {
          return (
            droppableContainer.data.current?.sortable.containerId ===
            args.active.data.current?.sortable.containerId
          );
        }

        return true;
      }

      // If the droppable container is a column, we only want it if it's empty
      if (droppableContainer.data.current?.type === 'column') {
        return droppableContainer.data.current.isEmpty;
      }

      return true;
    });

    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin({
      ...args,
      droppableContainers
    });

    // If there are collisions with the pointer, return them
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // Otherwise, return a closestCorners collision detection
    return closestCorners({
      ...args,
      droppableContainers
    });
  };

  const resetDraggingState = () => {
    setBeingDraggedInputId(null);
    setIsDraggingActive(false);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setBeingDraggedInputId(active.id);
    setIsDraggingActive(true);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!active || !over) {
      return;
    }

    let updatedGroups = items;

    const activeColumnId = active.data.current?.sortable.containerId;

    // If the element being dragged over is not an input, it means we are dragging an input over an empty column
    const overColumnId =
      over.data.current?.type === 'input' ? over.data.current?.sortable.containerId : over.id;

    if (!activeColumnId || !overColumnId) {
      return;
    }

    const activeColumnGroup = items.find((s) => s.columns.some((c) => c.id === activeColumnId));

    // If the input is being dragged within the same column, we can just update the input order within the column. There is no need to check for the other groups.
    if (activeColumnId === overColumnId) {
      updatedGroups = items.map((group) => {
        if (group.id === activeColumnGroup?.id) {
          return {
            ...group,
            columns: group.columns.map((column) => {
              if (column.id === activeColumnId) {
                const oldIndex = column.inputs.findIndex((input) => input.id === active.id);
                const newIndex = column.inputs.findIndex((input) => input.id === over.id);

                return {
                  ...column,
                  inputs: arrayMove(column.inputs, oldIndex, newIndex)
                } satisfies FormViewGroupColumn;
              }
              return column;
            })
          } satisfies FormViewGroup;
        }

        return group;
      });
    } else {
      const activeColumn = activeColumnGroup?.columns.find(
        (column) => column.id === activeColumnId
      );
      const activeInput = activeColumn?.inputs.find((input) => input.id === active.id);

      // At this point we know that the input is being dragged from one column to another
      updatedGroups = items.map((group) => ({
        ...group,
        columns: group.columns.map((column) => {
          // Remove the input from the active column
          if (column.id === activeColumnId) {
            return {
              ...column,
              inputs: column.inputs.filter((input) => input.id !== active.id)
            } satisfies FormViewGroupColumn;
          }

          // Add the input to the new column
          if (column.id === overColumnId && activeInput) {
            // If the column is empty, add the input as the only item
            if (column.inputs.length === 0) {
              return {
                ...column,
                inputs: [activeInput]
              } satisfies FormViewGroupColumn;
            }

            const existingInputIndex = column.inputs.findIndex((input) => input.id === over.id);

            // Determine if the input being dragged should be placed above or below the input it is being dragged over
            const isBelowInput =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height / 2;
            const modifier = isBelowInput ? 1 : 0;
            const newIndex =
              existingInputIndex >= 0 ? existingInputIndex + modifier : column.inputs.length + 1;

            return {
              ...column,
              inputs: [
                ...column.inputs.slice(0, newIndex),
                activeInput,
                ...column.inputs.slice(newIndex, column.inputs.length)
              ]
            } satisfies FormViewGroupColumn;
          }
          return column;
        })
      }));
    }

    const updatedView: FormView | CalendarView =
      view.type === 'calendar'
        ? {
            ...view,
            form: {
              ...view.form,
              groups: updatedGroups
            }
          }
        : {
            ...view,
            groups: updatedGroups
          };

    // Update the page with the updated groups
    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView
    });

    // Update the local items state with the updated groups for immediate UI update
    setItems(updatedGroups);

    resetDraggingState();
  };

  useEffect(() => {
    const newFormGroups = view.type === 'calendar' ? view.form.groups : view.groups;

    // Since the form groups can change from outside this dnd/sortable container (e.g. adding/deleting an input from the page editor),
    // we need to update the sortable items whenever the view changes to keep the order in sync
    setItems(newFormGroups);
  }, [view]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={resetDraggingState}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.BeforeDragging
        }
      }}
    >
      <SortableContext items={items.map((item) => item.id)}>
        {items.map((group) => (
          <div key={group.id} className="block flex-wrap gap-4 sm:flex sm:flex-nowrap">
            {group.columns.map((column) => (
              <DroppableFormGroupColumn key={column.id} column={column} />
            ))}
          </div>
        ))}
      </SortableContext>

      <DragOverlay>
        <DragOverlayContent items={items} beingDraggedInputId={beingDraggedInputId} />
      </DragOverlay>
    </DndContext>
  );
}

const memoized = memo(FormGroupsSortable);
export { memoized as FormGroupsSortable };
