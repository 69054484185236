import { type CalendarView } from '@/types/schema/views/CalendarView';

export const calendarViewDemoView: CalendarView = {
  id: '67a477c298f51c001d03b6cd',
  columns: [],
  links: [],
  inputs: [],
  type: 'calendar',
  description: '',
  events: {
    show_details: false,
    allow_add: true,
    allow_edit: true,
    allow_multiple_per_slot: true,
    allow_all_day: true,
    view: 'agendaWeek',
    display_type: 'calendar',
    event_field: {
      key: 'field_17'
    },
    label_field: {
      key: 'field_16'
    },
    time_max: '24:00',
    time_min: '00:00',
    exclude_weekends: false,
    week_start: 'sunday',
    allow_delete: true,
    rss: true,
    ical: true,
    allow_change_display: true,
    event_color_default: '#3366cc',
    event_colors: []
  },
  key: 'view_57',
  name: 'New Tables',
  title: 'New Tables',
  source: {
    object: 'object_4',
    criteria: {
      match: 'any',
      rules: [],
      groups: []
    },
    limit: '',
    sort: [
      {
        field: 'field_16',
        order: 'asc'
      }
    ]
  },
  allow_preset_filters: false,
  filter_type: 'fields',
  menu_filters: [],
  filter_fields: 'object',
  preset_filters: [],
  allow_limit: false,
  details: {
    columns: [],
    label_format: '',
    layout: '',
    list_layout: 'one-column',
    title: undefined,
    description: undefined
  },
  form: {
    type: 'form',
    columns: [],
    links: [],
    inputs: [],
    groups: [],
    submitButtonText: '',
    rules: {
      submits: [],
      fields: [],
      records: [],
      emails: []
    },
    reportType: null,
    action: 'update',
    alert: '',
    design: undefined,
    source: {
      criteria: {
        match: '',
        rules: [],
        groups: []
      },
      object: 'object_4'
    },
    id: '',
    key: 'view_57',
    name: ''
  }
};
