import { useTranslation } from 'react-i18next';
import { BsChevronContract as CollapseIcon, BsChevronExpand as ExpandIcon } from 'react-icons/bs';
import { Button } from '@knack/asterisk-react';
import { type Table } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { type GroupedRecord } from '@/components/views/common/table/types';

type TableRecord = ViewRecord | GroupedRecord;

interface GroupingCollapsibleSectionProps {
  table: Table<TableRecord>;
}

export function GroupingCollapsibleSection({ table }: GroupingCollapsibleSectionProps) {
  const [t] = useTranslation();

  const hasRecords = table.options.data.length > 0;

  if (!hasRecords) {
    return null;
  }

  const shouldDisabledCollapseAll = !table.getIsSomeRowsExpanded();
  const shouldDisableExpandAll =
    table.getIsAllRowsExpanded() ||
    // Tanstack getIsAllRowsExpanded() is not being updated as expected in some scenarios
    // This is a workaround to check if all rows are expanded
    Object.values(table.getState().expanded).length === table.getCoreRowModel().rows.length;

  return (
    <div className="mb-2 flex items-center gap-2">
      <Button
        size="sm"
        disabled={shouldDisableExpandAll}
        onClick={() => table.toggleAllRowsExpanded(true)}
        intent="minimalStandalone"
        className="gap-1 text-xs"
      >
        <ExpandIcon size={14} className="shrink-0" />
        {t('actions.expand_all')}
      </Button>
      <Button
        size="sm"
        disabled={shouldDisabledCollapseAll}
        onClick={() => table.toggleAllRowsExpanded(false)}
        intent="minimalStandalone"
        className="gap-1 text-xs"
      >
        <CollapseIcon size={14} className="shrink-0" />
        {t('actions.collapse_all')}
      </Button>
    </div>
  );
}
