import { Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Combobox,
  Label,
  MultiSelect,
  RadioGroup,
  Spinner,
  type ComboboxOption
} from '@knack/asterisk-react';

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { formatConnectionInputIdentifier } from '@/components/views/form/utils';
import { useViewContext } from '@/components/views/ViewContext';
import { AddConnectedRecordButton } from './AddConnectedRecordButton';
import { DependentConnectionInputPlaceholder } from './DependentConnectionInputPlaceholder';
import {
  getConnectionRecordFromId,
  handleSelectConnection,
  handleSelectMultipleCheckboxes,
  handleSelectMultipleConnections,
  isConnectionFieldWithKey
} from './helper';
import { type ConnectionRecord } from './types';
import { useConnectionInputPrepopulation } from './useConnectionInputPrepopulation';
import { useConnectionParentInput } from './useConnectionParentInput';

const CONNECTION_LIMIT = 15;

export function ConnectionInput({
  input,
  isReadOnly
}: {
  input: FormViewConnectionInput;
  isReadOnly?: boolean;
}) {
  const [t] = useTranslation();

  const { view, sourceTable } = useViewContext<FormView>();
  const {
    setValue: setFormValue,
    formState: { errors },
    getValues
  } = useFormContext();

  const { parentInput, parentInputSelectedRecordIds } = useConnectionParentInput({
    input,
    view
  });
  const { isSinglePrepopulatedInput, shouldPrepopulateValue, pageRecordId } =
    useConnectionInputPrepopulation(input);

  const [newConnectionRecordId, setNewConnectionRecordId] = useState('');

  const connectionInputField = sourceTable.fields.find((f) =>
    isConnectionFieldWithKey(f, input.field.key)
  );

  const { data: connectionRecords, isLoading } = useConnectionRecordsQuery({
    viewKey: view.key,
    relationshipObjectKey: connectionInputField?.relationship.object,
    fieldKey: input.field.key,
    parentInputSelectedRecordIds,

    // If the input has a dependency on another connection input (parent input) in the form, we only want to trigger a fetch if the parent input has a value/has selected a record
    enabled: parentInput
      ? parentInputSelectedRecordIds && parentInputSelectedRecordIds.length > 0
      : true
  });

  const allowOptionInserts = input.allow_option_inserts;
  const isViewActionCreate = view.action === 'create';
  const inputFormatType = input.format?.input || 'chosen';
  const hasManyRelationship = connectionInputField?.relationship?.has === 'many';
  const isCheckboxInput = inputFormatType === 'checkbox';

  const getRecordIdentifierFromId = (id: string) => {
    if (!connectionRecords) return '';
    const record = connectionRecords.records.find((connection) => connection.id === id);
    return record?.identifier || '';
  };

  // We need the useEffect because all the process to get the records is async
  useEffect(() => {
    if (view.action === 'update') {
      setFormValue(input.field.key, getValues(input.field.key));
      return;
    }

    if (shouldPrepopulateValue && pageRecordId) {
      setFormValue(input.field.key, [
        { id: pageRecordId, identifier: getRecordIdentifierFromId(pageRecordId) }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionRecords]);

  // We update the input with the new connection record we have introduced
  useEffect(() => {
    if (newConnectionRecordId && connectionRecords) {
      const newConnectionRecord = getConnectionRecordFromId(
        connectionRecords.records,
        newConnectionRecordId
      );
      if (hasManyRelationship) {
        setFormValue(input.field.key, [...getValues(input.field.key), newConnectionRecord]);
      } else {
        setFormValue(input.field.key, [newConnectionRecord]);
      }
      setNewConnectionRecordId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionRecords]);

  if (isLoading) {
    return <Spinner className="block" />;
  }

  // If the input depends on a parent input, and the parent input has no value yet, we need to show a placeholder state for the input
  if (parentInput && parentInputSelectedRecordIds && parentInputSelectedRecordIds.length === 0) {
    return (
      <DependentConnectionInputPlaceholder fieldInput={input} parentFieldInput={parentInput} />
    );
  }

  if (!connectionRecords) {
    return null;
  }

  // If the input is a checkbox and has many relationships, we need to check if we have reached the limit of connections
  const hasReachedConnectionLimit = connectionRecords.records?.length >= CONNECTION_LIMIT;
  const hasEmptyRecords = connectionRecords.records?.length === 0;

  const shouldRenderMultiSelectComponent =
    (hasManyRelationship && !isCheckboxInput) ||
    (hasManyRelationship && isCheckboxInput && hasReachedConnectionLimit);

  const shouldRenderSingleSelectComponent =
    inputFormatType === 'chosen' || (inputFormatType === 'radio' && hasReachedConnectionLimit);

  const selectTriggerMinWidth = 'min-w-[150px]';

  if (shouldRenderMultiSelectComponent) {
    const multiSelectId = `${input.field.key}-connections-multi-select`;
    const options = connectionRecords.records.map((record) => ({
      label: formatConnectionInputIdentifier(record.identifier),
      key: record.id
    }));

    return (
      <>
        <Controller
          name={input.field.key}
          {...(isViewActionCreate && {
            defaultValue:
              input.format?.conn_default === 'first' ? [connectionRecords.records?.[0]] : []
          })}
          render={({ field: { onChange, value } }) => {
            const fieldValues = value
              ? value.map((val: ConnectionRecord) => ({
                  label: val.identifier,
                  key: val.id
                }))
              : [];

            return (
              <MultiSelect
                id={multiSelectId}
                data-testid={multiSelectId}
                selectedOptions={fieldValues}
                onSelectOptions={(values) => handleSelectMultipleConnections(values, onChange)}
                maxVisibleChips={3}
                triggerClassName={selectTriggerMinWidth}
                options={options}
                isSearchEnabled
                disabled={isSinglePrepopulatedInput}
              />
            );
          }}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
        {allowOptionInserts && input.view && (
          <AddConnectedRecordButton
            connectionInput={input}
            onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
          />
        )}
      </>
    );
  }

  if (shouldRenderSingleSelectComponent) {
    const options = connectionRecords.records.map((record) => ({
      label: formatConnectionInputIdentifier(record.identifier),
      key: record.id
    }));

    return (
      <>
        <Controller
          name={input.field.key}
          {...(isViewActionCreate && {
            defaultValue:
              input.format?.conn_default === 'first' ? [connectionRecords.records?.[0]] : []
          })}
          render={({ field: { value: fieldValue, onChange } }) => {
            const formattedValue: ComboboxOption = {
              key: fieldValue?.[0]?.id,
              label: fieldValue?.[0]?.identifier || getRecordIdentifierFromId(fieldValue?.[0]?.id)
            };

            return (
              <Combobox
                id={`${view.key}-${input.id}-combobox-single-connection`}
                disabled={isSinglePrepopulatedInput}
                options={options}
                isSearchEnabled
                selectedOption={formattedValue}
                triggerClassName={selectTriggerMinWidth}
                onSelectOption={(option) => handleSelectConnection(option, onChange)}
              />
            );
          }}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
        {allowOptionInserts && input.view && (
          <AddConnectedRecordButton
            connectionInput={input}
            onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
          />
        )}
      </>
    );
  }

  if (hasEmptyRecords) {
    return (
      <p className="text-subtle">
        {t('components.views.form.connection_input.no_connection_options_available', {
          inputLabel: input.label
        })}
      </p>
    );
  }

  if (isCheckboxInput) {
    return (
      <>
        {connectionRecords.records.map((record) => {
          const elementId = `${input.field.key}-${record.id}-checkbox`;

          return (
            <Fragment key={elementId}>
              <div className="flex items-center gap-2">
                <Controller
                  name={input.field.key}
                  {...(isViewActionCreate && {
                    defaultValue:
                      input.format?.conn_default === 'first' ? [connectionRecords.records[0]] : []
                  })}
                  render={({ field: { value: fieldValues, onChange } }) => (
                    <Checkbox
                      disabled={isReadOnly || isSinglePrepopulatedInput}
                      checked={fieldValues.some(
                        (value: ConnectionRecord) => value.id === record.id
                      )}
                      id={elementId}
                      data-testid={elementId}
                      onClick={() => handleSelectMultipleCheckboxes(record, fieldValues, onChange)}
                    />
                  )}
                />
                <Label htmlFor={elementId}>
                  {formatConnectionInputIdentifier(record.identifier)}
                </Label>
              </div>
            </Fragment>
          );
        })}
        <FormErrorMessage errors={errors} name={input.field.key} />
        {allowOptionInserts && input.view && (!isReadOnly || !isSinglePrepopulatedInput) && (
          <AddConnectedRecordButton
            connectionInput={input}
            onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
          />
        )}
      </>
    );
  }

  return (
    <Controller
      name={input.field.key}
      {...(isViewActionCreate && {
        defaultValue:
          input.format?.conn_default === 'first'
            ? [connectionRecords.records[0]]
            : [{ id: undefined, identifier: '' }]
      })}
      render={({ field: { value: fieldValue, onChange } }) => (
        <>
          <RadioGroup
            disabled={isReadOnly || isSinglePrepopulatedInput}
            value={fieldValue?.[0]?.id}
          >
            {connectionRecords?.records.map((record) => {
              const elementId = `${input.field.key}-${record.id}-radio`;
              return (
                <RadioGroup.Container key={elementId}>
                  <RadioGroup.Item
                    value={record.id}
                    id={elementId}
                    data-testid={elementId}
                    onClick={() => onChange([record])}
                  />
                  <Label htmlFor={elementId}>
                    {formatConnectionInputIdentifier(record.identifier)}
                  </Label>
                </RadioGroup.Container>
              );
            })}
          </RadioGroup>
          <FormErrorMessage errors={errors} name={input.field.key} />
          {allowOptionInserts && input.view && (!isReadOnly || !isSinglePrepopulatedInput) && (
            <AddConnectedRecordButton
              connectionInput={input}
              onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
            />
          )}
        </>
      )}
    />
  );
}
