import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { type Header } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { TableHeadPageEditor } from '@/components/views/common/table/head/TableHeadPageEditor';
import { type ViewWithTable } from '@/components/views/common/table/types';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { TableHeadRender } from './TableHeadRender';

function SortableTableHeadRender({ headers }: { headers: Header<ViewRecord, unknown>[] }) {
  const { view } = useViewContext<ViewWithTable>();
  const { selectedItem } = usePageEditorContext();

  // Show the sortable table heads only if the view is active in the page editor
  if (selectedItem?.type === 'view' && selectedItem.view.key === view.key) {
    return (
      <SortableContext
        items={headers.map((header) => header.id)}
        strategy={horizontalListSortingStrategy}
      >
        {headers.map((header) => (
          <TableHeadPageEditor key={`${header.id}-head`} header={header} />
        ))}
      </SortableContext>
    );
  }

  return headers.map((header) => <TableHeadRender key={`${header.id}-head`} header={header} />);
}

export function TableHeadGroup({ headers }: { headers: Header<ViewRecord, unknown>[] }) {
  if (isPageEditor()) {
    return <SortableTableHeadRender headers={headers} />;
  }

  return headers.map((header) => <TableHeadRender key={`${header.id}-head`} header={header} />);
}
