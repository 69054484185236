import { useFormContext } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { ViewFilterInput } from '@/components/views/view-options/filters-inputs/ViewFilterInput';
import { AutoIncrementInput } from './AutoIncrementInput';
import { BooleanInput } from './BooleanInput';
import { CurrencyInput } from './CurrencyInput';
import { NumberEquationInput } from './NumberEquationInput';
import { PhoneInput } from './PhoneInput';
import { RatingInput } from './RatingInput';
import { TimerInput } from './TimerInput';

type SearchFieldInputProps = {
  field: KnackField;
  intent: 'default' | 'destructive';
  formValueKey: string;
  criteriaOperator: KnackOperator;
  searchViewCriteriaIndex: number;
  disabled?: boolean;
};

export function SearchFieldInput({
  field,
  intent,
  formValueKey,
  disabled = false,
  searchViewCriteriaIndex,
  criteriaOperator
}: SearchFieldInputProps) {
  const { register } = useFormContext();

  if (disabled) {
    return <Input className="h-9" disabled intent={intent} {...register(formValueKey)} />;
  }

  if (field.type === 'number' || field.type === 'equation') {
    return <NumberEquationInput field={field} intent={intent} formValueKey={formValueKey} />;
  }

  if (field.type === 'currency') {
    return <CurrencyInput field={field} intent={intent} formValueKey={formValueKey} />;
  }

  if (field.type === 'timer') {
    return <TimerInput field={field} intent={intent} formValueKey={formValueKey} />;
  }

  if (field.type === 'auto_increment') {
    return <AutoIncrementInput field={field} intent={intent} formValueKey={formValueKey} />;
  }

  if (field.type === 'phone' && (criteriaOperator === 'is' || criteriaOperator === 'is not')) {
    return <PhoneInput field={field} intent={intent} formValueKey={formValueKey} />;
  }

  if (field.type === 'rating') {
    return <RatingInput field={field} formValueKey={formValueKey} />;
  }

  if (field.type === 'boolean') {
    return <BooleanInput field={field} intent={intent} formValueKey={formValueKey} />;
  }

  return (
    <ViewFilterInput
      field={field}
      criteriaOperator={criteriaOperator}
      criteriaIndex={searchViewCriteriaIndex}
    />
  );
}
