import { useState } from 'react';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  type UniqueIdentifier
} from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';

import { type SearchViewColumn } from '@/types/schema/views/SearchView';
import { type TableViewColumn } from '@/types/schema/views/TableView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useDndUtils } from '@/hooks/useDndUtils';
import {
  type ViewWithTable,
  type ViewWithTableColumn
} from '@/components/views/common/table/types';
import { ViewTable } from '@/components/views/common/table/ViewTable';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { TableOverlayInPageEditor } from './TableOverlayInPageEditor';

interface SortableTableWrapperProps {
  columns: ViewWithTableColumn[];
  records: FormattedViewRecord[];
  shouldApplyFooterClasses?: boolean;
  footer: React.ReactNode;
  children: React.ReactNode;
}

export interface InsertPositionState {
  position: 'none' | 'before' | 'after';
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier;
}

export function SortableTableWrapper({
  columns,
  records,
  shouldApplyFooterClasses,
  footer,
  children
}: SortableTableWrapperProps) {
  const { view } = useViewContext<ViewWithTable>();
  const { selectedItem, setIsDraggingActive, updatePage } = usePageEditorContext();
  const { optimizedSensors } = useDndUtils();

  // State for keeping track of the id of the input being dragged. Needed for rendering the dragging overlay
  const [beingDraggedInputId, setBeingDraggedInputId] = useState<UniqueIdentifier | null>(null);
  const [insertPositionState, setInsertPositionState] = useState<InsertPositionState | null>(null);

  if (!selectedItem || selectedItem.type !== 'view') {
    return children;
  }

  // Show the sortable table heads only if the view is active
  const isViewActive = selectedItem.view.key === view.key;

  const resetDraggingState = () => {
    setBeingDraggedInputId(null);
    setIsDraggingActive(false);
    setInsertPositionState(null);
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((sum) => sum.id === active.id) ?? -1;
      const newIndex = columns.findIndex((sum) => sum.id === over.id) ?? -1;

      if (oldIndex > newIndex) {
        setInsertPositionState({
          position: 'before',
          activeId: active.id,
          overId: over.id
        });
      } else {
        setInsertPositionState({
          position: 'after',
          activeId: active.id,
          overId: over.id
        });
      }
    }

    if (over && active.id === over.id) {
      setInsertPositionState({
        position: 'none',
        activeId: active.id,
        overId: ''
      });
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setBeingDraggedInputId(active.id);
    setInsertPositionState({
      position: 'after',
      activeId: active.id,
      overId: active.id
    });
    setIsDraggingActive(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!columns) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((sum) => sum.id === active.id) ?? -1;
      const newIndex = columns.findIndex((sum) => sum.id === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const newArray = arrayMove(columns, oldIndex, newIndex);

      const updatedView =
        view.type === 'search'
          ? {
              ...view,
              results: {
                ...view.results,
                columns: newArray as SearchViewColumn[]
              }
            }
          : {
              ...view,
              columns: newArray as TableViewColumn[]
            };

      updatePage({
        type: 'view',
        origin: 'live-app',
        action: 'update',
        updatedView
      });
    }

    resetDraggingState();
  };

  if (isViewActive) {
    return (
      <DndContext
        sensors={optimizedSensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={onDragOver}
        onDragCancel={resetDraggingState}
        collisionDetection={closestCorners}
        modifiers={[restrictToHorizontalAxis]}
      >
        <ViewTable
          columns={columns}
          records={records}
          insertPositionState={insertPositionState}
          shouldApplyFooterClasses={shouldApplyFooterClasses}
          footer={footer}
        />
        <DragOverlay
          dropAnimation={null}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        >
          {columns.map((column) => {
            if (column.id !== beingDraggedInputId) {
              return null;
            }

            return <TableOverlayInPageEditor key={column.id} column={column} records={records} />;
          })}
        </DragOverlay>
      </DndContext>
    );
  }

  return children;
}
