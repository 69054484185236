import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useEntryPagesQuery } from '@/hooks/api/queries/useEntryPagesQuery';
import { usePageContext } from '@/context/PageContext';

export function useNavigation({ item }: { item: LiveAppPage }) {
  const location = useLocation();
  const { activePage, protectedPageSlug } = usePageContext();
  const { data: application } = useApplicationQuery();

  const childrenItems = useMemo(
    () => application?.pages.filter((i) => item.dropdownPages?.includes(i.key)),
    [application, item.dropdownPages]
  );
  const shouldDisplayChildren = childrenItems && childrenItems.length > 0;
  const hasActiveChild =
    !!childrenItems &&
    childrenItems.some((childItem) => {
      const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      return lastSegment === childItem.slug;
    });
  const isActive =
    activePage?.type === 'authentication'
      ? protectedPageSlug === item.slug
      : activePage?.key === item.key || hasActiveChild;

  return {
    childrenItems,
    shouldDisplayChildren,
    isActive
  };
}

// The backend already removes pages with ignoreEntrySceneMenu: true on the getEntryPages endpoint.
// The purpose of this function is to manually update the entryPages' response in the frontend and
// see the changes in the iframe in real time without having to save the page.
export function useParsedEntryPages(pageKeyToIgnore?: LiveAppPage['key']) {
  const { data: entryPages = [] } = useEntryPagesQuery();

  return useMemo(() => {
    if (!pageKeyToIgnore) {
      return entryPages;
    }
    return entryPages.filter((page) => page.key !== pageKeyToIgnore);
  }, [entryPages, pageKeyToIgnore]);
}
