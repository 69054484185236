import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@knack/asterisk-react';
import { isAxiosError } from 'axios';
import { type z } from 'zod';

import { type DateTimeField } from '@/types/schema/fields';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useAddRecordMutation } from '@/hooks/api/mutations/useAddRecordMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { isInternalBuilderIframe, isPageEditor } from '@/utils/iframe';
import { FormGroupsSortableWrapper } from '@/components/views/form/form-groups-sortable/FormGroupsSortableWrapper';
import { FormGroups } from '@/components/views/form/FormGroups';
import {
  getDefaultDatePayload,
  transformCalendarFormDateToKnackDate
} from '@/components/views/form/inputs/date-time/dateTimeUtils';
import { getDynamicFormSchema } from '@/components/views/form/schema/helper';
import { getDefaultFormValues, getFormViewInputs } from '@/components/views/form/utils';
import { useViewContext } from '@/components/views/ViewContext';

type CalendarFormProps = {
  startDate?: Date;
  endDate?: Date;
  onCancel: () => void;
  onSubmitSuccess: () => void;
};

export function CalendarForm({ startDate, endDate, onCancel, onSubmitSuccess }: CalendarFormProps) {
  const [t] = useTranslation();

  const { data: application } = useApplicationQuery();
  const { mutate: addRecord, isPending: isAddRecordPending } = useAddRecordMutation();

  const { view, sourceTable } = useViewContext<CalendarView>();

  const formViewInputs = getFormViewInputs(view.form.groups);
  const formSchema = getDynamicFormSchema({
    inputs: formViewInputs,
    sourceTableFields: sourceTable.fields,
    ...(application?.settings.passwordOptions && {
      passwordOptions: application.settings.passwordOptions
    })
  });

  const eventKey = view.events.event_field.key;
  const eventFieldFormat = sourceTable.fields.find((field) => field.key === eventKey)
    ?.format as DateTimeField['format'];

  const initialFormValues = useMemo(() => {
    const defaultValues = getDefaultFormValues(formViewInputs, sourceTable.fields);
    return {
      ...defaultValues,
      // Edge case where the event field is not present in the form schema
      // In this case the default value will be missing
      ...(!defaultValues[eventKey] && {
        [eventKey]: getDefaultDatePayload(eventFieldFormat)
      }),
      // overwrite default values with the current values of the event field if they exist
      ...(startDate && {
        [eventKey]: transformCalendarFormDateToKnackDate(eventFieldFormat, {
          startDate,
          endDate,
          eventKey
        })
      })
    };
  }, [endDate, eventKey, eventFieldFormat, formViewInputs, sourceTable.fields, startDate]);

  type FormSchemaType = z.infer<typeof formSchema>;

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...initialFormValues
    }
  });

  useEffect(() => {
    if (isPageEditor()) {
      form.reset({ ...initialFormValues });
    }
  }, [initialFormValues, endDate, eventKey, form, formViewInputs, sourceTable.fields, startDate]);

  const handleSubmitForm = () => {
    // Using getValues here ensure that the defaultValues we set are in the form data
    const formattedData = { ...form.getValues() };

    Object.keys(formattedData).forEach((key) => {
      // When adding a MultipleChoice Single Select, verify if the value of the key is 'kn-blank' and replace it with an empty string
      if (formattedData[key] === 'kn-blank') formattedData[key] = '';
      // If the field is a timer, we need to format the data to match the expected format from the Server
      if (formattedData[key]?.from) {
        formattedData[key] = {
          times: [formattedData[key]]
        };
      }
      // If the field is a file type and we are updating a record, we clean unnecessary data
      if (formattedData[key]?.type === 'file' || formattedData[key]?.type === 'image') {
        formattedData[key] = formattedData[key].id;
      }
    });

    addRecord(
      {
        viewKey: view.key,
        data: formattedData
      },
      {
        onSuccess: () => {
          onSubmitSuccess();
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response && Array.isArray(error.response.data.errors)) {
            error.response.data.errors.forEach(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ({ field: fieldKey, fieldType, isUserField, message, type: errorType }: any) => {
                // Check if the error is related to an existing user email field
                if (errorType === 'unique' && fieldType === 'email' && isUserField) {
                  // If the fieldKey of the error is not present in the form data, it means that fieldKey refers to the email field from the main `accounts` table and not this form's email field
                  if (!formattedData[fieldKey]) {
                    // We need to find the user role email field in the form so we can display the error there
                    const userEmailField = sourceTable.fields.find(
                      (field) => field.type === 'email' && field.user
                    );

                    if (userEmailField) {
                      form.setError(userEmailField.key, {
                        message,
                        type: errorType
                      });
                      return;
                    }
                  }
                }

                form.setError(fieldKey, {
                  message,
                  type: errorType
                });
              }
            );
          }
        }
      }
    );
  };

  return (
    <FormProvider {...form}>
      <form
        data-testid="calendar-form"
        onSubmit={(e) => {
          if (isInternalBuilderIframe()) {
            e.preventDefault();
            return;
          }

          void form.handleSubmit(handleSubmitForm)(e);
        }}
      >
        {isPageEditor() ? (
          <FormGroupsSortableWrapper view={view}>
            <FormGroups formGroups={view.form.groups} />
          </FormGroupsSortableWrapper>
        ) : (
          <FormGroups formGroups={view.form.groups} />
        )}

        <div className="mt-4 flex justify-end gap-4">
          <Button
            intent="minimal"
            onClick={() => {
              if (isInternalBuilderIframe()) {
                return;
              }

              onCancel();
            }}
          >
            {t('actions.cancel')}
          </Button>
          <Button intent="primary" type="submit" isLoading={isAddRecordPending}>
            {t('actions.submit')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
