import { Table } from '@knack/asterisk-react';
import { flexRender, type Header } from '@tanstack/react-table';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { getColumnWidth } from '@/components/views/common/table/helper';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';
import { useThemingContext } from '@/context/ThemingContext';

interface TableHeadProps {
  header: Header<ViewRecord, unknown>;
}

export function TableHeadRender({ header }: TableHeadProps) {
  const { theme } = useThemingContext();

  const {
    params: { sortField, sortOrder },
    actions: { setViewQueryParam }
  } = useViewQueryParamsContext();

  const viewColumn = header.column.columnDef.meta?.column;

  const columnStyle = getColumnWidth(viewColumn, header.getContext().table.getTotalSize());

  if (!viewColumn) {
    return null;
  }

  const handleChangeSorting = (selectedSortField: KnackFieldKey) => {
    if (!sortField || !selectedSortField) return;

    setViewQueryParam({
      sortField: selectedSortField,
      sortOrder: selectedSortField === sortField && sortOrder === 'asc' ? 'desc' : 'asc'
    });
  };

  return (
    <Table.Head
      tabIndex={0}
      style={{ ...columnStyle }}
      className={cn(
        'h-auto cursor-pointer whitespace-nowrap border-x-0 border-t-0 border-default px-2 py-1 text-default hover:bg-subtle',
        {
          'border-r': theme.tables.border === 'rows-and-columns',
          'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg':
            theme.appearance.corners === 'rounded'
        }
      )}
      onClick={() => handleChangeSorting(viewColumn.field?.key)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleChangeSorting(viewColumn.field?.key);
        }
      }}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </Table.Head>
  );
}
