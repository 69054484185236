import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiCalendar as CalendarIcon } from 'react-icons/hi2';
import { Checkbox, DatePicker, Input, Label } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type DateTimeField, type EquationField } from '@/types/schema/fields';
import { type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { DAY_PICKER_FROM_YEAR, DAY_PICKER_TO_YEAR } from '@/utils/constants';
import {
  getCriteriaTimeString,
  getUpdatedCriteriaDateTimeValueOnTimeChange,
  getUpdatedCriteriaDateTimeValueOnTimePeriodChange
} from '@/utils/date-time-criteria-helpers';
import { cn } from '@/utils/tailwind';
import {
  getFormattedDatePickerDate,
  getInitialIsAnyDate,
  getInitialIsAnyTime,
  isManualDateInputValid
} from '@/components/views/form/inputs/date-time/dateTimeUtils';
import { defaultDateFormatMap } from '@/components/views/form/inputs/date-time/types';
import { TimeInput } from '@/components/views/form/TimeInput';
import { useThemingContext } from '@/context/ThemingContext';

interface FilterDateTimeInputProps {
  field: DateTimeField | EquationField;
  criteriaIndex: number;
}

export function FilterDateTimeInput({ field, criteriaIndex }: FilterDateTimeInputProps) {
  const [t] = useTranslation();

  const { theme } = useThemingContext();
  const { control, getValues, setValue } = useFormContext<ViewFilters>();
  const { getDefaultCriteriaValue } = useCriteriaHelpers();

  const inputRef = useRef<HTMLInputElement>(null);

  const currentDateTimeValue = getValues(
    `rules.${criteriaIndex}.value`
  ) as KnackCriteriaDateTimeValue;
  const defaultDateTimeValue = getDefaultCriteriaValue(field) as KnackCriteriaDateTimeValue;
  const dateFormat = defaultDateFormatMap[field.format.date_format];
  const shouldRenderDate = field.format.date_format !== 'Ignore Date';
  const shouldRenderTime = field.format.time_format !== 'Ignore Time';
  const shouldShowTimeMode = field.format.time_format !== 'HH MM (military)';

  // If the field doesn't ignore time and date, it means that the user can select 'any date' or 'any time' for each part of the date/time input
  const hasAnyDateAnyTimeOptions =
    field.format.time_format &&
    field.format.time_format !== 'Ignore Time' &&
    field.format.date_format &&
    field.format.date_format !== 'Ignore Date';

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyDate, setIsAnyDate] = useState(() => getInitialIsAnyDate(currentDateTimeValue));
  const [isAnyTime, setIsAnyTime] = useState(() => getInitialIsAnyTime(currentDateTimeValue));
  const [lastValidDateTimeValue, setLastValidDateTimeValue] = useState<KnackCriteriaDateTimeValue>({
    date: currentDateTimeValue.date || defaultDateTimeValue.date,
    time: currentDateTimeValue.time || defaultDateTimeValue.time,
    hours: currentDateTimeValue.hours || defaultDateTimeValue.hours,
    minutes: currentDateTimeValue.minutes || defaultDateTimeValue.minutes,
    am_pm: currentDateTimeValue.am_pm || defaultDateTimeValue.am_pm
  });

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(dateFormat);

  return (
    <>
      <div
        className={cn(
          'flex h-9 w-full items-center justify-between gap-1 border border-default bg-input p-1',
          {
            'rounded-lg': theme.appearance.corners === 'rounded'
          }
        )}
      >
        {shouldRenderDate && (
          <Controller
            control={control}
            name={`rules.${criteriaIndex}.value.date`}
            defaultValue={defaultDateTimeValue.date}
            render={({ field: { value, onChange } }) => {
              const formattedDatePickerValue = getFormattedDatePickerDate(value || '', dateFormat);

              return (
                <DatePicker
                  selected={formattedDatePickerValue}
                  onSelect={(selectedDate) => {
                    if (!selectedDate) {
                      return;
                    }

                    const formattedSelectedDate = getFormattedDate(selectedDate);
                    onChange(formattedSelectedDate);
                    setLastValidDateTimeValue((prev) => ({
                      ...prev,
                      date: formattedSelectedDate
                    }));
                  }}
                  mode="single"
                  triggerRef={inputRef}
                  onDatePickerOpenChange={setIsDatePickerOpen}
                  calendarProps={{
                    fromYear: DAY_PICKER_FROM_YEAR,
                    toYear: DAY_PICKER_TO_YEAR
                  }}
                >
                  <Input.Container>
                    <CalendarIcon
                      size={16}
                      className="absolute top-0 ml-2 flex h-full flex-col justify-center"
                    />
                    <Input
                      id={`view-filters-${criteriaIndex}-date-input`}
                      ref={inputRef}
                      value={value}
                      className="h-auto border-0 p-1 pl-7 focus:outline-0"
                      onChange={(e) => {
                        const { value: manualDateInputValue } = e.target;

                        if (!isManualDateInputValid(manualDateInputValue)) {
                          return;
                        }

                        onChange(manualDateInputValue);
                      }}
                      onBlur={(e) => {
                        const { value: manualDateInputValue } = e.target;

                        // Attempt to parse using the field format
                        const dateTime = DateTime.fromFormat(manualDateInputValue, dateFormat);

                        // If the date is not valid, reset the date value
                        if (!dateTime.isValid) {
                          setValue(`rules.${criteriaIndex}.value`, lastValidDateTimeValue);
                        }
                      }}
                      onClick={(e) => {
                        if (isDatePickerOpen) {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </Input.Container>
                </DatePicker>
              );
            }}
          />
        )}
        {shouldRenderTime && !isAnyTime && (
          <Controller
            control={control}
            name={`rules.${criteriaIndex}.value`}
            defaultValue={defaultDateTimeValue.time}
            render={({ field: { value, onChange } }) => {
              const selectedDateTimeValue = value as KnackCriteriaDateTimeValue;
              const timeStringForInput = getCriteriaTimeString(selectedDateTimeValue, field.format);

              return (
                <TimeInput
                  value={timeStringForInput}
                  format={field.format.time_format}
                  onValueChange={(newTime) => {
                    const updatedDateTimeValue = getUpdatedCriteriaDateTimeValueOnTimeChange(
                      field.format,
                      selectedDateTimeValue,
                      newTime
                    );

                    onChange(updatedDateTimeValue);
                    setLastValidDateTimeValue(updatedDateTimeValue);
                  }}
                >
                  {shouldShowTimeMode && (
                    <TimeInput.TimeModeSelect
                      value={(selectedDateTimeValue?.am_pm?.toUpperCase() as 'AM' | 'PM') || 'AM'}
                      onValueChange={(newPeriodValue) => {
                        const updatedDateTimeValue =
                          getUpdatedCriteriaDateTimeValueOnTimePeriodChange(
                            selectedDateTimeValue,
                            newPeriodValue
                          );

                        onChange(updatedDateTimeValue);
                      }}
                    />
                  )}
                </TimeInput>
              );
            }}
          />
        )}
      </div>

      {hasAnyDateAnyTimeOptions && (
        <div className="flex gap-2">
          <Label
            className="mt-2 flex items-center text-emphasis"
            htmlFor={`any-date-checkbox-filter.${criteriaIndex}`}
          >
            <Checkbox
              id={`any-date-checkbox-filter.${criteriaIndex}`}
              className="mr-2"
              checked={isAnyDate}
              onCheckedChange={(isChecked: boolean) => {
                setIsAnyDate(isChecked);

                // Reset the date value to empty when checked
                if (isChecked) {
                  setValue(`rules.${criteriaIndex}.value.date`, '');
                  return;
                }

                // Set the date value to the last valid date when unchecked
                setValue(`rules.${criteriaIndex}.value.date`, lastValidDateTimeValue.date);
              }}
            />
            {t('components.view_filters.date_time.any_date')}
          </Label>

          <Label
            className="mt-2 flex items-center text-emphasis"
            htmlFor={`any-time-checkbox-filter.${criteriaIndex}`}
          >
            <Checkbox
              id={`any-time-checkbox-filter.${criteriaIndex}`}
              className="mr-2"
              checked={isAnyTime}
              onCheckedChange={(isChecked: boolean) => {
                setIsAnyTime(isChecked);

                // Reset the time value to empty when checked
                if (isChecked) {
                  const existingDateTimeValue = getValues(
                    `rules.${criteriaIndex}.value`
                  ) as KnackCriteriaDateTimeValue;
                  setValue(`rules.${criteriaIndex}.value`, {
                    ...existingDateTimeValue,
                    time: '',
                    hours: '',
                    minutes: '',
                    am_pm: ''
                  });
                  return;
                }

                // Set the time values to the last valid values when unchecked
                setValue(`rules.${criteriaIndex}.value`, {
                  ...lastValidDateTimeValue,
                  date: currentDateTimeValue.date
                });
              }}
            />
            {t('components.view_filters.date_time.any_time')}
          </Label>
        </div>
      )}
    </>
  );
}
