import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type DetailsView } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type SearchListView } from '@/types/schema/views/SearchView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { DetailsOuterColumnsSortable } from './DetailsOuterColumnsSortable';

interface DetailsOuterColumnsSortableWrapperProps {
  view: DetailsView | MapView | ListView | CalendarView | SearchListView;
  record: FormattedViewRecord;
  recordIndex?: number;
  withNonSortableOverlay?: boolean;
  outerColumnsClassname?: string;
}

export function DetailsOuterColumnsSortableWrapper({
  view,
  record,
  recordIndex,
  withNonSortableOverlay,
  outerColumnsClassname
}: DetailsOuterColumnsSortableWrapperProps) {
  const { selectedItem } = usePageEditorContext();

  if (!selectedItem || selectedItem.type !== 'view') {
    return <DetailsOuterColumns record={record} className={outerColumnsClassname} />;
  }

  const isViewActive = selectedItem.view.key === view.key;

  if (!isViewActive) {
    return <DetailsOuterColumns record={record} className={outerColumnsClassname} />;
  }

  // If we don't need have a record index, it means that this sortable wrapper is not being rendered inside a loop (e.g. in a list view)
  if (recordIndex === undefined) {
    return (
      <DetailsOuterColumnsSortable
        view={view}
        record={record}
        outerColumnsClassname={outerColumnsClassname}
      />
    );
  }

  // At this point we know this is being rendered inside a loop, so we render the sortable columns only for the first record
  if (recordIndex === 0) {
    return (
      <DetailsOuterColumnsSortable
        view={view}
        record={record}
        outerColumnsClassname={outerColumnsClassname}
      />
    );
  }

  // Otherwise, we render the regular columns
  return (
    <DetailsOuterColumns
      record={record}
      className={cn(
        {
          // If needed, we can optionally add an overlay to indicate that the columns are not sortable
          'relative after:absolute after:size-full after:bg-white/55': withNonSortableOverlay
        },
        outerColumnsClassname
      )}
    />
  );
}
