import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, useToast } from '@knack/asterisk-react';
import { type Cell } from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import { type z } from 'zod';

import { type FormViewFieldInput } from '@/types/schema/views/FormView';
import { type TableView } from '@/types/schema/views/TableView';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { FormFieldInput } from '@/components/views/form/FormFieldInput';
import { getDynamicFormSchema } from '@/components/views/form/schema/helper';
import { useViewContext } from '@/components/views/ViewContext';

interface EditableCellFormProps {
  cell: Cell<ViewRecord, unknown>;
  input: FormViewFieldInput;
  viewColumn: ViewWithTableColumn;
  handleClosePopover: () => void;
}

export function EditableCellForm({
  cell,
  input,
  viewColumn,
  handleClosePopover
}: EditableCellFormProps) {
  const [t] = useTranslation();
  const { sourceTable, view } = useViewContext<TableView>();
  const { presentToast } = useToast();

  const { mutate: updateRecord, isPending } = useUpdateRecordMutation({ viewKey: view.key });

  const recordValues = cell.row.original;
  const recordRawValues = cell.getContext().table.options.meta?.rawValues[
    cell.row.index
  ] as ViewRecord;

  const formSchema = getDynamicFormSchema({
    inputs: [input],
    sourceTableFields: sourceTable.fields
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: { [input.field.key]: recordRawValues[viewColumn.field.key] }
  });

  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    updateRecord(
      {
        data,
        recordId: recordValues.id
      },
      {
        onSuccess: () => {
          presentToast({
            intent: 'success',
            title: t('components.views.table.record_updated', { fieldName: viewColumn.header })
          });
          handleClosePopover();
        },
        onError: (error: Error) => {
          if (isAxiosError(error) && error.response) {
            const { field, message } = error.response.data.errors[0];
            form.setError(field, {
              message,
              type: 'schema'
            });
          }
        }
      }
    );
  };

  return (
    <FormProvider {...form}>
      <div className="flex items-center justify-between">
        <span className="font-semibold">
          {t('actions.edit')} {viewColumn.header}
        </span>
        <Button intent="minimal" size="sm" onClick={handleClosePopover} tabIndex={-1}>
          <CloseIcon size={14} className="shrink-0" />
        </Button>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-3">
          <FormFieldInput input={input} isInlineEdit />
        </div>
        <div className="flex justify-end gap-2">
          <Button intent="minimal" size="sm" onClick={handleClosePopover}>
            {t('actions.cancel')}
          </Button>
          <Button intent="primary" size="sm" type="submit" disabled={isPending}>
            {isPending ? t('actions.saving') : t('actions.save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
