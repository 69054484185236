import { type ReactNode } from 'react';
import { HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

export function ResultsDisplayEmptyState({ children }: { children: ReactNode }) {
  const { theme } = useThemingContext();

  return (
    <div
      className={cn('flex size-full items-center justify-center bg-default p-6', {
        rounded: theme.appearance.corners === 'rounded'
      })}
    >
      <div className="flex flex-col items-center gap-4">
        <SearchIcon size={34} className="text-muted" />
        <div className="text-center text-sm font-semibold text-default">{children}</div>
      </div>
    </div>
  );
}
