import { Table } from '@knack/asterisk-react';
import { type Table as ReactTable } from '@tanstack/react-table';

import { type ViewTotalSummary } from '@/types/schema/LiveAppView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { TableSummaryCell } from './TableSummaryCell';

interface TableSummariesProps {
  table: ReactTable<ViewRecord>;
  columnSummaries: ViewTotalSummary[];
}

export function TableSummaries({ table, columnSummaries }: TableSummariesProps) {
  return (
    <tfoot>
      {columnSummaries.map((summary) => (
        <Table.Row key={summary.label} className="[&_td]:first:border-t">
          {table.getAllLeafColumns().map((col, columnIndex) => (
            <TableSummaryCell
              key={col.id}
              column={col}
              columnIndex={columnIndex}
              columnSummary={summary}
              rawValues={table.options.meta?.rawValues as ViewRecord[]}
            />
          ))}
        </Table.Row>
      ))}
    </tfoot>
  );
}
