import { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@knack/asterisk-react';
import { z } from 'zod';

import { type SearchView, type SearchViewFilters } from '@/types/schema/views/SearchView';
import { useViewContext } from '@/components/views/ViewContext';
import { SearchFieldOptions } from './SearchFieldOptions';
import { useSearchViewFilters } from './useSearchViewFilters';

export function SearchOptions() {
  const [t] = useTranslation();

  const { view } = useViewContext<SearchView>();
  const {
    searchViewCriterias,
    searchViewFieldInputsByFieldKey,
    applySearchViewFilters,
    resetSearchViewFilters
  } = useSearchViewFilters();

  const filterFormSchema = z.custom<SearchViewFilters>().superRefine((data, context) => {
    data.rules.forEach((rule, index) => {
      if (searchViewFieldInputsByFieldKey[rule.field].required && !rule.value) {
        context.addIssue({
          path: ['rules', index, 'value'],
          code: 'custom',
          message: t('errors.value_required')
        });
      }
    });
  });

  const form = useForm<SearchViewFilters>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: { rules: searchViewCriterias }
  });

  const { fields: searchViewCriteriasForm, update: updateCriteria } = useFieldArray({
    control: form.control,
    name: 'rules'
  });

  // We need to keep in sync URL params with the form state.
  // Example: we click reset button and clear all the URL params. Then,
  // we need to reset the form state as well.
  useEffect(() => {
    form.reset({ rules: searchViewCriterias });
  }, [form, searchViewCriterias]);

  // If Knack user enables "Show results to start",
  // we need to trigger the form validation.
  // It can happen, Knack user also has required search inputs
  // that need to be validated before performing the search.
  useEffect(() => {
    if (!view.initial_results) return;
    form.trigger().catch(() => {});
  }, [view.initial_results, form]);

  const handleResetSearchInput = () => {
    resetSearchViewFilters();
    form.reset({ rules: searchViewCriterias });
    form.clearErrors();
  };

  const onSubmit = (newViewFilters: SearchViewFilters) => {
    const normalizedViewFilters: SearchViewFilters = { ...newViewFilters };
    applySearchViewFilters(normalizedViewFilters);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {searchViewCriteriasForm.map((searchViewCriteria, searchViewCriteriaIndex) => (
            <SearchFieldOptions
              key={searchViewCriteria.id}
              fieldKey={searchViewCriteria.field}
              searchViewCriteriaIndex={searchViewCriteriaIndex}
              searchViewFieldInputsByFieldKey={searchViewFieldInputsByFieldKey}
              onCriteriaOperatorChange={(newCriteria) => {
                updateCriteria(searchViewCriteriaIndex, newCriteria);
                form.clearErrors();
              }}
            />
          ))}
        </div>

        <div className="flex gap-4">
          <Button intent="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('actions.apply')}
          </Button>

          <Button intent="secondary" onClick={() => handleResetSearchInput()}>
            {t('actions.reset')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
