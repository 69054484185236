import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Combobox, Select, type ComboboxOption } from '@knack/asterisk-react';
import { snakeCase } from 'lodash';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewFilterInput } from './filters-inputs/ViewFilterInput';

interface ViewFilterRowProps {
  ruleIndex: number;
  criteriaField: KnackField;
  criteriaFieldOperators: KnackOperator[];
  sourceObject: KnackObjectResponse;
  eligibleCriteriaFields: KnackField[];
  onCriteriaUpdate: (updatedCriteria: KnackCriteria) => void;
  onCriteriaRemove: (index: number) => void;
}

export function ViewFilterRow({
  ruleIndex,
  criteriaField,
  criteriaFieldOperators,
  eligibleCriteriaFields,
  sourceObject,
  onCriteriaUpdate,
  onCriteriaRemove
}: ViewFilterRowProps) {
  const [t] = useTranslation();

  const { control, getValues, watch } = useFormContext<ViewFilters>();
  const { view } = useViewContext<CalendarView>();

  const {
    shouldResetCriteriaOperator,
    shouldResetCriteriaValue,
    getDefaultCriteriaOperator,
    getDefaultCriteriaValue
  } = useCriteriaHelpers();

  const selectedOperator = watch(`rules.${ruleIndex}.operator`);

  const eligibleCriteriaFieldOptions: ComboboxOption[] = eligibleCriteriaFields.map((field) => ({
    key: field.key,
    label: field.name
  }));

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newFieldKey
  }: {
    criteriaIndex: number;
    newFieldKey: KnackFieldKey;
  }) => {
    let previousField: KnackField | null = null;
    let newField: KnackField | null = null;

    const criteriaToEdit = getValues(`rules.${criteriaIndex}`);

    sourceObject.fields.forEach((field) => {
      if (field.key === criteriaToEdit.field) {
        previousField = field;
      } else if (field.key === newFieldKey) {
        newField = field;
      }
    });

    if (!newField) {
      return;
    }

    const newCriteria = {
      ...criteriaToEdit,
      field: newFieldKey,
      operator: shouldResetCriteriaOperator(newField, criteriaToEdit.operator)
        ? getDefaultCriteriaOperator(newField)
        : criteriaToEdit.operator,
      value:
        !previousField || shouldResetCriteriaValue(newField, previousField)
          ? getDefaultCriteriaValue(newField)
          : criteriaToEdit.value
    };

    onCriteriaUpdate(newCriteria);
  };

  const onCriteriaOperatorChange = ({
    criteriaIndex,
    newOperator
  }: {
    criteriaIndex: number;
    newOperator: KnackOperator;
  }) => {
    const criteriaToEdit = getValues(`rules.${criteriaIndex}`);

    onCriteriaUpdate({
      ...criteriaToEdit,
      operator: newOperator
    });
  };

  return (
    <div className="w-full flex-wrap gap-2 sm:flex">
      <span
        className={cn('min-w-0 basis-[70px] text-right', {
          'pt-2.5': ruleIndex === 0
        })}
      >
        {ruleIndex === 0 && t('keywords.where')}
        {ruleIndex >= 1 && (
          <Controller
            control={control}
            name="match"
            render={({ field: { value, onChange } }) => (
              <Select value={value} onValueChange={onChange}>
                <Select.Trigger
                  className="h-9 w-full"
                  data-testid={`view-filter-${ruleIndex}-match-select-trigger`}
                />
                <Select.Content
                  className="min-w-20"
                  data-testid={`view-filter-${ruleIndex}-match-select-content`}
                >
                  <Select.Item value="and">{t('keywords.and')}</Select.Item>
                  {/* Disabling `or` filter for calendar views, because a date filter is required and we cannot have a mix of `and` and `or` at the moment */}
                  {view.type !== 'calendar' && (
                    <Select.Item value="or">{t('keywords.or')}</Select.Item>
                  )}
                </Select.Content>
              </Select>
            )}
          />
        )}
      </span>
      <div className="mt-2 min-w-0 flex-1 sm:mt-0">
        <Controller
          control={control}
          name={`rules.${ruleIndex}.field`}
          render={({ field: { value } }) => {
            const selectedOption = {
              key: value,
              label: sourceObject.fields.find((field) => field.key === value)?.name ?? ''
            };

            return (
              <Combobox
                id="view-view-filters"
                isSearchEnabled
                align="start"
                options={eligibleCriteriaFieldOptions}
                triggerClassName="h-9 w-full text-emphasis"
                contentClassName="min-w-[300px]"
                selectedOption={selectedOption}
                onSelectOption={(newSelectedOption) => {
                  onCriteriaFieldChange({
                    criteriaIndex: ruleIndex,
                    newFieldKey: newSelectedOption.key as KnackFieldKey
                  });
                }}
              />
            );
          }}
        />
      </div>
      <div className="mt-2 min-w-0 flex-1 sm:mt-0">
        <Controller
          control={control}
          name={`rules.${ruleIndex}.operator`}
          render={({ field: { value } }) => (
            <Select
              value={value}
              onValueChange={(newOperator: KnackOperator) => {
                onCriteriaOperatorChange({ criteriaIndex: ruleIndex, newOperator });
              }}
            >
              <Select.Trigger
                className="h-9 w-full whitespace-nowrap py-2"
                data-testid={`view-filter-${ruleIndex}-operator-select-trigger`}
              />
              <Select.Content
                className="min-w-[200px]"
                data-testid={`view-filter-${ruleIndex}-operator-select-content`}
              >
                {criteriaFieldOperators.map((operator) => (
                  <Select.Item key={operator} value={operator}>
                    {t(`operators.${snakeCase(operator)}`)}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>
      {!shouldHideValueBasedOnOperator(selectedOperator) && (
        <div className="mt-2 min-w-0 flex-1 sm:mt-0">
          <ViewFilterInput
            field={criteriaField}
            criteriaOperator={selectedOperator}
            criteriaIndex={ruleIndex}
          />
        </div>
      )}
      <Button
        className="mt-2 sm:mt-0"
        intent="minimal"
        size="sm"
        onClick={() => onCriteriaRemove(ruleIndex)}
      >
        <RemoveIcon size={14} className="shrink-0" />
        <span className="ml-2 sm:hidden">{t('actions.remove')}</span>
      </Button>
    </div>
  );
}
