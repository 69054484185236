import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiCalculator as CalculatorIcon } from 'react-icons/hi';
import { EquationHelper } from '@knack/equation-helper';

import { type EquationField } from '@/types/schema/fields';
import { type FormViewEquationInput } from '@/types/schema/views/form/Equation';
import { type FormView } from '@/types/schema/views/FormView';
import { useViewContext } from '@/components/views/ViewContext';

interface EquationInputProps {
  input: FormViewEquationInput;
}

export function EquationInput({ input }: EquationInputProps) {
  const { sourceTable } = useViewContext<FormView>();
  const equationField = sourceTable.fields.find((f) => f.key === input.field.key) as EquationField;

  const { setValue: setFormValue, control } = useFormContext();
  const formValues = useWatch({ control });

  const equationValue = EquationHelper.calculateEquation(
    equationField,
    formValues,
    input.format.equation_type
  );

  const isCalculatingCurrentTime = equationField.format.equation.includes('currentTime');

  useEffect(() => {
    // If we already have the current time value when loading the form, we do not recalculate it
    // This could cause an infinite calculation when running the time. Parity with old live-app
    if (isCalculatingCurrentTime && equationValue) {
      return;
    }

    setFormValue(input.field.key, equationValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equationValue]);

  return (
    <div className="flex items-center gap-2">
      <CalculatorIcon size={20} />
      <span data-testid={`form-view-equation-input-${input.field.key}`}>{equationValue}</span>
    </div>
  );
}
