import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon, HiChevronUp as ChevronUpIcon } from 'react-icons/hi2';
import { Collapsible, Divider, Table, type ChartConfig } from '@knack/asterisk-react';

import { type ChartRecord, type ReportViewChart } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

interface ChartDataTableProps {
  chart: ReportViewChart;
  chartRecords: ChartRecord[];
  chartConfig: ChartConfig;
}

export function ChartDataTable({ chart, chartRecords, chartConfig }: ChartDataTableProps) {
  const { theme } = useThemingContext();
  const [t] = useTranslation();
  const [isTableVisible, setTableVisible] = useState(false);

  const handleToggle = () => {
    setTableVisible((prevState) => !prevState);
  };

  return (
    <>
      <Divider className="my-4" />
      <Collapsible defaultOpen={isTableVisible}>
        <Collapsible.Trigger
          className="group flex items-center gap-2 text-subtle"
          onClick={handleToggle}
        >
          <ChevronDownIcon size={16} className="ml-auto group-data-[state=open]:hidden" />
          <ChevronUpIcon size={16} className="ml-auto group-data-[state=closed]:hidden" />
          {t('components.views.report.data_table')}
        </Collapsible.Trigger>

        <Collapsible.Content className="mt-1 flex flex-col gap-1">
          <div
            className={cn('my-6 h-fit overflow-hidden border border-default', {
              'rounded-lg': theme.appearance.corners === 'rounded'
            })}
          >
            <Table className="table overflow-auto">
              <Table.Header>
                <Table.Row>
                  {chart.groups.map((group, index) => (
                    <Table.Head
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${group.label}-${index}`}
                      colSpan={index > 0 ? chartRecords.length : undefined}
                      rowSpan={chart.groups.length > 1 && index === 0 ? 2 : undefined}
                    >
                      {group.label}
                    </Table.Head>
                  ))}
                  {chart.groups.length < 2 &&
                    chart.calculations.map((calculation, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Table.Head key={`${calculation.calculation}-${index}`}>
                        {calculation.label}
                      </Table.Head>
                    ))}
                </Table.Row>
                {chart.groups.length > 1 && (
                  <Table.Row>
                    {Object.keys(chartConfig).reduce<JSX.Element[]>((acc, config, index) => {
                      if (config !== 'calc_0') {
                        acc.push(
                          // eslint-disable-next-line react/no-array-index-key
                          <Table.Head key={`${config}-${index}`}>
                            {chartConfig[config].label}
                          </Table.Head>
                        );
                      }
                      return acc;
                    }, [])}
                  </Table.Row>
                )}
              </Table.Header>
              <Table.Body>
                {chartRecords.map((record, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Table.Row key={`${record.key}-${index}`}>
                    <Table.Cell key={record.group_0}>{record.group_0}</Table.Cell>
                    {chart.groups.length < 2 &&
                      chart.calculations.map((calculation, index2) => (
                        <Table.Cell key={`${calculation.calculation}-${record.key}`}>
                          {record[`calc_${index2}`]}
                        </Table.Cell>
                      ))}
                    {chart.groups.length > 1 &&
                      Object.keys(record).reduce<JSX.Element[]>((acc, key) => {
                        if (key !== 'group_0') {
                          acc.push(<Table.Cell key={record[key]}>{record[key]}</Table.Cell>);
                        }
                        return acc;
                      }, [])}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Collapsible.Content>
      </Collapsible>
    </>
  );
}
