import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type LogoutResponse = {
  success: boolean;
};

async function logoutUser(applicationId: string | undefined) {
  if (!applicationId) {
    return null;
  }

  const { data } = await axios.delete<LogoutResponse>(`/v1/live-app/${applicationId}/session`, {
    withCredentials: true
  });

  return data;
}

export function useLogoutUserMutation() {
  const queryClient = useQueryClient();
  const { data: application } = useApplicationQuery();

  return useMutation({
    mutationFn: () => logoutUser(application?.id),
    onSuccess: (data: LogoutResponse | null) => {
      if (data?.success) {
        void queryClient.resetQueries({
          queryKey: [queryKeys.auth.session]
        });

        // Remove query data from the cache, but not inform observers about it
        queryClient.removeQueries();
      }
    }
  });
}
