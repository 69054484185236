import { forwardRef, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@knack/asterisk-react';
import truncate from 'lodash/truncate';

import { type ViewColumnIcon } from '@/types/schema/LiveAppView';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { LabelWithIcon } from '@/components/LabelWithIcon';

interface ActionButtonProps {
  icon?: ViewColumnIcon;
  label: string | undefined;
  linkTo?: string;
  isDelete?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const MAX_NUMBER_CHARACTERS = 20;

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ icon, label = '', onClick, linkTo, isDelete }, ref) => {
    const [t] = useTranslation();

    const handleActionButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (isInternalBuilderIframe()) {
        e.preventDefault();
        return;
      }

      if (onClick) {
        onClick(e);
      }
    };

    const content = (
      <LabelWithIcon icon={icon?.icon} iconPosition={icon?.align || 'left'}>
        {truncate(label, { length: MAX_NUMBER_CHARACTERS })}
      </LabelWithIcon>
    );

    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Button
            aria-label={t('components.views.action_button')}
            ref={ref}
            intent={isDelete ? 'destructive' : 'secondary'}
            size="sm"
            asChild={!!linkTo}
            className="kn-action-button h-auto min-h-8"
            onClick={onClick ? handleActionButtonClick : undefined}
          >
            {linkTo ? <Link to={linkTo}>{content}</Link> : content}
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="z-20">{label}</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    );
  }
);

ActionButton.displayName = 'ActionButton';
