import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiChevronDown as ChevronIcon,
  HiExclamationTriangle as ExclamationIcon
} from 'react-icons/hi2';
import {
  Banner,
  Button,
  Checkbox,
  Chip,
  Divider,
  DropdownMenu,
  Input,
  Label,
  RadioGroup
} from '@knack/asterisk-react';

import { NavHorizontalContainer } from '@/components/main-navigation/horizontal/NavHorizontalContainer';
import { DetailsViewRender } from '@/components/views/details/DetailsViewRender';
import { TableViewRender } from '@/components/views/table/TableViewRender';
import { DemoView } from '@/pages/theme/demo/DemoView';
import { detailsDemoData } from '@/pages/theme/demo/detailsDemoData';
import { detailsViewDemoView } from '@/pages/theme/demo/detailsViewDemo';
import { SelectDemo } from './colorsDemoData';
import { tableDemo } from './table/tableDemo';
import { tableDemoData } from './table/tableDemoData';
import { tableViewDemoView } from './table/tableViewDemo';
import { ColorGroup, ExampleGroup, Grid } from './utils';

export function ColorsSurface() {
  const [t] = useTranslation();
  const navBrandRef = useRef(null);

  return (
    <Grid title={t('components.page_editor.demo_page.surface')}>
      <ColorGroup
        color="bg-default"
        name="Default"
        description="Page background (behind cards), radios/checkboxes, and table footer"
      >
        <ExampleGroup group="Radio Button & Checkbox">
          <RadioGroup defaultValue="comfortable">
            <RadioGroup.Container>
              <RadioGroup.Item value="default" id="r1" />
              <Label htmlFor="r1">Radio button</Label>
            </RadioGroup.Container>
          </RadioGroup>
          <Checkbox />
          <Label>Checkbox</Label>
        </ExampleGroup>
        <ExampleGroup group="Table Example" isSingleColumn>
          <DemoView view={tableViewDemoView} sourceTable={tableDemo}>
            <TableViewRender sourceData={tableDemoData} />
          </DemoView>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup color="bg-muted" name="Muted" description="Used in table headers and row stripes">
        <ExampleGroup group="Table Example" isSingleColumn>
          <DemoView view={tableViewDemoView} sourceTable={tableDemo}>
            <TableViewRender sourceData={tableDemoData} />
          </DemoView>
        </ExampleGroup>
        <ExampleGroup group="Details/List Example" isSingleColumn>
          <DemoView view={detailsViewDemoView} sourceTable={tableDemo}>
            <DetailsViewRender sourceData={detailsDemoData} />
          </DemoView>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-subtle"
        name="Subtle"
        description="Used in table filters, dividers and hovered radio buttons/checkboxes"
      >
        <ExampleGroup group="Details/List Example">
          <Chip text="Example Table Filter" />
          <Chip text="Example Table Filter" />
        </ExampleGroup>
        <ExampleGroup group="Radio Button & Checkbox">
          <RadioGroup defaultValue="comfortable">
            <RadioGroup.Container>
              <RadioGroup.Item value="default" id="r1" />
              <Label htmlFor="r1">Radio button</Label>
            </RadioGroup.Container>
          </RadioGroup>
          <Checkbox />
          <Label>Checkbox</Label>
        </ExampleGroup>
        <ExampleGroup group="Divider">
          <Divider />
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-card"
        name="Card"
        description="Used in cards and table cell backgrounds"
      >
        <ExampleGroup group="Table Example" isSingleColumn>
          <DemoView view={tableViewDemoView} sourceTable={tableDemo}>
            <TableViewRender sourceData={tableDemoData} />
          </DemoView>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-menu"
        name="Menu"
        description="Used in option menus in dropdowns and navigations"
      >
        <ExampleGroup group="Button Tabs">
          <DropdownMenu>
            <DropdownMenu.Trigger asChild>
              <Button intent="primary">
                Open a Menu Here <ChevronIcon size={20} className="ml-2" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Label>My Account</DropdownMenu.Label>
              <DropdownMenu.Separator />
              <DropdownMenu.Item>Profile</DropdownMenu.Item>
              <DropdownMenu.Item>Billing</DropdownMenu.Item>
              <DropdownMenu.Item>Team</DropdownMenu.Item>
              <DropdownMenu.Item disabled>Disabled Option</DropdownMenu.Item>
              <DropdownMenu.Item>Subscription</DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu>
        </ExampleGroup>
        <ExampleGroup group="Menu" isSingleColumn>
          <SelectDemo />
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-navigation"
        name="App Navigation"
        description="Used in your app's horizontal or vertical navigation"
      >
        <ExampleGroup group="Horizontal Navigation">
          <div className="min-w-full border bg-navigation p-4">
            <NavHorizontalContainer containerclasses="" navBrandRef={navBrandRef} />
          </div>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup color="bg-input" name="Input" description="Used in the text inputs and dropdowns">
        <ExampleGroup group="Inputs">
          <Input className="min-w-60" placeholder="Example Text Input" />
        </ExampleGroup>
        <ExampleGroup group="Selects & Multi-Selects">
          <SelectDemo />
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-destructive"
        name="Destructive"
        description="Used in error or destructive banners, messages, buttons, inputs, etc"
      >
        <Banner
          title="This is an destructive banner"
          className="mb-4 w-full"
          closeMode="text"
          intent="destructive"
          actionLabel="Action"
          closeLabel="Dismiss"
          icon={ExclamationIcon}
          type="inline"
          onClose={() => {}}
          onAction={() => {}}
        >
          <Banner.Message>Hello! I&apos;m a DESTRUCTIVE banner component!</Banner.Message>
        </Banner>
        <ExampleGroup group="Buttons">
          <Button intent="destructive">Destructive Button</Button>
          <Button intent="destructive" disabled>
            Destructive Button Disabled
          </Button>
        </ExampleGroup>
      </ColorGroup>
    </Grid>
  );
}
