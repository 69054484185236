import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, RichTextRenderer } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { useViewContext } from '@/components/views/ViewContext';
import { CalendarForm } from './CalendarForm';

interface CalendarEventFormModalProps {
  startDate?: Date;
  endDate?: Date;
  onOpenChange: () => void;
}

export function CalendarEventFormModal({
  startDate,
  endDate,
  onOpenChange
}: CalendarEventFormModalProps) {
  const [t] = useTranslation();
  const { view } = useViewContext<CalendarView>();
  const sanitizedValue = useMemo(
    () => sanitizeHTML(view.form.description || ''),
    [view.form.description]
  );

  return (
    <Dialog open onOpenChange={onOpenChange} modal={false}>
      <Dialog.Content
        aria-describedby={undefined}
        modal={false}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {view.form.title || t('components.views.calendar.add_event')}
            </Dialog.Title>
            {view.form.description && (
              <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
            )}
          </Dialog.Header>
          <div className="pt-4">
            <CalendarForm
              startDate={startDate}
              endDate={endDate}
              onCancel={onOpenChange}
              onSubmitSuccess={onOpenChange}
            />
          </div>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
