import { useMemo } from 'react';

import { type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import {
  type SearchView,
  type SearchViewCriteria,
  type SearchViewFieldInput,
  type SearchViewFilters
} from '@/types/schema/views/SearchView';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { useViewContext } from '@/components/views/ViewContext';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

export function useSearchViewFilters() {
  const { view, sourceTable } = useViewContext<SearchView>();
  const { actions, searchViewParams } = useViewQueryParamsContext();
  const { canFieldStoreDateValues } = useFieldHelpers();

  const { searchViewCriterias, searchViewFieldInputsByFieldKey } = useMemo(() => {
    const criterias: SearchViewCriteria[] = [];
    const fieldInputs: Record<string, SearchViewFieldInput> = {};

    view.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.fields.forEach((field) => {
          const existingCriteria = searchViewParams.filters?.find(
            (rule) => rule.field === field.field
          );
          const criteria: SearchViewCriteria = existingCriteria || {
            field: field.field,
            operator: field.operator,
            value: field.value
          };
          criteria.operator = field.ignore_operators ? field.operator_default : criteria.operator;
          criteria.value = shouldHideValueBasedOnOperator(criteria.operator) ? '' : criteria.value;
          criterias.push(criteria);
          fieldInputs[field.field] = field;
        });
      });
    });

    return {
      searchViewCriterias: criterias,
      searchViewFieldInputsByFieldKey: fieldInputs
    };
  }, [view.groups, searchViewParams.filters]);

  const applySearchViewFilters = (newViewFilters: SearchViewFilters) => {
    const criterias: SearchViewCriteria[] = [];

    newViewFilters.rules.forEach((rule) => {
      if (rule.field === 'keyword_search' && rule.value) {
        criterias.push({
          ...rule,
          operator: 'contains',
          value: rule.value
        });
        return;
      }

      if (rule.operator === 'is any') {
        return;
      }

      const criteriaField = sourceTable.fields.find((field) => field.key === rule.field);

      if (!criteriaField) {
        criterias.push(rule);
        return;
      }

      if (criteriaField.type === 'connection' && Array.isArray(rule.value)) {
        criterias.push({
          ...rule,
          value: [rule.value[0]]
        });
        return;
      }

      if (canFieldStoreDateValues(criteriaField) && rule.value) {
        const ruleDateTimeValue = rule.value as KnackCriteriaDateTimeValue;
        criterias.push({
          ...rule,
          value: ruleDateTimeValue
        });
        return;
      }

      criterias.push(rule);
    });

    actions.setViewQueryParam({ filters: criterias, page: undefined });
  };

  const resetSearchViewFilters = () => {
    actions.setViewQueryParam({ filters: undefined, page: undefined });
  };

  return {
    searchViewCriterias,
    searchViewFieldInputsByFieldKey,
    applySearchViewFilters,
    resetSearchViewFilters
  };
}
