import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@knack/asterisk-react';

import { type ViewSubmitRule } from '@/types/schema/LiveAppView';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageSegmentsHelpers } from './helpers/usePageSegmentsHelpers';

export function useViewSubmitRules() {
  const navigate = useNavigate();
  const { presentToast } = useToast();

  const { sourceTable } = useViewContext();
  const { getRedirectToChildPagePathFromSlug, getRedirectToPagePathFromSlug, getPreviousPagePath } =
    usePageSegmentsHelpers();

  const [applicableSubmitRuleValues, setApplicableSubmitRuleValues] = useState({
    confirmationMessage: '',
    shouldShowReloadButton: false,
    shouldReloadAutomatically: false
  });

  const handleSubmitRuleAction = ({
    applicableRule,
    recordId,
    shouldShowToast = false
  }: {
    applicableRule: ViewSubmitRule;
    recordId?: string;
    shouldShowToast?: boolean;
  }) => {
    const {
      action,
      url,
      existing_page: existingPage,
      scene,
      message,
      reload_show: shouldShowReloadButton,
      reload_auto: shouldReloadAutomatically
    } = applicableRule;

    setApplicableSubmitRuleValues((prev) => ({
      ...prev,
      shouldShowReloadButton: shouldShowReloadButton || false,
      shouldReloadAutomatically: shouldReloadAutomatically || false
    }));

    let formattedUrl = url || '/';

    if (action === 'url' && url) {
      if (!url.includes('http')) {
        formattedUrl = `//${url}`;
      } else {
        formattedUrl = url;
      }
      window.location.href = formattedUrl;
    }

    if (action === 'existing_page' && existingPage) {
      void navigate(getRedirectToPagePathFromSlug(existingPage, sourceTable?.key, recordId));
    }

    if (action === 'child_page' && scene) {
      void navigate(getRedirectToChildPagePathFromSlug(scene, recordId));
    }

    if (action === 'parent_page') {
      void navigate(getPreviousPagePath());
    }

    if (action === 'message') {
      if (shouldShowToast) {
        presentToast({
          content: message,
          intent: 'success'
        });
        return;
      }

      setApplicableSubmitRuleValues((prev) => ({
        ...prev,
        confirmationMessage: message
      }));
    }
  };

  const handleFormSubmitRules = ({
    matchingSubmitRuleKey,
    rules,
    recordId, // This will be the record ID of the record that was either added or updated
    shouldShowToast
  }: {
    matchingSubmitRuleKey: string | boolean;
    rules: ViewSubmitRule[];
    recordId?: string;
    shouldShowToast?: boolean;
  }) => {
    const applicableRule = rules.find((rule) => rule.key === matchingSubmitRuleKey);

    if (!applicableRule) return;

    handleSubmitRuleAction({ applicableRule, shouldShowToast, recordId });
  };

  return {
    applicableSubmitRuleValues,
    setApplicableSubmitRuleValues,
    handleFormSubmitRules,
    handleSubmitRuleAction
  };
}
