import { HiArrowDown as SortIcon } from 'react-icons/hi2';

import { cn } from '@/utils/tailwind';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

export function TableHeadContent({ column }: { column: ViewWithTableColumn }) {
  const {
    params: { sortField, sortOrder }
  } = useViewQueryParamsContext();

  const columnFieldKey = column?.field?.key;
  const isFieldTypeColumn = column.type === 'field';
  const isThisColumnSorting = sortField === columnFieldKey;

  return (
    <div
      className={cn('flex items-center justify-between gap-2', {
        'justify-center': !isFieldTypeColumn || column.align === 'center',
        'justify-start': column.align === 'left',
        'justify-end': column.align === 'right'
      })}
    >
      <span className="truncate"> {column.header}</span>
      {isThisColumnSorting && (
        <SortIcon
          size={16}
          className={cn('shrink-0', {
            'rotate-180': sortOrder === 'desc'
          })}
        />
      )}
    </div>
  );
}
