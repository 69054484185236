import { Link } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type ViewColumnConnSeparator } from '@/types/schema/LiveAppView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { cn } from '@/utils/tailwind';

interface ViewRecordInputValueProps {
  rawValues?: ViewRecord;
  fieldKey: KnackFieldKey;
  connLink: string | undefined;
  connSeparator: ViewColumnConnSeparator | undefined;
}

export function ViewRecordConnectionLink({
  rawValues,
  fieldKey,
  connLink,
  connSeparator
}: ViewRecordInputValueProps) {
  const { data: application } = useApplicationQuery();
  const { getPagePathFromSlug } = usePageSegmentsHelpers();

  if (!rawValues || Object.keys(rawValues).length === 0) {
    return '';
  }

  if (!rawValues[fieldKey]) {
    return '';
  }

  return rawValues[fieldKey].map((value: { id: string; identifier: string }, index: number) => {
    if (!value) {
      return '';
    }

    const { id, identifier } = value;

    let connLinkPage = null;
    if (connLink) {
      connLinkPage = application?.pages.find((page) => page.key === connLink);
    }

    if (!connLinkPage) {
      return (
        <span
          key={`${id}-${fieldKey}`}
          className={cn({
            block: !connSeparator || connSeparator === 'new_line'
          })}
        >
          {connSeparator === 'comma' && index > 0 && <span className="mr-1">,</span>}
          {identifier}
        </span>
      );
    }

    return (
      <Fragment key={`${id}-${fieldKey}`}>
        {connSeparator === 'comma' && index > 0 && <span className="mr-1">,</span>}
        <Link
          className={cn('text-brand-default', {
            block: connSeparator === 'new_line'
          })}
          to={`${getPagePathFromSlug(connLinkPage.slug)}/${id}`}
        >
          {identifier}
        </Link>
      </Fragment>
    );
  });
}
