import { Table } from '@knack/asterisk-react';
import formatHelper from '@knack/format-helper';
import { type Column } from '@tanstack/react-table';

import { type ViewTotalSummary } from '@/types/schema/LiveAppView';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useViewContext } from '@/components/views/ViewContext';
import { calculateSummaries, getSummaryFieldFormat, getSummaryValue, replaceNbsp } from './helper';
import { TableViewSummaryLabel } from './TableSummaryLabel';

interface TableSummaryCellProps {
  columnSummary: ViewTotalSummary;
  column: Column<ViewRecord>;
  columnIndex: number;
  rawValues: ViewRecord[];
}

export const CELL_MAX_WIDTH_IN_PX = 140;

export const tableCellClasses =
  'border-x-0 bg-muted p-2 text-emphasis font-medium hover:bg-muted border-b border-default';

export function TableSummaryCell({
  column,
  columnIndex,
  columnSummary,
  rawValues
}: TableSummaryCellProps) {
  const { sourceTable } = useViewContext<TableView>();

  const isFirstColumn = columnIndex === 1;
  const viewColumn = column.columnDef.meta?.column;

  if (!viewColumn) {
    return null;
  }

  const field = sourceTable?.fields.find((f) => f.key === viewColumn?.field?.key);

  // If action column or ignore summary then return an empty cell
  if (!field || viewColumn.ignore_summary) {
    return <Table.Cell className={tableCellClasses} style={{ maxWidth: CELL_MAX_WIDTH_IN_PX }} />;
  }

  const summariesValues = calculateSummaries(rawValues, sourceTable.fields);
  const summaryValue = getSummaryValue(summariesValues, viewColumn.field.key, columnSummary.calc);
  const summaryRenderValue = replaceNbsp(
    formatHelper.formatNumber(summaryValue, getSummaryFieldFormat(field))
  );

  // Render the label only in the first column
  if (isFirstColumn) {
    return (
      <TableViewSummaryLabel
        label={columnSummary.label}
        summaryValue={summaryRenderValue}
        align={viewColumn.align}
      />
    );
  }

  return (
    <Table.Cell
      className={tableCellClasses}
      style={{ maxWidth: CELL_MAX_WIDTH_IN_PX, textAlign: viewColumn.align }}
    >
      {summaryRenderValue}
    </Table.Cell>
  );
}
