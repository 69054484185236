import { type ControllerRenderProps } from 'react-hook-form';
import { type ComboboxOption, type MultiSelectOption } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import { type ConnectionRecord } from './types';

export const handleSelectMultipleCheckboxes = (
  selectedOption: ConnectionRecord,
  fieldValues: ConnectionRecord[],
  onChange: ControllerRenderProps['onChange']
) => {
  if (fieldValues.some((value) => value.id === selectedOption.id)) {
    onChange(fieldValues.filter((item) => item.id !== selectedOption.id));
  } else {
    onChange([...fieldValues, selectedOption]);
  }
};

export const handleSelectMultipleConnections = (
  selectedOptions: MultiSelectOption[],
  onChange: ControllerRenderProps['onChange']
) => {
  onChange(
    selectedOptions.map((option: MultiSelectOption) => ({
      id: option.key,
      identifier: option.label
    }))
  );
};

export const handleSelectConnection = (
  option: ComboboxOption,
  onChange: ControllerRenderProps['onChange']
) => {
  const selectedRecordOption: ConnectionRecord = {
    id: option.key,
    identifier: option.label
  };
  onChange([selectedRecordOption]);
};

export const getConnectionRecordFromId = (connections: ConnectionRecord[], connectionId: string) =>
  connections.find((connection) => connection.id === connectionId);

export function isConnectionFieldWithKey(
  field: KnackField,
  key: KnackField['key']
): field is ConnectionField {
  return field.type === 'connection' && field.key === key;
}
