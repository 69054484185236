import { useTranslation } from 'react-i18next';
import { SimplePagination } from '@knack/asterisk-react';

import { RowsPerPageSelect } from '@/components/views/view-options/RowsPerPageSelect';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';
import { RecordsRange } from './RecordsRange';

type LargeViewFooterProps = {
  shouldAllowChangeRowsPerPage: boolean;
  shouldRenderPagination: boolean;
  totalPageNumber: number;
  totalRecords?: number;
};

export function LargeViewFooter({
  shouldAllowChangeRowsPerPage,
  shouldRenderPagination,
  totalPageNumber,
  totalRecords
}: LargeViewFooterProps) {
  const [t] = useTranslation();

  const { params, actions } = useViewQueryParamsContext();

  return (
    <>
      {shouldAllowChangeRowsPerPage && (
        <div className="flex items-center gap-2">
          <RowsPerPageSelect />
          {totalRecords && params.rowsPerPage && (
            <RecordsRange
              totalRecords={totalRecords}
              rowsPerPage={Number(params.rowsPerPage)}
              currentPage={Number(params.page)}
            />
          )}
        </div>
      )}
      {!shouldAllowChangeRowsPerPage && params.rowsPerPage && (
        <div className="text-xs text-subtle">{`${t('components.views.showing')} ${params.rowsPerPage} ${t('components.views.per_page')}`}</div>
      )}
      {shouldRenderPagination && (
        <div data-testid="pagination">
          <SimplePagination
            totalPages={totalPageNumber}
            currentPage={Number(params.page)}
            onPageChange={(page) => actions.setViewQueryParam({ page })}
            maxVisiblePages={3}
          />
        </div>
      )}
    </>
  );
}
