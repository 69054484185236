import { DateTime } from 'luxon';

import {
  type DateTimeField,
  type DateTimeFieldFormat,
  type EquationField
} from '@/types/schema/fields';
import { type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { isTimeStringInMilitaryFormat } from '@/components/views/form/inputs/date-time/dateTimeUtils';
import { defaultDateFormatMap } from '@/components/views/form/inputs/date-time/types';

export function getDefaultCriteriaTimeValues(dateTimeField: DateTimeField | EquationField) {
  // If the field is an equation field that can store times, return the current time since there can't be a default time
  if (dateTimeField.type === 'equation' || dateTimeField.format.time_type === 'current') {
    const defaultFormat =
      dateTimeField.format.time_format === 'HH MM (military)' ? 'HH:mm' : 'h:mma';

    const criteriaTimeValues: KnackCriteriaDateTimeValue = {
      time: DateTime.now().toFormat(defaultFormat),
      hours: DateTime.now().hour,
      minutes: DateTime.now().minute,
      am_pm: DateTime.now().hour < 12 ? 'AM' : 'PM'
    };

    return criteriaTimeValues;
  }

  if (dateTimeField.format.time_type === 'none') {
    const criteriaTimeValues: KnackCriteriaDateTimeValue = {
      time: '',
      hours: '',
      minutes: '',
      am_pm: ''
    };

    return criteriaTimeValues;
  }

  if (dateTimeField.format.time_type === 'time' && dateTimeField.format.default_time) {
    const isMilitaryFormat = isTimeStringInMilitaryFormat(dateTimeField.format.default_time);

    const timeStringInMilitary = isMilitaryFormat
      ? dateTimeField.format.default_time
      : DateTime.fromFormat(dateTimeField.format.default_time, 'h:mma').toFormat('HH:mm');

    const timeString =
      dateTimeField.format.time_format === 'HH MM (military)'
        ? timeStringInMilitary
        : dateTimeField.format.default_time;

    const [hours, minutes] = timeStringInMilitary.split(':').map(Number);

    const criteriaTimeValues: KnackCriteriaDateTimeValue = {
      time: timeString,
      hours,
      minutes,
      am_pm: hours < 12 ? 'AM' : 'PM'
    };

    return criteriaTimeValues;
  }

  return '';
}

export function getDefaultCriteriaDateValue(dateTimeField: DateTimeField | EquationField) {
  const normalizedDateFormat = defaultDateFormatMap[dateTimeField.format.date_format];

  // If the field is an equation field that can store dates, return the current date since there can't be a default date
  if (dateTimeField.type === 'equation') {
    return DateTime.now().toFormat(normalizedDateFormat);
  }

  if (dateTimeField.format.default_type === 'date' && dateTimeField.format.default_date) {
    const formattedDate = DateTime.fromFormat(
      dateTimeField.format.default_date,
      'MM/dd/yyyy'
    ).toFormat(normalizedDateFormat);

    return formattedDate;
  }

  if (dateTimeField.format.default_type === 'current') {
    return DateTime.now().toFormat(normalizedDateFormat);
  }

  return '';
}

export function getDefaultCriteriaDateTimeValue(dateTimeField: DateTimeField | EquationField) {
  const defaultDate: KnackCriteriaDateTimeValue = {
    date: getDefaultCriteriaDateValue(dateTimeField),

    // Only include time properties if the time is not ignored
    ...(dateTimeField.format.time_format !== 'Ignore Time' && {
      ...getDefaultCriteriaTimeValues(dateTimeField)
    })
  };

  return defaultDate;
}

export function getCriteriaTimeString(
  criteriaDateTimeValue: KnackCriteriaDateTimeValue,
  dateTimeFieldFormat: DateTimeFieldFormat
) {
  if (
    criteriaDateTimeValue.hours === undefined ||
    criteriaDateTimeValue.minutes === undefined ||
    criteriaDateTimeValue.hours === '' ||
    criteriaDateTimeValue.minutes === ''
  ) {
    return '';
  }

  if (dateTimeFieldFormat.time_format === 'HH MM (military)') {
    return DateTime.fromObject({
      hour:
        typeof criteriaDateTimeValue.hours === 'number' ? criteriaDateTimeValue.hours : undefined,
      minute:
        typeof criteriaDateTimeValue.minutes === 'number'
          ? criteriaDateTimeValue.minutes
          : undefined
    })
      .toFormat('HH:mm')
      .toUpperCase();
  }

  return DateTime.fromObject({
    hour: typeof criteriaDateTimeValue.hours === 'number' ? criteriaDateTimeValue.hours : undefined,
    minute:
      typeof criteriaDateTimeValue.minutes === 'number' ? criteriaDateTimeValue.minutes : undefined
  })
    .toFormat('hh:mma')
    .toUpperCase();
}

export function getUpdatedCriteriaDateTimeValueOnTimeChange(
  dateTimeFormat: DateTimeFieldFormat,
  dateTimeValue: KnackCriteriaDateTimeValue,
  newTimeString: string
) {
  // If the time format is military, the time string is already in the correct format
  if (dateTimeFormat.time_format === 'HH MM (military)') {
    const [hours, minutes] = newTimeString.split(':');

    return {
      hours: Number(hours),
      minutes: Number(minutes),
      time: newTimeString,
      ...(dateTimeValue.date && { date: dateTimeValue.date })
    };
  }

  // The new time string needs to be formatted from `hh:mm` to `h:mma`. Example: 04:00 -> 4:00am
  const newTimeFormattedString = DateTime.fromFormat(
    `${newTimeString} ${dateTimeValue.am_pm || 'AM'}`,
    'hh:mm a'
  )
    .toFormat('h:mma')
    .toLowerCase();

  // Parse the time string and convert it to 24-hour format
  const timeInMilitary = DateTime.fromFormat(
    `${newTimeString} ${dateTimeValue.am_pm || 'AM'}`,
    'hh:mm a'
  ).toFormat('H:mm');

  const [hours, minutes] = timeInMilitary.split(':');

  return {
    hours: Number(hours),
    minutes: Number(minutes),
    time: newTimeFormattedString,
    am_pm: dateTimeValue.am_pm || 'AM',
    ...(dateTimeValue.date && { date: dateTimeValue.date })
  };
}

export function getUpdatedCriteriaDateTimeValueOnTimePeriodChange(
  dateTimeValue: KnackCriteriaDateTimeValue,
  newPeriodValue: KnackCriteriaDateTimeValue['am_pm']
) {
  if (
    !newPeriodValue ||
    typeof dateTimeValue.hours !== 'number' ||
    typeof dateTimeValue.minutes !== 'number'
  ) {
    return dateTimeValue;
  }

  const hoursInMilitary =
    newPeriodValue === 'AM' ? dateTimeValue.hours % 12 : (dateTimeValue.hours % 12) + 12;

  // The new time string needs to be formatted from `hh:mm` to `h:mma`. Example: 04:00 -> 4:00am
  const newTimeFormattedString = DateTime.fromObject({
    hour: hoursInMilitary,
    minute: dateTimeValue.minutes
  })
    .toFormat('h:mma')
    .toLowerCase();

  return {
    ...dateTimeValue,
    hours: hoursInMilitary,
    time: newTimeFormattedString,
    am_pm: newPeriodValue
  };
}
