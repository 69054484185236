import { useTranslation } from 'react-i18next';
import { HiExclamationTriangle as ExclamationIcon } from 'react-icons/hi2';
import { Button, Checkbox, Input, Label, RadioGroup } from '@knack/asterisk-react';

import { FormViewRender } from '@/components/views/form/FormViewRender';
import { TableViewRender } from '@/components/views/table/TableViewRender';
import { DemoView } from '@/pages/theme/demo/DemoView';
import { SelectDemo } from './colorsDemoData';
import { signatureDemo } from './signature/signatureDemo';
import { signatureDemoView } from './signature/signatureViewDemo';
import { tableDemo } from './table/tableDemo';
import { tableDemoData } from './table/tableDemoData';
import { tableViewDemoView } from './table/tableViewDemo';
import { ColorGroup, ExampleGroup, Grid } from './utils';

export function ColorsBorder() {
  const [t] = useTranslation();

  return (
    <Grid title={t('components.page_editor.demo_page.border')}>
      <ColorGroup
        color="border-default"
        name="Border Default"
        description="Used in secondary buttons, selects, radio buttons/checkboxes"
      >
        <ExampleGroup group="Button">
          <Button intent="secondary">Secondary Button</Button>
        </ExampleGroup>
        <ExampleGroup group="Radio Button & Checkbox">
          <RadioGroup defaultValue="comfortable">
            <RadioGroup.Container>
              <RadioGroup.Item value="default" id="r1" />
              <Label htmlFor="r1">Radio button</Label>
            </RadioGroup.Container>
          </RadioGroup>
          <Checkbox />
          <Label>Checkbox</Label>
        </ExampleGroup>
        <ExampleGroup group="Selects">
          <SelectDemo />
        </ExampleGroup>
        <ExampleGroup group="Inputs">
          <Input className="min-w-60" placeholder="Example Text Input" />
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="border-subtle"
        name="Border Subtle"
        description="Used in disabled secondary buttons and tables"
      >
        <ExampleGroup group="Button">
          <Button intent="secondary" disabled>
            Disabled Secondary Button
          </Button>
        </ExampleGroup>
        <ExampleGroup group="Table Example" isSingleColumn>
          <DemoView view={tableViewDemoView} sourceTable={tableDemo}>
            <TableViewRender sourceData={tableDemoData} />
          </DemoView>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="border-emphasis"
        name="Border Emphasis"
        description="Used in hovered secondary buttons, radio buttons, checkboxes selects, and inputs"
      >
        <ExampleGroup group="Button">
          <Button intent="secondary">Hover Me</Button>
        </ExampleGroup>
        <ExampleGroup group="Radio Button & Checkbox">
          <RadioGroup defaultValue="comfortable">
            <RadioGroup.Container>
              <RadioGroup.Item value="default" id="r1" />
              <Label htmlFor="r1">Radio button</Label>
            </RadioGroup.Container>
          </RadioGroup>
          <Checkbox />
          <Label>Checkbox</Label>
        </ExampleGroup>
        <ExampleGroup group="Selects">
          <SelectDemo />
        </ExampleGroup>
        <ExampleGroup group="Inputs">
          <Input className="min-w-60" placeholder="Example Text Input" />
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup color="border-brand" name="Border Brand" description="Used in signature field">
        <DemoView view={signatureDemoView} sourceTable={signatureDemo}>
          <FormViewRender />
        </DemoView>
      </ColorGroup>
      <ColorGroup
        color="border-destructive"
        name="Border Destructive"
        description="Used in secondary destructive buttons and destructive states for selects and inputs"
      >
        <ExampleGroup group="Buttons">
          <Button intent="destructiveSecondary">Destructive Secondary</Button>
          <Button intent="destructiveSecondary" disabled>
            Destructive Secondary Disabled
          </Button>
        </ExampleGroup>
        <ExampleGroup group="Inputs">
          <Input.Container>
            <Input.Icon>
              <ExclamationIcon className="text-destructive" />
            </Input.Icon>
            <Input placeholder="Input with Error" intent="destructive" />
          </Input.Container>
        </ExampleGroup>
        <ExampleGroup group="Selects">
          <SelectDemo intent="destructive" />
        </ExampleGroup>
        <ExampleGroup group="Radio Button & Checkbox">
          <RadioGroup defaultValue="comfortable">
            <RadioGroup.Container>
              <RadioGroup.Item value="default" id="r1" onError={() => {}} />
              <Label htmlFor="r1">Radio button</Label>
            </RadioGroup.Container>
          </RadioGroup>
          <Checkbox intent="destructive" />
          <Label>Checkbox</Label>
        </ExampleGroup>
      </ColorGroup>
    </Grid>
  );
}
