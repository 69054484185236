import { useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { InputWithDisabledText } from '@knack/asterisk-react';

import { type TimerField } from '@/types/schema/fields';

type TimerInputProps = {
  field: TimerField;
  intent: 'default' | 'destructive';
  formValueKey: string;
};

export function TimerInput({ intent, formValueKey }: TimerInputProps) {
  const { getValues, register, setValue } = useFormContext();

  const inputValue = getValues(formValueKey);
  // We do not support searching records by timer objects.
  // We only support searching by the timer total time.
  const defaultValue = typeof inputValue === 'object' ? '' : inputValue;
  const { ref: formRef, onBlur, ...formRegister } = register(formValueKey);

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: 2,
      radix: '.',
      padFractionalZeros: true
    },
    {
      defaultValue,
      onAccept: (value: string) => {
        setValue(formValueKey, value);
      }
    }
  );

  return (
    <InputWithDisabledText
      ref={inputRef}
      intent={intent}
      value={typedValue !== undefined ? iMaskValue : defaultValue}
      {...formRegister}
    />
  );
}
