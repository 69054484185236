import { Table } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { TableHeadContent } from '@/components/views/common/table/head/TableHeadContent';
import { getColumnWidth } from '@/components/views/common/table/helper';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';

export function TableHeadOverlayInPageEditor({ column }: { column: ViewWithTableColumn }) {
  return (
    <Table.Head
      style={{ ...getColumnWidth(column) }}
      className={cn(
        'relative h-auto whitespace-nowrap bg-blue-500 bg-opacity-20 px-2 py-1 text-blue-400 outline outline-1 -outline-offset-1 outline-blue-500 first:rounded-tl-lg last:rounded-tr-lg'
      )}
    >
      <TableHeadContent column={column} />
    </Table.Head>
  );
}
