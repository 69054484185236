import { max, mean, min, sum } from 'mathjs';

import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';
import { type ViewTotalSummary } from '@/types/schema/LiveAppView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { type ColumnSummariesValues } from './types';

const fieldTypesAllowedForSummary: KnackFieldType[] = [
  'number',
  'currency',
  'min',
  'max',
  'sum',
  'average',
  'count',
  'rating',
  'equation'
];

export function calculateSummaries(records: ViewRecord[], sourceTableFields: KnackField[]) {
  const numericColumnValues: { [key: string]: number[] } = {};

  records.forEach((record) => {
    Object.entries(record).forEach(([key, value]) => {
      const sourceTableField = sourceTableFields.find((field) => field.key === key);
      if (!sourceTableField) return;

      if (fieldTypesAllowedForSummary.includes(sourceTableField.type)) {
        if (!numericColumnValues[key]) {
          numericColumnValues[key] = [];
        }

        if (sourceTableField.type === 'currency') {
          const formattedValue = value.replace(/[^0-9.-]+/g, '');
          if (formattedValue === '') return;

          numericColumnValues[key].push(Number(formattedValue));
          return;
        }

        // If the value is empty, we don't want to count it
        if (value === '') return;

        numericColumnValues[key].push(value);
      }
    });
  });

  return Object.entries(numericColumnValues).reduce((result, [key, values]) => {
    if (values.length === 0) return result;

    return {
      ...result,
      [key]: {
        sum: sum(values),
        average: mean(values),
        min: min(values),
        max: max(values)
      }
    };
  }, {} as ColumnSummariesValues);
}

export const getSummaryValue = (
  summariesValues: ColumnSummariesValues,
  fieldKey: KnackFieldKey,
  summaryType: ViewTotalSummary['calc']
) => {
  if (!summariesValues || !summariesValues[fieldKey]) return '';

  switch (summaryType) {
    case 'sum':
      return summariesValues[fieldKey].sum;
    case 'average':
      return summariesValues[fieldKey].average;
    case 'min':
      return summariesValues[fieldKey].min;
    case 'max':
      return summariesValues[fieldKey].max;
    default:
      return '';
  }
};

export const getSummaryFieldFormat = (field: KnackField) => {
  if (field.type === 'currency')
    return {
      ...field.format,
      mark_decimal: 'period',
      mark_thousands: 'comma',
      precision: 2
    };

  return field.format;
};

// Replace &nbsp; with a space - Used for formatting numbers e.g 100&nbsp;€ -> 100 €
export const replaceNbsp = (value: string) => value.toString().replace(/&nbsp;/g, ' ');
