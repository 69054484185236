import { useMemo, type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Rating, RichTextRenderer } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type DetailsView, type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { cn } from '@/utils/tailwind';
import { Map } from '@/components/views/map/engine/Map';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewRecordConnectionLink } from '@/components/views/ViewRecordConnectionLink';
import { ViewRecordFileField } from '@/components/views/ViewRecordFileField';

interface DetailsInputValueProps extends ComponentProps<'div'> {
  field: KnackField | undefined;
  input: DetailsViewInput;
  record: FormattedViewRecord;
}

export function DetailsInputValue({ field, input, record, ...rest }: DetailsInputValueProps) {
  const [t] = useTranslation();
  const { view } = useViewContext<ListView | DetailsView | MapView>();

  const sanitizedValue = useMemo(() => {
    if (input.type === 'field' && input.connection && !field) {
      return sanitizeHTML(record.values[`${input?.connection.key}.${input.key}`] || '');
    }
    return sanitizeHTML(record.values[field?.key || '']);
  }, [field, input.connection, input.key, input.type, record.values]);

  // If the record is empty and we are in the page editor, we show a sample value
  if (isPageEditor() && field && Object.keys(record.rawValues).length === 0) {
    const { className, ...restWithoutClassname } = rest;
    return (
      <div className={cn(className, 'min-h-8 p-2 text-muted')} {...restWithoutClassname}>
        {t('components.views.field_sample_value', {
          fieldName: field.name
        })}
      </div>
    );
  }

  if (view.type !== 'map' && input.show_map) {
    <div className={rest.className}>
      <Map
        formattedViewRecords={record ? [record] : []}
        mapHeight={input.map_height}
        addressFieldKey={input.key}
      />
      <div
        className="mt-2"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitizedValue }}
      />
    </div>;
  }

  if (field?.type === 'rating') {
    return (
      <div {...rest}>
        <Rating
          ratingNumber={Number(record.values[field.key])}
          maxRatingNumber={parseInt(field?.format.max, 10)}
          allowHalf={field?.format.allow_half}
        />
      </div>
    );
  }

  if (
    field?.type === 'multiple_choice' &&
    input.conn_separator === 'new_line' &&
    record.values[field.key]
  ) {
    const values = record.values[field.key].split(', ');
    return (
      <div {...rest}>
        <ul>
          {values.map((value: string) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      </div>
    );
  }

  if (field?.type === 'connection') {
    return (
      <div {...rest}>
        <ViewRecordConnectionLink
          rawValues={record.rawValues}
          fieldKey={field.key}
          connLink={input.conn_link}
          connSeparator={input.conn_separator}
        />
      </div>
    );
  }

  if (field?.type === 'file') {
    return (
      <div {...rest}>
        <ViewRecordFileField rawValues={record.rawValues} fieldKey={field.key} />
      </div>
    );
  }

  return <RichTextRenderer {...rest} dangerouslySetInnerHTML={{ __html: sanitizedValue }} />;
}
