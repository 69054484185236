import { useMemo } from 'react';
import { RichTextRenderer } from '@knack/asterisk-react';

import { type ReportViewChart } from '@/types/schema/views/ReportView';
import { sanitizeHTML } from '@/utils/sanitizeHTML';

export function ChartHeader({ chart }: { chart: ReportViewChart }) {
  const { title, description } = chart;

  const sanitizedValue = useMemo(() => sanitizeHTML(description || ''), [description]);

  if (!title && !description) {
    return null;
  }

  return (
    <div className="kn-chart-header mb-4" data-testid="chart-header">
      {title && (
        <h3 className="font-semibold text-emphasis" data-testid={`${chart.id}-title`}>
          {title}
        </h3>
      )}
      {description && <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedValue }} />}
    </div>
  );
}
