import { type Cell, type Table } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { TableActionButton } from '@/components/views/action-button/TableActionButton';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { RecordCellContent } from './RecordCellContent';

interface TableCellProps {
  table: Table<ViewRecord>;
  viewColumn: ViewWithTableColumn;
  cell: Cell<ViewRecord, unknown>;
}

export function TableCell({ table, viewColumn, cell }: TableCellProps) {
  if (cell.row.getCanExpand()) {
    return null;
  }

  if (viewColumn.type !== 'field') {
    return <TableActionButton column={viewColumn} cell={cell} />;
  }

  return <RecordCellContent table={table} column={viewColumn} cell={cell} />;
}
