import { useTranslation } from 'react-i18next';
import { Checkbox, Combobox, Label, RadioGroup } from '@knack/asterisk-react';

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';

interface DependentConnectionInputPlaceholderProps {
  fieldInput: FormViewConnectionInput;
  parentFieldInput: FormViewConnectionInput;
}

export function DependentConnectionInputPlaceholder({
  fieldInput,
  parentFieldInput
}: DependentConnectionInputPlaceholderProps) {
  const [t] = useTranslation();

  const placeholderText = t('components.views.form.connection_input.dependent_input_placeholder', {
    parentInputLabel: parentFieldInput.label
  });

  if (fieldInput.format?.input === 'checkbox') {
    return (
      <Label aria-disabled className="flex items-center">
        <Checkbox disabled checked={false} />
        <span className="ml-2 text-muted">{placeholderText}</span>
      </Label>
    );
  }

  if (fieldInput.format?.input === 'radio') {
    return (
      <RadioGroup disabled>
        <RadioGroup.Container>
          <RadioGroup.Item id="disabled-radio-input" value="" />
          <Label htmlFor="disabled-radio-input" className="text-muted">
            {placeholderText}
          </Label>
        </RadioGroup.Container>
      </RadioGroup>
    );
  }

  if (fieldInput.format?.input === 'chosen') {
    return (
      <Combobox
        id="disabled-combobox"
        disabled
        options={[]}
        isSearchEnabled
        selectedOption={{ key: '', label: placeholderText }}
        onSelectOption={() => {}}
      />
    );
  }

  return null;
}
