import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Calendar, EventModel, Widget } from '@bryntum/calendar';
import { BryntumCalendar, type BryntumCalendarProps } from '@bryntum/calendar-react';
import { useToast } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useDeleteRecordMutation } from '@/hooks/api/mutations/useDeleteRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import {
  formatViewRecordsResponse,
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { transformCalendarFormDateToKnackDate } from '@/components/views/form/inputs/date-time/dateTimeUtils';
import { type RepeatOptions } from '@/components/views/form/inputs/date-time/types';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';
import { useThemingContext } from '@/context/ThemingContext';
import { getCalendarDefaultProps } from './calendarConfig';
import { CalendarEventDetailsModal } from './CalendarEventDetailsModal';
import { CalendarEventFormModal } from './CalendarEventFormModal';
import { CalendarUtils } from './CalendarUtils';
import { getBryntumEvents, getFilters, mappedMode, mappedWeekStartDay } from './helper';
import { type NewEventData } from './type';

import './calendar.css';

interface CalendarViewRenderProps {
  sourceData?: ViewRecordsResponse;
  forceShowEventForm?: boolean;
  forceShowDetails?: boolean;
}

export function CalendarViewRender({
  sourceData,
  forceShowEventForm,
  forceShowDetails
}: CalendarViewRenderProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { theme } = useThemingContext();
  const { view, sourceTable } = useViewContext<CalendarView>();
  const { params } = useViewQueryParamsContext();
  const { canFieldStoreDateValues } = useFieldHelpers();
  const { mutate: deleteRecord } = useDeleteRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({
    viewKey: view.key
  });

  const calendarRef = useRef<BryntumCalendar | null>(null);
  const previousMode = useRef('week');

  const [calendarInstance, setCalendarInstance] = useState<Calendar>();
  const [selectedEventRecord, setSelectedEventRecord] = useState<FormattedViewRecord>();
  const [newEventData, setNewEventData] = useState<NewEventData>();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  });

  const { data, isLoading } = useViewMultipleRecordsQuery({
    view,
    options: {
      filters: getFilters({
        ...dateRange,
        key: view.events.event_field.key,
        viewFilter: params.filters
      })
    }
  });

  const eventKey = view.events.event_field.key;
  const labelKey = view.events.label_field.key;
  const parsedCalendarData = sourceData ? formatViewRecordsResponse(sourceData) : data;
  const events = getBryntumEvents({
    tableData: parsedCalendarData?.records || [],
    eventField: eventKey,
    labelField: labelKey,
    sourceTable,
    event_color_default: view.events.event_color_default,
    event_colors: view.events.event_colors
  });

  const transformedViewData = useMemo(
    () => ({
      mode: view.events.display_type === 'calendar' ? mappedMode[view.events.view] : 'agenda',
      weekStartDay: mappedWeekStartDay[view.events.week_start],
      hideNonWorkingDays: !!view.events.exclude_weekends,
      dayStartTime: view.events.time_min || '0:00',
      dayEndTime: view.events.time_max || '24:00',
      disableEventEditFeature: !view.events.allow_edit,
      disableEventCreate: !view.events.allow_add
    }),
    [view]
  );

  const handleToggle = () => {
    if (calendarRef.current) {
      const currentMode = calendarRef.current.instance.mode;
      if (currentMode !== 'agenda') {
        previousMode.current = currentMode;
        calendarRef.current.instance.mode = 'agenda';
      } else {
        calendarRef.current.instance.mode = previousMode.current;
      }
    }
  };

  const handleDelete = async (recordId: string) =>
    new Promise((resolve) => {
      deleteRecord(
        {
          viewKey: view.key,
          recordId
        },
        {
          onSuccess: () => {
            presentToast({
              title: t('components.views.table.record_delete_success')
            });
            resolve(false);
          },
          onError: () => {
            presentToast({
              title: t('components.views.table.record_delete_error'),
              intent: 'destructive'
            });
            resolve(false);
          }
        }
      );
    });

  const handleEdit = async (
    newStartDate: Date,
    newEndDate: Date,
    eventRecord: EventModel & { data?: { realEventId: string } & Record<KnackFieldKey, KnackField> }
  ) =>
    new Promise((resolve) => {
      const eventField = sourceTable.fields.find((field) => field.key === eventKey);
      const recordValues: FormattedViewRecord = eventRecord.getData('record');
      const { id } = recordValues.values;
      if (eventField && eventRecord.data && id && canFieldStoreDateValues(eventField)) {
        const eventFormat = eventField.format;

        let formattedDate: ReturnType<typeof transformCalendarFormDateToKnackDate> & {
          repeat?: RepeatOptions;
        } = transformCalendarFormDateToKnackDate(eventFormat, {
          startDate: newStartDate,
          endDate: newEndDate,
          eventKey
        });

        if ('repeat' in eventRecord.data[eventKey] && eventRecord.data[eventKey].repeat) {
          formattedDate = {
            ...formattedDate,
            repeat: eventRecord.data[eventKey].repeat as RepeatOptions
          };
        }

        updateRecord(
          {
            data: { [eventKey]: formattedDate },
            recordId: recordValues.values.id
          },
          {
            onSuccess: ({ record: updatedRecord }) => {
              resolve(updatedRecord);
            },
            onError: () => resolve(false)
          }
        );
      } else {
        resolve(false);
      }
    });

  const calendarProps = useMemo(
    () =>
      getCalendarDefaultProps({
        today: new Date(),
        ...transformedViewData
      }),
    [transformedViewData]
  );

  useEffect(() => {
    if (calendarRef.current?.instance) {
      setCalendarInstance(calendarRef.current?.instance);
    }
  }, [calendarRef]);

  useEffect(() => {
    if (calendarRef.current?.instance) {
      calendarRef.current.instance.modes.agenda.setConfig({
        emptyText: isLoading
          ? t('components.views.calendar.loading_events')
          : t('components.views.calendar.no_events'),
        range: mappedMode[view.events.view]
      });
    }
  }, [calendarRef, isLoading, t, view.events.view]);

  const tbarConfig = useMemo<BryntumCalendarProps['tbar']>(
    () => ({
      items: {
        modeSelector: { weight: 100 },
        includeWeekendsButton: false,
        todayButton: { weight: 150 },
        prevButton: { weight: 200 },
        viewDescription: { weight: 300 },
        nextButton: { weight: 400 },
        spacer: { weight: 450, flex: 2 },
        spacer2: {
          text: '',
          flex: 1,
          cls: 'border-none bg-card',
          weight: 180
        }
      }
    }),
    []
  );

  useEffect(() => {
    const tbarItems = calendarRef.current?.instance.tbar.items as Widget[];
    const filterButton = tbarItems.find((item) => item.id.startsWith('filter'));
    if (view.events.allow_change_display && !filterButton) {
      calendarRef.current?.instance.tbar.add({
        id: `filter_${Date.now().toString()}`,
        icon: 'b-fa b-fa-list',
        weight: 500,
        tooltip: t('components.views.calendar.toggle_view'),
        onAction: handleToggle,
        dataset: { ref: 'filter' }
      });
    } else if (!view.events.allow_change_display && filterButton) {
      calendarRef.current?.instance.tbar.remove(filterButton);
    }
  }, [t, view]);

  // TODO: Handle recurrent event edit confirmation in our own modal
  // const recurrenceConfirmationConfig = useMemo<BryntumCalendarProps['recurrenceConfirmationPopup']>(
  //   () => ({
  //     bbar: {
  //       items: {
  //         changeSingleButton: null
  //       }
  //     },
  //     listeners: {
  //       async beforeHide(event: { source: Widget }) {
  //         const { confirmed } = event.source as unknown as { confirmed: boolean };
  //         if (confirmed) {
  //           return !!(await formRef.current?.submitForm());
  //         }
  //         return true;
  //       }
  //     }
  //   }),
  //   []
  // );

  return (
    <div data-kn="calendar-view" data-rounded={theme.appearance.corners === 'rounded'}>
      <ViewHeaderSection view={view} className="mb-4" />
      <CalendarUtils
        calendarInstance={calendarInstance}
        buttons={{
          shouldEnableEvent: view.events.allow_add,
          shouldEnableExport: view.events.ical,
          shouldEnableSubscribe: view.events.rss
        }}
        onAddEventClick={() =>
          setNewEventData({
            startDate: undefined
          })
        }
      />
      <BryntumCalendar
        {...calendarProps}
        tbar={tbarConfig}
        ref={calendarRef}
        events={events}
        autoCreate={false} // Disable double‑click behavior on header cells
        onEventClick={({ eventRecord }) => {
          if (!view.events.show_details) {
            return;
          }
          const formattedRecord: FormattedViewRecord = eventRecord.getData('record');
          setSelectedEventRecord(formattedRecord);
        }}
        // Event for creating a new event by dragging on the calendar
        onDragCreateEnd={({ eventRecord }) => {
          if (
            typeof eventRecord.startDate === 'string' ||
            typeof eventRecord.endDate === 'string'
          ) {
            return;
          }

          setNewEventData({
            startDate: eventRecord.startDate,
            endDate: eventRecord.endDate
          });
        }}
        // Event for creating a new event by double clicking on the calendar
        onScheduleDblClick={({ date }) => {
          setNewEventData({
            startDate: date,
            endDate: DateTime.fromJSDate(date).plus({ hours: 1 }).toJSDate()
          });
        }}
        onBeforeDragResizeEnd={async ({ newStartDate, newEndDate, eventRecord }) => {
          const recordValues: FormattedViewRecord = eventRecord.getData('record');
          const selectedEvent = recordValues.rawValues;
          if (selectedEvent[eventKey].repeat) {
            presentToast({
              title: t('components.views.calendar.dragging_message')
            });
            return false;
          }
          return !!(await handleEdit(newStartDate, newEndDate, eventRecord));
        }}
        onBeforeDragMoveEnd={async ({ newStartDate, newEndDate, eventRecord }) => {
          const recordValues: FormattedViewRecord = eventRecord.getData('record');
          const selectedEvent = recordValues.rawValues;
          if (selectedEvent[eventKey].repeat) {
            presentToast({
              title: t('components.views.calendar.dragging_message')
            });
            return false;
          }
          return !!(await handleEdit(newStartDate, newEndDate, eventRecord));
        }}
        onBeforeEventDelete={async ({ eventRecords }) =>
          !!(await handleDelete(String(eventRecords[0].id)))
        }
        listeners={{
          dateRangeChange({ new: { startDate, endDate } }) {
            setDateRange({ startDate, endDate });
          }
        }}
      />

      {(selectedEventRecord || forceShowDetails) && (
        <CalendarEventDetailsModal
          // If we are force-showing the details modal, we use the first record (if there is one) as the event record
          eventRecord={forceShowDetails ? data?.records[0] : selectedEventRecord}
          onOpenChange={() => setSelectedEventRecord(undefined)}
        />
      )}

      {(newEventData || forceShowEventForm) && (
        <CalendarEventFormModal
          startDate={newEventData?.startDate}
          endDate={newEventData?.endDate}
          onOpenChange={() => setNewEventData(undefined)}
        />
      )}
    </div>
  );
}
