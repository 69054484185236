import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Divider,
  Input,
  InputPassword,
  Label,
  RichTextRenderer
} from '@knack/asterisk-react';
import { type AxiosError } from 'axios';
import { z } from 'zod';

import { type LoginView } from '@/types/schema/views/LoginView';
import { useSignInMutation } from '@/hooks/api/mutations/useSignInMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSupportAccessTool } from '@/hooks/useSupportAccessTool';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { zodEmailRegex } from '@/utils/zod';
import { useViewContext } from '@/components/views/ViewContext';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { useThemingContext } from '@/context/ThemingContext';
import { GoogleButton } from './GoogleButton';
import { LoginErrorBanner } from './LoginErrorBanner';
import { PageEditorSignupButtons } from './signup-buttons/PageEditorSignupButtons';
import { SignupButtons } from './signup-buttons/SignupButtons';

export function LoginForm() {
  const [t] = useTranslation();

  const { view } = useViewContext<LoginView>();
  const { theme } = useThemingContext();
  const { setActiveAuthFlow } = useAuthFlow();
  const { isActive: isSupportAccessToolActive } = useSupportAccessTool();

  const { data: application } = useApplicationQuery();
  const { mutate: signIn, isPending } = useSignInMutation();

  const [loginError, setLoginError] = useState<Error | AxiosError>();

  const logoUrl = theme.mainNavContainer.logo.url;
  const shouldDisplayGoogleButton = application?.settings.sso?.google?.client_id && view.sso_google;
  const hasSignupButtons =
    view.registrationForms &&
    (view.registrationType === 'open' || view.registrationType === 'approval');

  const loginSchema = z.object({
    email: z
      .string()
      .min(1, t('components.views.login.errors.email_required'))
      .regex(zodEmailRegex, t('components.views.login.errors.email_invalid')),
    password: z.string().min(1, t('components.views.login.errors.password_required')),
    remember: z.boolean().default(false)
  });

  const sanitizedValue = useMemo(() => sanitizeHTML(view.description || ''), [view.description]);

  type LoginViewTypeSchema = z.infer<typeof loginSchema>;

  const { handleSubmit, control, register, setValue } = useForm<LoginViewTypeSchema>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
      remember: false
    }
  });

  const onSubmit: SubmitHandler<LoginViewTypeSchema> = async (loginData: LoginViewTypeSchema) => {
    if (isInternalBuilderIframe()) {
      return;
    }

    signIn(loginData, {
      onError: (responseError) => {
        setLoginError(responseError);
      }
    });
  };

  useEffect(() => {
    if (isSupportAccessToolActive) {
      setValue('password', '********');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupportAccessToolActive]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="mb-8 flex flex-col items-center justify-center space-y-2">
          {theme.mainNavContainer.logo.isVisible && logoUrl && (
            <img
              src={theme.mainNavContainer.logo.url}
              alt={`${application?.name} Logo`}
              className="max-w-[64px] sm:max-w-[120px]"
            />
          )}
          {view.title && <h1 className="text-2xl font-medium text-emphasis">{view.title}</h1>}
          {view.description && (
            <RichTextRenderer
              dangerouslySetInnerHTML={{
                __html: sanitizedValue
              }}
            />
          )}
        </div>

        {shouldDisplayGoogleButton && (
          <div className="mb-4">
            <GoogleButton
              onSubmit={() => {
                // Clear any previous errors from the login form if the user is trying to login with Google
                setLoginError(undefined);
              }}
              shouldResetErrors={!!loginError}
            />
          </div>
        )}

        {loginError && (
          <div className="mb-4">
            <LoginErrorBanner error={loginError} />
          </div>
        )}
        <div className="space-y-6">
          <div className="space-y-2">
            {shouldDisplayGoogleButton && <Divider className="mb-2" text={t('keywords.or')} />}
            <Label htmlFor="login-email">{t('keywords.email')}</Label>
            <Input type="email" id="login-email" {...register('email')} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="login-password">{t('keywords.password')}</Label>
            <InputPassword
              {...register('password')}
              id="login-password"
              disabled={isSupportAccessToolActive}
            />
            <div className="flex justify-between">
              <div className="flex items-center">
                <Controller
                  name="remember"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Checkbox
                        id="remember-login"
                        checked={field.value}
                        onClick={() => {
                          field.onChange(!field.value);
                        }}
                      />
                      <Label htmlFor="remember-login" className="ml-2">
                        {t('components.views.login.login.remember_me')}
                      </Label>
                    </>
                  )}
                />
              </div>
              <Button
                intent="link"
                onClick={() => {
                  if (isInternalBuilderIframe()) {
                    return;
                  }
                  setActiveAuthFlow('forgot-password');
                }}
              >
                {t('components.views.login.login.forgot_password')}
              </Button>
            </div>
          </div>
          <Button className="mt-2 w-full" type="submit" intent="primary" isLoading={isPending}>
            {view.submitButtonText || t('actions.submit')}
          </Button>
        </div>
      </form>
      {hasSignupButtons && (
        <div className="mt-4 flex flex-wrap items-center justify-center">
          {t('components.views.login.login.dont_have_account')}
          <div className="flex">
            {isInternalBuilderIframe() ? <PageEditorSignupButtons /> : <SignupButtons />}
          </div>
        </div>
      )}
    </>
  );
}
