import { useTranslation } from 'react-i18next';
import { HiArrowUpOnSquare as ExportIcon } from 'react-icons/hi2';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import { type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type ViewFilterType } from '@/types/schema/LiveAppView';
import {
  useExportViewRecords,
  type ExportViewRecordsFormat
} from '@/hooks/api/queries/useExportViewRecordsQuery';
import { useViewFilters } from '@/hooks/useViewFilters';
import { cn } from '@/utils/tailwind';
import { CalendarViewFiltersPopover } from '@/components/views/calendar/CalendarViewFiltersPopover';
import { ListViewFiltersPopover } from '@/components/views/list/ListViewFiltersPopover';
import { MapViewFiltersPopover } from '@/components/views/map/filters/MapViewFiltersPopover';
import { TableViewFiltersPopover } from '@/components/views/table/TableViewFiltersPopover';
import { useViewContext } from '@/components/views/ViewContext';
import { type ViewWithQueryParams } from '@/components/views/ViewQueryParamsContext';
import { ViewFiltersChips } from './ViewFiltersChips';
import { ViewMenuFilters } from './ViewMenuFilters';
import { ViewSearchInput } from './ViewSearchInput';

type Properties = {
  filterType: ViewFilterType | undefined;
  isKeywordSearchEnabled: boolean | undefined;
  shouldAllowExport: boolean | undefined;
  menuFilters: KnackMenuFilter[];
};

const getViewProperties = (view: ViewWithQueryParams) => {
  const defaultProperties: Properties = {
    filterType: undefined,
    isKeywordSearchEnabled: undefined,
    shouldAllowExport: undefined,
    menuFilters: []
  };

  switch (view.type) {
    case 'map':
      return {
        ...defaultProperties,
        filterType: view.details.filter_type,
        menuFilters: view.details.menu_filters
      };
    case 'calendar':
      return {
        ...defaultProperties,
        filterType: view.filter_type,
        menuFilters: view.menu_filters
      };
    case 'search':
      return {
        ...defaultProperties,
        shouldAllowExport: view.allow_exporting
      };
    default:
      return {
        ...defaultProperties,
        filterType: view.filter_type,
        isKeywordSearchEnabled: view.keyword_search,
        shouldAllowExport: view.allow_exporting,
        menuFilters: view.menu_filters
      };
  }
};

export function ViewToolbarSection({ className }: { className?: string }) {
  const [t] = useTranslation();

  const { view, sourceTable } = useViewContext<ViewWithQueryParams>();
  const { visibleFilters } = useViewFilters(view);
  const exportViewRecords = useExportViewRecords();

  const { filterType, isKeywordSearchEnabled, shouldAllowExport, menuFilters } =
    getViewProperties(view);

  const hasMenuFilters = menuFilters && menuFilters?.length > 0 && filterType === 'menu';
  const shouldNotRenderToolbarSection =
    !shouldAllowExport && filterType !== 'fields' && !isKeywordSearchEnabled && !hasMenuFilters;

  const handleExportViewRecords = async (fileFormat: ExportViewRecordsFormat) => {
    await exportViewRecords({
      viewKey: view.key,
      fileFormat,
      fileName: `${view.title}-exported-records`
    });
  };

  if (shouldNotRenderToolbarSection) {
    return null;
  }

  const renderFilterPopover = () => {
    switch (view.type) {
      case 'table':
        return <TableViewFiltersPopover view={view} sourceObject={sourceTable} />;
      case 'list':
        return <ListViewFiltersPopover view={view} sourceObject={sourceTable} />;
      case 'map':
        return <MapViewFiltersPopover view={view} sourceObject={sourceTable} />;
      case 'calendar':
        return <CalendarViewFiltersPopover view={view} sourceObject={sourceTable} />;
      default:
        return null;
    }
  };

  return (
    <div className={cn(className, 'flex flex-col gap-2')}>
      {(shouldAllowExport || filterType === 'fields' || isKeywordSearchEnabled) && (
        <div
          className={cn('flex flex-row flex-wrap items-center gap-2 md:justify-between', {
            'md:justify-end': filterType === 'none' && !shouldAllowExport && isKeywordSearchEnabled
          })}
        >
          {(shouldAllowExport || filterType === 'fields') && (
            <div className="flex flex-row flex-wrap items-center gap-2">
              {shouldAllowExport && (
                <DropdownMenu>
                  <DropdownMenu.Trigger asChild>
                    <Button
                      data-testid={`${view.key}-export-button`}
                      intent="secondary"
                      className="gap-2 text-default md:w-auto"
                    >
                      <ExportIcon size={16} />
                      <span className="hidden md:inline">{t('actions.export')}</span>
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content align="start">
                    <DropdownMenu.Item onSelect={() => handleExportViewRecords('csv')}>
                      {t('components.views.table.export_csv')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={() => handleExportViewRecords('text')}>
                      {t('components.views.table.export_txt')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={() => handleExportViewRecords('json')}>
                      {t('components.views.table.export_json')}
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu>
              )}
              {filterType === 'fields' && renderFilterPopover()}

              {visibleFilters && visibleFilters.rules.length > 0 && !hasMenuFilters && (
                <ViewFiltersChips visibleFilters={visibleFilters} />
              )}
            </div>
          )}
          {isKeywordSearchEnabled && (
            <div className="flex-1 md:min-w-60 md:flex-none">
              <ViewSearchInput />
            </div>
          )}
        </div>
      )}

      {hasMenuFilters && (
        <div className="mt-2 w-fit" data-testid="view-menu-filters">
          <ViewMenuFilters menuFilters={menuFilters} />
        </div>
      )}
    </div>
  );
}
