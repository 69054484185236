import { type MenuViewSceneLink } from '@/types/schema/views/MenuView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { usePageContext } from '@/context/PageContext';

export function useMenuLinkPagePath() {
  const { getPagePathFromSlug } = usePageSegmentsHelpers();
  const { getPageBySlug } = usePageHelpers();
  const { activePage, activePageRecordId } = usePageContext();

  const getMenuLinkPagePath = (link: MenuViewSceneLink) => {
    const baseScenePath = getPagePathFromSlug(link.scene);

    // If there is no active page or page record ID, there is nothing else to append to the path
    if (!activePage || !activePageRecordId) {
      return baseScenePath;
    }

    const targetPage = getPageBySlug(link.scene);

    // If the target page has a parent, we need to check if we need to include a record ID in the path
    if (targetPage?.parentSlug) {
      if (
        activePage.sourceObjectKey &&
        targetPage.sourceObjectKey &&
        activePage.sourceObjectKey === targetPage.sourceObjectKey
      ) {
        return `${baseScenePath}/${activePageRecordId}`;
      }

      const targetParentPage = getPageBySlug(targetPage.parentSlug);
      const targetGrandParentPage = targetParentPage?.parentSlug
        ? getPageBySlug(targetParentPage.parentSlug)
        : undefined;
      const isChildOfActivePage =
        activePage.slug === targetParentPage?.slug ||
        (targetParentPage?.type === 'authentication' &&
          activePage.slug === targetGrandParentPage?.slug);

      if (isChildOfActivePage) {
        return `${baseScenePath}/${activePageRecordId}`;
      }
    }

    // Otherwise, if the target doesn't have a parent but the active page has a source object key, we append the record ID and object page object key as query params.
    // This is used for pages that are not part of the active page's hierarchy, so we can prepopulate any potential connection fields in form views with the record ID provided.
    if (activePage.sourceObjectKey) {
      return `${baseScenePath}?recordId=${activePageRecordId}&objectKey=${activePage.sourceObjectKey}`;
    }

    return baseScenePath;
  };

  return { getMenuLinkPagePath };
}
