import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@knack/asterisk-react';
import { t } from 'i18next';
import { snakeCase } from 'lodash';

import { type KnackOperator } from '@/types/schema/KnackOperator';
import {
  type SearchViewCriteria,
  type SearchViewFieldInput,
  type SearchViewFilters
} from '@/types/schema/views/SearchView';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';

type SearchFieldOperatorsProps = {
  operators: KnackOperator[];
  searchViewCriteriaIndex: number;
  searchViewFieldInputsByFieldKey: Record<string, SearchViewFieldInput>;
  onCriteriaOperatorChange: (updatedKnackCriteria: SearchViewCriteria) => void;
};

export function SearchFieldOperators({
  operators,
  searchViewCriteriaIndex,
  searchViewFieldInputsByFieldKey,
  onCriteriaOperatorChange
}: SearchFieldOperatorsProps) {
  const { control, getValues } = useFormContext<SearchViewFilters>();

  return (
    <div>
      <Controller
        control={control}
        name={`rules.${searchViewCriteriaIndex}.operator`}
        render={({ field }) => (
          <Select
            defaultValue={field.value}
            value={field.value}
            onValueChange={(newOperator: KnackOperator) => {
              const criteriaToEdit = getValues(`rules.${searchViewCriteriaIndex}`);
              if (shouldHideValueBasedOnOperator(newOperator)) {
                criteriaToEdit.value = '';
              } else {
                criteriaToEdit.value = searchViewFieldInputsByFieldKey[criteriaToEdit.field].value;
              }
              onCriteriaOperatorChange({
                ...criteriaToEdit,
                operator: newOperator
              });
            }}
          >
            <Select.Trigger className="w-full sm:w-[140px]" />
            <Select.Content className="w-full sm:w-[140px]">
              {operators.map((operator) => (
                <Select.Item key={operator} value={operator}>
                  {t(`operators.${snakeCase(operator)}`)}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    </div>
  );
}
