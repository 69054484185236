import { useMemo } from 'react';
import { Divider } from '@knack/asterisk-react';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { cn } from '@/utils/tailwind';
import { NavBrand } from '@/components/main-navigation/NavBrand';
import { NavItemProfile } from '@/components/main-navigation/NavItemProfile';
import { useParsedEntryPages } from '@/components/main-navigation/useNavigation';
import { useThemingContext } from '@/context/ThemingContext';
import { NavVerticalItem } from './NavVerticalItem';

export function NavVerticalContainer({
  containerclasses,
  pageKeyToIgnore
}: {
  containerclasses?: string;
  pageKeyToIgnore?: LiveAppPage['key'];
}) {
  const { theme } = useThemingContext();
  const entryPages = useParsedEntryPages(pageKeyToIgnore);
  const pagesWithoutParent = useMemo(
    () => entryPages?.filter((page) => !page.menuPageKey && page.type !== 'user'),
    [entryPages]
  );

  return (
    <div
      className={cn(
        'kn-main-nav-vertical-container sticky top-0 flex h-screen w-56 max-w-56 shrink-0 flex-col justify-between bg-navigation',
        containerclasses
      )}
    >
      <NavBrand isVerticalNav />
      {theme.mainNavContainer.navigationMenu.isVisible && (
        <nav className="kn-main-nav flex flex-1 flex-col gap-1 overflow-y-auto px-4">
          {pagesWithoutParent.map((item) => (
            <NavVerticalItem key={item.key} item={item} />
          ))}
        </nav>
      )}
      <div className="p-4">
        <Divider className="mb-2" />
        <NavItemProfile />
      </div>
    </div>
  );
}
