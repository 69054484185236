import { useEffect, useRef, useState } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { type MapView } from '@/types/schema/views/MapView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useContainerQuery } from '@/hooks/useContainerQuery';
import { isInternalBuilderIframe, isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { SampleDataBadgeWhenLoginRequired } from '@/components/views/common/SampleDataBadgeWhenLoginRequired';
import { MapDetailsItems } from '@/components/views/map/details/MapDetailsItems';
import { Map } from '@/components/views/map/engine/Map';
import { MapSearch } from '@/components/views/map/filters/MapSearch';
import {
  MapLocationProvider,
  useMapLocationContext
} from '@/components/views/map/MapLocationContext';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

function getPageEditorFilters(view: MapView): ViewFilters | undefined {
  let filterRules: KnackCriteria[] = [];

  if (view.details.filter_type === 'fields') {
    filterRules = [...view.details.preset_filters];
  } else if (view.details.filter_type === 'menu' && view.details.menu_filters.length > 0) {
    filterRules = [
      {
        field: view.details.menu_filters[0].field,
        operator: view.details.menu_filters[0].operator,
        value: view.details.menu_filters[0].value
      }
    ];
  }

  if (view.starting_point === 'address' && view.starting_address) {
    filterRules.push({
      field: view.address_field.key,
      operator: 'near',
      value: view.starting_address,
      range: view.default_range
    });
  }

  return filterRules.length > 0
    ? {
        match: 'and',
        rules: filterRules
      }
    : undefined;
}

function MapViewContent() {
  const { data: application, isLoading: isLoadingApplication } = useApplicationQuery();
  const { view, sourceTable } = useViewContext<MapView>();
  const { params } = useViewQueryParamsContext();
  const { location, isLoading: isLoadingLocation } = useMapLocationContext();

  const [selectedRecord, setSelectedRecord] = useState<FormattedViewRecord>();

  // If we are inside the page editor, we need to create the filters based on the view schema settings
  const searchFilters = isInternalBuilderIframe() ? getPageEditorFilters(view) : params.filters;

  const { data: pagedViewRecords, isFetching: isFetchingRecords } = useViewMultipleRecordsQuery({
    view,
    searchTerm: location,
    emptyResponse: !isInternalBuilderIframe() && (location === '' || !params.filters),
    options: {
      rowsPerPage: params.rowsPerPage,
      page: params.page,
      ...(params.search && { search: params.search }),
      ...(searchFilters && { filters: JSON.stringify(searchFilters) })
    }
  });

  useEffect(() => {
    setSelectedRecord(undefined);
  }, [pagedViewRecords]);

  const containerRef = useRef(null);
  const isSmallVersion = useContainerQuery(containerRef, { maxWidth: 750 });

  const isFiltered = !!location || !!(view.starting_point === 'blank' && params.filters);
  const isSearchTermValid = !!pagedViewRecords?.isSearchTermValid;
  const isEmptyState = !isFiltered;
  const isLoading = isFetchingRecords || isLoadingLocation;

  const isPositionResultsBelowMap = isSmallVersion && (view.position_results_below_map || false);

  const shouldShowSampleDataBadge = isPageEditor() && view.source.authenticated_user;

  return (
    <div ref={containerRef} className="relative w-full">
      {(isLoadingApplication || isLoading || !application) && (
        <div className="absolute z-10 flex size-full bg-card bg-opacity-60">
          <div className="flex size-full items-center justify-center">
            <Spinner />
          </div>
        </div>
      )}
      <ViewHeaderSection view={view} className="mb-4" />
      <MapSearch />
      <ViewToolbarSection className="mb-6" />
      {shouldShowSampleDataBadge && <SampleDataBadgeWhenLoginRequired />}

      <div className={cn('flex w-full gap-4', isPositionResultsBelowMap && 'flex-wrap')}>
        <Map
          formattedViewRecords={pagedViewRecords?.records}
          selectedRecord={selectedRecord}
          onSelectRecord={(r) => setSelectedRecord(r)}
          onUnselectRecord={() => setSelectedRecord(undefined)}
          mapHeight={view.map_height}
          addressFieldKey={view.address_field.key}
          titleFieldKey={view.title_field.key}
          pinColorDefault={view.pin_color_default}
          pinColors={view.pin_colors}
          fields={sourceTable.fields}
          isSmallVersion={isPositionResultsBelowMap}
          isEmptyState={isEmptyState}
          isLoading={isLoading}
          isSearchTermValid={isSearchTermValid}
          searchTerm={location}
          searchCoordinates={pagedViewRecords?.geoOrigin}
        />
        <MapDetailsItems
          pagedViewRecords={pagedViewRecords}
          selectedRecord={selectedRecord}
          onSelectRecord={(r) => setSelectedRecord(r)}
          detailsWidth={view.list_width}
          detailsHeight={view.map_height}
          isSmallVersion={isSmallVersion}
          isEmptyState={isEmptyState}
          searchTerm={location}
          isLoading={isLoading}
          noDataText={view.no_data_text}
          titleFieldKey={view.title_field.key}
        />
      </div>
    </div>
  );
}

export function MapViewRender() {
  return (
    <MapLocationProvider>
      <MapViewContent />
    </MapLocationProvider>
  );
}
