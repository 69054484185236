import { type DetailsView, type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type FormattedDetailsDisplayRuleAction } from '@/components/views/details/useDetailsDisplayRules';

export function getLabelStyleClassNames(
  input: DetailsViewInput,
  view: DetailsView | ListView | MapView
) {
  const isDefaultLabelFormat = !input.format?.label_custom;

  if (isDefaultLabelFormat && view.type !== 'map') {
    return {
      'text-left': view.label_format === 'left',
      'text-right': view.label_format === 'right'
    };
  }

  return {
    'text-right': input.format?.label_format === 'right',
    'text-left': input.format?.label_format === 'left'
  };
}

export function getValueStylesClassNames(
  input: DetailsViewInput,
  displayRules: FormattedDetailsDisplayRuleAction
) {
  return {
    'text-4xl': input.format?.styles?.includes('h1'),
    'text-2xl': input.format?.styles?.includes('h2'),
    'font-bold': input.format?.styles?.includes('strong') || displayRules.valueStyles.bold,
    'font-normal italic': input.format?.styles?.includes('em'),
    italic: displayRules.valueStyles.italic,
    'line-through': displayRules.valueStyles.strikethrough
  };
}
