import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@knack/asterisk-react';

import { type DateTimeRangeType } from '@/types/schema/fields';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { type ViewFilters } from '@/types/schema/LiveAppView';

interface FilterDateTimeRangeInputProps {
  criteriaIndex: number;
  criteriaOperator: KnackOperator;
}

export function FilterDateTimeRangeInput({
  criteriaIndex,
  criteriaOperator
}: FilterDateTimeRangeInputProps) {
  const { control, getValues, setValue } = useFormContext<ViewFilters>();

  // The range value options are values from 1 to 31
  const rangeValueOptions = Array.from(Array(31), (_, i) => (i + 1).toString());
  const dateTimeSingularRangeTypes = [
    'week',
    'month',
    'quarter',
    'year'
  ] satisfies DateTimeRangeType[];
  const dateTimePluralRangeTypes = [
    'days',
    'weeks',
    'months',
    'years',
    'rolling weeks',
    'rolling months',
    'rolling years'
  ] satisfies DateTimeRangeType[];

  // Ensure that the range type has a valid default value for singular range types
  useEffect(() => {
    const rangeType = getValues(`rules.${criteriaIndex}.type`);

    if (criteriaOperator === 'is during the current') {
      const defaultValue = dateTimeSingularRangeTypes[0];
      const isValidValue = !!(
        rangeType && (dateTimeSingularRangeTypes as string[]).includes(rangeType)
      );

      if (!isValidValue) {
        setValue(`rules.${criteriaIndex}.type`, defaultValue);
      }
    } else {
      const defaultValue = dateTimePluralRangeTypes[0];
      const isValidValue = !!(
        rangeType && (dateTimePluralRangeTypes as string[]).includes(rangeType)
      );

      if (!isValidValue) {
        setValue(`rules.${criteriaIndex}.type`, defaultValue);
      }
    }
  }, [
    criteriaIndex,
    setValue,
    getValues,
    dateTimeSingularRangeTypes,
    criteriaOperator,
    dateTimePluralRangeTypes
  ]);

  if (criteriaOperator === 'is during the current') {
    return (
      <Controller
        control={control}
        name={`rules.${criteriaIndex}.type`}
        defaultValue={dateTimeSingularRangeTypes[0]}
        render={({ field: { value, onChange } }) => (
          <Select value={value} onValueChange={onChange}>
            <Select.Trigger className="h-9 w-full" />
            <Select.Content>
              {dateTimeSingularRangeTypes.map((type) => (
                <Select.Item key={type} value={type}>
                  {type}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  return (
    <div className="flex w-full gap-2">
      <Controller
        control={control}
        name={`rules.${criteriaIndex}.range`}
        defaultValue={rangeValueOptions[0]}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value !== undefined ? String(value) : rangeValueOptions[0]}
            onValueChange={onChange}
          >
            <Select.Trigger className="h-9 w-28 min-w-0" />
            <Select.Content className="min-w-fit">
              {rangeValueOptions.map((day) => (
                <Select.Item key={day} value={day}>
                  {day}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
      <Controller
        name={`rules.${criteriaIndex}.type`}
        defaultValue={dateTimePluralRangeTypes[0]}
        render={({ field }) => (
          <Select value={field.value} onValueChange={(val) => field.onChange(val)}>
            <Select.Trigger defaultValue="1" className="h-9 w-full min-w-0" />
            <Select.Content className="min-w-fit">
              {dateTimePluralRangeTypes.map((type) => (
                <Select.Item key={type} value={type}>
                  {type}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    </div>
  );
}
