import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronIcon } from 'react-icons/hi2';
import { Table } from '@knack/asterisk-react';
import { type Row } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { cn } from '@/utils/tailwind';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { useThemingContext } from '@/context/ThemingContext';

interface GroupingCellLabelProps {
  row: Row<ViewRecord>;
  column: ViewWithTableColumn;
}

export function GroupingCellLabel({ row, column }: GroupingCellLabelProps) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();

  const isRowExpanded = row.getIsExpanded();
  const collapsibleRowValue =
    row.original.value ??
    t('components.views.table.empty_record_text', { columnHeader: column.header });
  const nestedRowsValues = row.original.subRows as ViewRecord[];
  const isNestedInsideAnotherRow = row.depth > 0;

  // Count the number of nested rows recursively
  function countNestedRows(nestedRows: ViewRecord[]): number {
    return nestedRows.reduce((acc, nestedRow) => {
      // If the row has subRows, only count the subRows recursively
      if (nestedRow.subRows && nestedRow.subRows.length > 0) {
        return acc + countNestedRows(nestedRow.subRows);
      }

      // If no subRows, count this row
      return acc + 1;
    }, 0);
  }

  const nestedRowsNumber = countNestedRows(nestedRowsValues);
  const sanitizedValue = sanitizeHTML(collapsibleRowValue);

  return (
    <Table.Cell
      data-testid={`grouping-cell-${row.depth}`}
      className="cursor-pointer border-x-0 px-2 py-3 group-hover:cursor-pointer group-hover:bg-subtle"
      style={{
        paddingLeft: isNestedInsideAnotherRow ? `${row.depth * 1.5}rem` : undefined
      }}
    >
      <div className="flex min-h-5 cursor-pointer items-center gap-1">
        <ChevronIcon
          className={cn({
            'rotate-180': isRowExpanded
          })}
        />
        <div className="flex gap-1.5">
          <button
            aria-label={t('components.views.table.grouping_cell')}
            type="button"
            className={cn('font-medium', {
              'text-muted': !row.original.value
            })}
            onClick={(e) => e.preventDefault()}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizedValue }}
          />
          {nestedRowsNumber > 0 ? (
            <span
              data-testid={`grouping-label-${row.id}-nested-rows`}
              className={cn(
                'flex min-h-5 min-w-5 items-center justify-center bg-subtle px-1 group-hover:bg-muted',
                {
                  'bg-muted': isRowExpanded,
                  'rounded-md': theme.appearance.corners === 'rounded'
                }
              )}
            >
              {nestedRowsNumber}
            </span>
          ) : null}
        </div>
      </div>
    </Table.Cell>
  );
}
