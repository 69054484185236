import { Table } from '@knack/asterisk-react';
import { type Row, type Table as TanstackTable } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { calculateSummaries } from '@/components/views/common/table/summaries/helper';
import {
  type ViewWithTable,
  type ViewWithTableColumn
} from '@/components/views/common/table/types';
import { useViewContext } from '@/components/views/ViewContext';
import { GroupingCellLabel } from './GroupingCellLabel';
import { GroupingCellSummary } from './GroupingCellSummary';

interface GroupingCellProps {
  viewColumns: ViewWithTableColumn[];
  row: Row<ViewRecord>;
  table: TanstackTable<ViewRecord>;
}

export function GroupingCell({ viewColumns, table, row }: GroupingCellProps) {
  const { sourceTable } = useViewContext<ViewWithTable>();

  const isTriggerCollapsibleRow = row.getCanExpand();

  const nestedRowsValues = row.original.subRows as ViewRecord[];
  const rawValues = table.options.meta?.rawValues as ViewRecord[];
  const valuesIds = new Set(nestedRowsValues.map((item) => item.id));
  const filteredRawValues = rawValues.filter((item) => valuesIds.has(item.id));

  const columnSummaries = table.options.meta?.columnSummaries ?? [];
  const groupSummaries =
    columnSummaries.length > 0 ? calculateSummaries(filteredRawValues, sourceTable.fields) : null;

  if (!isTriggerCollapsibleRow) {
    return null;
  }

  return viewColumns.map((col, index) => {
    if (index === 0) {
      return <GroupingCellLabel key={`${col.id}-grouping-cell`} row={row} column={col} />;
    }

    if (
      groupSummaries &&
      groupSummaries[col.field?.key] &&
      // This is temporary until we have complete design for the grouping cell summary - FE-4588
      // https://knackhq.slack.com/archives/C049HLP66RK/p1742482114221739
      (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
    ) {
      return (
        <GroupingCellSummary
          key={`${col.id}-grouping-cell`}
          column={col}
          table={table}
          groupSummaries={groupSummaries}
          dataTestId={`grouping-cell-${row.depth}-${index}-summary`}
        />
      );
    }

    return (
      <Table.Cell
        key={`${col.id}-grouping-cell`}
        data-testid={`grouping-cell-${row.depth}-${index}`}
        className={cn('border-x-0 px-2 py-3 group-hover:cursor-pointer group-hover:bg-subtle')}
      />
    );
  });
}
