import { type LiveAppPageFull } from '@/types/schema/LiveAppPage';
import { PagePrint } from './PagePrint';

export function PageTitle({ page }: { page: LiveAppPageFull }) {
  if (page.type === 'authentication' || !page.isTitleVisible) {
    return null;
  }

  return (
    <h1 className="kn-page-title flex items-center gap-2 text-2xl">
      {page.name}
      {page.print && <PagePrint />}
    </h1>
  );
}
