import { memo, useEffect, useState } from 'react';
import type {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type DetailsView, type DetailsViewOuterColumn } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type SearchListView } from '@/types/schema/views/SearchView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { DragOverlayContent } from './DragOverlayContent';
import { DroppableDetailsOuterColumns } from './DroppableDetailsOuterColumns';

interface DetailsOuterColumnsSortableProps {
  view: DetailsView | MapView | ListView | CalendarView | SearchListView;
  record: FormattedViewRecord;
  outerColumnsClassname?: string;
}

function DetailsOuterColumnsSortable({
  view,
  record,
  outerColumnsClassname
}: DetailsOuterColumnsSortableProps) {
  const { updatePage, setIsDraggingActive } = usePageEditorContext();

  const [sortableOuterColumns, setSortableOuterColumns] = useState<DetailsViewOuterColumn[]>(() => {
    if (view.type === 'search') {
      return view.results.columns;
    }
    if (view.type === 'map' || view.type === 'calendar') {
      return view.details.columns;
    }
    return view.columns;
  });

  // Sensors for the drag and drop operations
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        tolerance: 5,
        // Require to press for 100ms to start dragging, this can reduce the chance of dragging accidentally due to page scroll
        delay: 100
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // State for keeping track of the id of the input being dragged. Needed for rendering the dragging overlay
  const [beingDraggedInputId, setBeingDraggedInputId] = useState<UniqueIdentifier | null>(null);

  // Custom collision detection strategy optimized for multiple containers
  const collisionDetectionStrategy: CollisionDetection = (args) => {
    const droppableContainers = args.droppableContainers.filter((droppableContainer) => {
      if (droppableContainer.data.current?.type === 'input') {
        // If the droppable input is the same as the active input, we only want it if it's being dragged within same column
        if (droppableContainer.id === args.active.id) {
          return (
            droppableContainer.data.current?.sortable.containerId ===
            args.active.data.current?.sortable.containerId
          );
        }

        return true;
      }

      // If the droppable container is a column, we only want it if it's empty
      if (droppableContainer.data.current?.type === 'column') {
        return droppableContainer.data.current.isEmpty;
      }

      return true;
    });

    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin({
      ...args,
      droppableContainers
    });

    // If there are collisions with the pointer, return them
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // If the pointer is not colliding with any droppable container, let's check if the pointer is colliding with any container if we modify the pointer coordinates
    const pointerCollisionsModifiedCoordinates = pointerWithin({
      ...args,
      pointerCoordinates: args.pointerCoordinates
        ? {
            x: args.pointerCoordinates.x,
            y: args.pointerCoordinates.y - 25 // Move up the pointer position by 25px to check for collisions with the container above
          }
        : null,
      droppableContainers
    });

    // If there are collisions with the pointer with modified coordinates, return them
    if (pointerCollisionsModifiedCoordinates.length > 0) {
      return pointerCollisionsModifiedCoordinates;
    }

    // Otherwise, return a closestCorners collision detection
    return closestCorners({
      ...args,
      droppableContainers
    });
  };

  const resetDraggingState = () => {
    setBeingDraggedInputId(null);
    setIsDraggingActive(false);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setBeingDraggedInputId(active.id);
    setIsDraggingActive(true);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!active || !over) {
      return;
    }

    let updatedOuterColumns = sortableOuterColumns;

    const activeOuterColumnId = active.data.current?.sortable.containerId;

    // If the element being dragged over is not an input, it means we are dragging an input over an empty column
    const overOuterColumnId =
      over.data.current?.type === 'input' ? over.data.current?.sortable.containerId : over.id;

    if (!activeOuterColumnId || !overOuterColumnId) {
      return;
    }

    const activeOuterColumn = sortableOuterColumns.find(
      (column) => column.id === activeOuterColumnId
    );

    // If the input is being dragged within the same column, we can just update the input order within the column
    if (activeOuterColumnId === overOuterColumnId) {
      updatedOuterColumns = sortableOuterColumns.map((outerColumn) => {
        if (outerColumn.id === activeOuterColumn?.id) {
          const activeColumnGroup = outerColumn.groups[0];

          return {
            ...outerColumn,
            groups: [
              {
                ...activeColumnGroup,
                columns: activeColumnGroup.columns.map((innerColumn) => {
                  const oldIndex = innerColumn.findIndex((input) => input.id === active.id);
                  const newIndex = innerColumn.findIndex((input) => input.id === over.id);
                  return arrayMove(innerColumn, oldIndex, newIndex);
                })
              }
            ]
          } satisfies DetailsViewOuterColumn;
        }

        return outerColumn;
      });
    } else {
      const activeInput = activeOuterColumn?.groups[0]?.columns[0].find(
        (input) => input.id === active.id
      );

      if (activeInput) {
        // At this point we know that the input is being dragged from one outer column to another
        updatedOuterColumns = sortableOuterColumns.map((outerColumn) => {
          const outerColumnInputs = outerColumn.groups[0].columns[0];

          // Remove the input from the active column
          if (outerColumn.id === activeOuterColumnId) {
            return {
              ...outerColumn,
              groups: [
                {
                  ...outerColumn.groups[0],
                  columns: [outerColumnInputs.filter((input) => input.id !== active.id)]
                }
              ]
            } satisfies DetailsViewOuterColumn;
          }

          // Add the input to the new column
          if (outerColumn.id === overOuterColumnId) {
            // If the column is empty, add the input as the only item
            if (outerColumnInputs.length === 0) {
              return {
                ...outerColumn,
                groups: [
                  {
                    ...outerColumn.groups[0],
                    columns: [[activeInput]]
                  }
                ]
              } satisfies DetailsViewOuterColumn;
            }

            const existingInputIndex = outerColumnInputs.findIndex((input) => input.id === over.id);

            // Determine if the input being dragged should be placed above or below the input it is being dragged over
            // We only need to do this if the target input is the first one in the column, otherwise we consider the input to be placed below the target input
            const isBelowInput =
              existingInputIndex === 0
                ? over &&
                  active.rect.current.translated &&
                  active.rect.current.translated.top > over.rect.top + over.rect.height / 2
                : true;

            const modifier = isBelowInput ? 1 : 0;
            const newIndex =
              existingInputIndex >= 0
                ? existingInputIndex + modifier
                : outerColumnInputs.length + 1;

            return {
              ...outerColumn,
              groups: [
                {
                  ...outerColumn.groups[0],
                  columns: [
                    [
                      ...outerColumnInputs.slice(0, newIndex),
                      activeInput,
                      ...outerColumnInputs.slice(newIndex, outerColumnInputs.length)
                    ]
                  ]
                }
              ]
            } satisfies DetailsViewOuterColumn;
          }

          return outerColumn;
        });
      }
    }

    const getUpdatedView = () => {
      if (view.type === 'search') {
        return {
          ...view,
          results: {
            ...view.results,
            columns: updatedOuterColumns
          }
        };
      }
      if (view.type === 'map') {
        return {
          ...view,
          details: {
            ...view.details,
            columns: updatedOuterColumns
          }
        };
      }

      if (view.type === 'calendar') {
        return {
          ...view,
          details: {
            ...view.details,
            columns: updatedOuterColumns
          }
        };
      }

      return {
        ...view,
        columns: updatedOuterColumns
      };
    };

    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: getUpdatedView()
    });

    // Update the local items state with the updated columns for immediate UI update
    setSortableOuterColumns(updatedOuterColumns);

    resetDraggingState();
  };

  useEffect(() => {
    setSortableOuterColumns(() => {
      if (view.type === 'search') {
        return view.results.columns;
      }
      if (view.type === 'map' || view.type === 'calendar') {
        return view.details.columns;
      }
      return view.columns;
    });
  }, [view]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={resetDraggingState}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.BeforeDragging
        }
      }}
    >
      <SortableContext
        items={sortableOuterColumns.map((sortableOuterColumn) => sortableOuterColumn.id)}
      >
        <DroppableDetailsOuterColumns
          outerColumns={sortableOuterColumns}
          record={record}
          className={outerColumnsClassname}
        />
      </SortableContext>

      <DragOverlay>
        <DragOverlayContent
          record={record}
          items={sortableOuterColumns}
          beingDraggedInputId={beingDraggedInputId}
        />
      </DragOverlay>
    </DndContext>
  );
}

const memoized = memo(DetailsOuterColumnsSortable);
export { memoized as DetailsOuterColumnsSortable };
