import { Table } from '@knack/asterisk-react';

import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { type ViewWithTableColumn } from '@/components/views/common/table/types';
import { TableHeadOverlayInPageEditor } from './TableHeadOverlayInPageEditor';

interface TableOverlayProps {
  column: ViewWithTableColumn;
  records: FormattedViewRecord[];
}

// This component is used in the Page Editor to render the overlay during the DnD
export function TableOverlayInPageEditor({ column, records }: TableOverlayProps) {
  return (
    <Table key={column.id} className="pointer-events-none">
      <Table.Header>
        <Table.Row>
          <TableHeadOverlayInPageEditor column={column} />
        </Table.Row>
      </Table.Header>
      <Table.Body className="max-h-20 border border-blue-500 border-t-default">
        {records.map((record) => (
          <Table.Row key={record.values.id}>
            <Table.Cell className="h-10 bg-blue-50 p-2"> </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
