import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, RichTextRenderer } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { DetailsOuterColumnsSortableWrapper } from '@/components/views/details/details-columns-sortable/DetailsOuterColumnsSortableWrapper';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { useViewContext } from '@/components/views/ViewContext';

interface CalendarEventDetailsModalProps {
  eventRecord: FormattedViewRecord | undefined;
  onOpenChange: () => void;
}

export function CalendarEventDetailsModal({
  eventRecord,
  onOpenChange
}: CalendarEventDetailsModalProps) {
  const [t] = useTranslation();

  const { view } = useViewContext<CalendarView>();

  const record = eventRecord || { values: {}, rawValues: {} };
  const sanitizedValue = useMemo(
    () => sanitizeHTML(view.details.description || ''),
    [view.details.description]
  );

  return (
    <Dialog open onOpenChange={onOpenChange} modal={false}>
      <Dialog.Content aria-describedby={undefined} modal={false}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {view.details.title || t('components.views.calendar.event_details')}
            </Dialog.Title>
            {view.details.description && (
              <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
            )}
          </Dialog.Header>
          <div className="pt-4">
            {isPageEditor() ? (
              <DetailsOuterColumnsSortableWrapper view={view} record={record} />
            ) : (
              <DetailsOuterColumns record={record} onAfterDeleteAction={onOpenChange} />
            )}
          </div>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
