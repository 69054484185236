import { type ListView } from '@/types/schema/views/ListView';

export const listViewDemoView: ListView = {
  id: '66ba08315c4e01010ffd8bf2',
  columns: [
    {
      id: 'column_1',
      groups: [
        {
          id: 'group_1',
          columns: [
            [
              {
                id: 'field_22',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_22',
                name: 'Name'
              },
              {
                id: 'field_54',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_54',
                name: 'Short Text'
              },
              {
                id: 'field_58',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_58',
                name: 'Currency'
              },
              {
                id: 'field_69',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_69',
                name: 'Phone'
              },
              {
                id: 'field_66',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_66',
                name: 'Link'
              },
              {
                id: 'field_65',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'delete',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_65',
                name: 'Address'
              },
              {
                id: 'field_59',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_59',
                name: 'Multiselect'
              },
              {
                id: 'field_8',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'delete',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'delete',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_8',
                name: 'Delete'
              },
              {
                id: 'field_9',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'view',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'scene_link',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                scene: 'mock-data-8-details',
                key: 'field_9',
                name: ''
              },
              {
                id: 'field_10',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'edit',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'scene_link',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                scene: 'edit-mock-data-83',
                key: 'field_10',
                name: ''
              }
            ]
          ]
        }
      ],
      width: 100
    }
  ],
  links: [],
  inputs: [],
  groups: [],
  hide_fields: false,
  keyword_search_fields: 'view',
  name: 'MOCK_DATA (8)s',
  type: 'list',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: null,
    sort: [
      {
        field: 'field_17',
        order: 'asc'
      }
    ],
    object: 'object_1'
  },
  description: '',
  layout: 'full',
  label_format: 'left',
  rows_per_page: '25',
  allow_limit: true,
  allow_exporting: false,
  keyword_search: true,
  allow_preset_filters: false,
  filter_type: 'fields',
  preset_filters: [],
  menu_filters: [],
  filter_fields: 'view',
  rules: {
    fields: []
  },
  list_layout: 'two-column',
  key: 'view_46',
  no_data_text: 'No Data',
  design: {}
};
