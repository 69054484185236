import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiPencil as EditIcon } from 'react-icons/hi';
import { FloatingPortal, useFloating } from '@floating-ui/react';
import { Table } from '@knack/asterisk-react';
import { type Row, type Table as TanstackTable } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { TableCellRender } from '@/components/views/common/table/cell/TableCellRender';
import { getZebraStripeClass } from '@/components/views/common/table/helper';
import { type InsertPositionState } from '@/components/views/common/table/sortable/SortableTableWrapper';
import { type ViewWithTable } from '@/components/views/common/table/types';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import {
  useSectionEditorContext,
  type ViewInputHoverIdentifier
} from '@/pages/page/page-editor/SectionEditorContext';
import { useThemingContext } from '@/context/ThemingContext';

interface PageEditorTableRowRenderProps {
  row: Row<ViewRecord>;
  level?: number;
  table: TanstackTable<ViewRecord>;
  rowSpacingClasses: string;
  insertPositionState?: InsertPositionState | null;
  shouldEnableInlineEditing: boolean;
}

export function PageEditorTableRowRender({
  row,
  level = 0,
  table,
  rowSpacingClasses,
  insertPositionState,
  shouldEnableInlineEditing
}: PageEditorTableRowRenderProps) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const { view } = useViewContext<ViewWithTable>();
  const rowRef = useRef<HTMLTableRowElement | null>(null);

  const { startEditViewInput, updatePage } = usePageEditorContext();
  const { setViewInputHover, currentHoveredViewInput } = useSectionEditorContext();

  const columnId = row.original.groupBy;
  const viewRowHoverIdentifier: ViewInputHoverIdentifier = `${view.key}:${columnId}-${row.id}`;
  const isRowHovered = currentHoveredViewInput === viewRowHoverIdentifier;

  const canRowExpand = row.getCanExpand();
  const isRowExpanded = row.getIsExpanded();
  const isNestedRow = level > 0;
  const hasTableGrouping = table.getCanSomeRowsExpand();

  // When grouping is enabled, we can't use the native odd/even, because it counts the grouped rows as rows
  // We calculate manually where to apply the zebra stripe
  const zebraStripeClass = useMemo(
    () => getZebraStripeClass({ row, hasTableGrouping, canRowExpand }),
    [canRowExpand, hasTableGrouping, row]
  );

  const { refs, floatingStyles } = useFloating({
    placement: 'top-start',
    elements: {
      reference: rowRef.current
    }
  });

  if (isNestedRow) {
    return null;
  }

  const onRemoveColumn = () => {
    if (view.type === 'search') {
      updatePage({
        type: 'view',
        origin: 'live-app',
        action: 'update',
        updatedView: {
          ...view,
          results: {
            ...view.results,
            columns: view.results.columns.filter((col) => col.id !== columnId)
          }
        }
      });
    }

    if (view.type === 'table') {
      updatePage({
        type: 'view',
        origin: 'live-app',
        action: 'update',
        updatedView: {
          ...view,
          columns: view.columns.filter((col) => col.id !== columnId)
        }
      });
    }
  };

  return (
    <React.Fragment key={row.id}>
      <Table.Row
        ref={rowRef}
        className={cn('group border-default', {
          'first-of-type:border-b': canRowExpand,
          'border-y bg-subtle': canRowExpand && isRowExpanded,
          'bg-muted': theme.tables.hasAlternateRowColor && zebraStripeClass === 'even-row',
          'bg-card':
            (theme.tables.hasAlternateRowColor && zebraStripeClass === 'odd-row') || canRowExpand,
          'bg-subtle': canRowExpand && isRowExpanded,
          'odd:bg-card even:bg-muted': theme.tables.hasAlternateRowColor && !hasTableGrouping,
          'outline outline-1 -outline-offset-1 outline-blue-500': isRowHovered
        })}
        {...(canRowExpand && { onClick: row.getToggleExpandedHandler() })}
        {...(isPageEditor() &&
          canRowExpand && {
            onMouseEnter: () => setViewInputHover(viewRowHoverIdentifier),
            onMouseLeave: () => setViewInputHover(null)
          })}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCellRender
            key={cell.id}
            cell={cell}
            className={rowSpacingClasses}
            insertPositionState={insertPositionState}
            shouldEnableInlineEditing={shouldEnableInlineEditing}
          />
        ))}
      </Table.Row>

      {isRowExpanded &&
        row.subRows?.map((subRow) => (
          <PageEditorTableRowRender
            key={subRow.id}
            row={subRow}
            table={table}
            level={level + 1}
            rowSpacingClasses={rowSpacingClasses}
            insertPositionState={insertPositionState}
            shouldEnableInlineEditing={shouldEnableInlineEditing}
          />
        ))}

      {isRowHovered && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, top: 26 }}
            className="h-auto rounded-br-lg border border-blue-500 bg-blue-500 p-0"
            onMouseEnter={() => setViewInputHover(viewRowHoverIdentifier)}
            onMouseLeave={() => setViewInputHover(null)}
          >
            <div className="flex h-6 items-center px-1 text-white">
              <button
                type="button"
                aria-label={t('actions.edit')}
                className="mr-1 inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                onClick={() => startEditViewInput({ viewKey: view.key, viewInputId: columnId })}
              >
                <EditIcon size={14} />
              </button>
              <button
                type="button"
                aria-label={t('actions.delete')}
                className="inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                onClick={onRemoveColumn}
              >
                <DeleteIcon size={14} />
              </button>
            </div>
          </div>
        </FloatingPortal>
      )}
    </React.Fragment>
  );
}
