import { useCallback } from 'react';

import {
  type TableDisplayRuleAction,
  type TableDisplayRuleIcon,
  type ViewWithTableDisplayRule
} from '@/types/schema/LiveAppView';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';

interface DefaultTableDisplayRulesActionsStyles {
  backgroundColor: string;
  color: string;
  fontStyle: string;
  fontWeight: string;
  textDecoration: string;
  shouldHideContent: boolean;
  icon: TableDisplayRuleIcon;
  iconColor: string;
}

interface FormattedTableDisplayRule {
  cellStyle: {
    backgroundColor: string;
    color: string;
    fontStyle: string;
    fontWeight: string;
    textDecoration: string;
  };
  shouldHideContent: boolean;
  icon: TableDisplayRuleIcon & { color: string };
}

export function useTableDisplayRules(
  recordRawValues: ViewRecord,
  displayRules?: ViewWithTableDisplayRule[]
) {
  const { sourceTable } = useViewContext<TableView>();

  const getActiveTableDisplayRulesActions = useCallback(() => {
    const actions: TableDisplayRuleAction[] = [];

    if (!displayRules || !recordRawValues) {
      return actions;
    }

    displayRules.forEach((rule) => {
      if (isEveryCriteriaMet(recordRawValues, sourceTable.fields, rule.criteria)) {
        rule.actions.forEach((action) => {
          const isExistingType = actions.some((a) => a.action === action.action);

          // If the action type already exists, only keep the last one
          if (isExistingType) {
            const existingAction = actions.find((a) => a.action === action.action);

            if (existingAction && existingAction.field === action.field) {
              actions.splice(actions.indexOf(existingAction), 1);
            }
          }

          actions.push(action);
        });
      }
    });

    return actions;
  }, [displayRules, recordRawValues, sourceTable.fields]);

  const processDisplayRuleActions = useCallback((): FormattedTableDisplayRule => {
    const activeDisplayRuleActions = getActiveTableDisplayRulesActions();

    if (activeDisplayRuleActions.length === 0) {
      return {
        cellStyle: {
          backgroundColor: '',
          color: '',
          fontStyle: '',
          fontWeight: '',
          textDecoration: ''
        },
        shouldHideContent: false,
        icon: {
          icon: '',
          align: 'left',
          color: ''
        }
      };
    }

    const defaultTableDisplayRulesActionsStyles: DefaultTableDisplayRulesActionsStyles = {
      backgroundColor: '',
      color: '',
      fontStyle: '',
      fontWeight: '',
      textDecoration: '',
      shouldHideContent: false,
      icon: {
        icon: '',
        align: 'left'
      },
      iconColor: ''
    };

    const {
      backgroundColor,
      color,
      fontStyle,
      fontWeight,
      textDecoration,
      shouldHideContent,
      icon,
      iconColor
    } =
      activeDisplayRuleActions.reduce((acc, action) => {
        switch (action.action) {
          case 'bg-color':
            acc.backgroundColor = action.value;
            break;
          case 'text-color':
            acc.color = action.color;
            break;
          case 'text-style':
            if (action.bold) {
              acc.fontWeight = 'bold';
            }
            if (action.italic) {
              acc.fontStyle = 'italic';
            }
            if (action.strikethrough) {
              acc.textDecoration = 'line-through';
            }
            break;
          case 'hide':
            acc.shouldHideContent = true;
            break;
          case 'icon':
            acc.iconColor = action.color;
            acc.icon = {
              icon: action.icon.icon,
              align: action.icon.align
            };
            break;
          default:
            break;
        }

        return acc;
      }, defaultTableDisplayRulesActionsStyles) || {};

    return {
      cellStyle: {
        backgroundColor,
        color,
        fontStyle,
        fontWeight,
        textDecoration
      },
      shouldHideContent,
      icon: {
        icon: icon.icon,
        align: icon.align,
        color: iconColor
      }
    };
  }, [getActiveTableDisplayRulesActions]);

  return processDisplayRuleActions();
}
