import { FullPageSpinner } from './components/FullPageSpinner';
import { usePageContext } from './context/PageContext';
import { usePageQuery } from './hooks/api/queries/usePageQuery';
import { PageEditorContextProvider } from './pages/page/page-editor/PageEditorContext';

export function BuilderAppPageWrapper({ children }: { children?: React.ReactNode }) {
  const { activePage, shouldRestrictAccess } = usePageContext();
  const { data: fullPage, isLoading } = usePageQuery({
    pageSlug: activePage?.slug,
    enabled: !shouldRestrictAccess
  });

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (!fullPage) {
    throw new Error(
      `Something went wrong loading the page with slug: ${activePage?.slug} and key: ${activePage?.key}`
    );
  }

  return <PageEditorContextProvider initialPage={fullPage}>{children}</PageEditorContextProvider>;
}
