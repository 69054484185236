import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function usePageHelpers() {
  const { data: application } = useApplicationQuery();

  const getPageBySlug = (slug: string) => application?.pages.find((page) => page.slug === slug);

  return {
    getPageBySlug
  };
}
