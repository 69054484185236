import { useState } from 'react';
import { BsChevronContract as CollapseIcon, BsChevronExpand as ExpandIcon } from 'react-icons/bs';
import { Button, RichTextRenderer } from '@knack/asterisk-react';
import truncate from 'lodash/truncate';

import { cn } from '@/utils/tailwind';

export function DefaultRecordCellContent({
  value,
  isCharacterLimitExceeded,
  characterLimit
}: {
  value: string;
  isCharacterLimitExceeded: boolean;
  characterLimit?: number;
}) {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const CollapsibleIcon = isContentExpanded ? CollapseIcon : ExpandIcon;

  const toogleContentExpansion = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Stop propagation to avoid triggering inline editing when clicking the button
    e.stopPropagation();
    setIsContentExpanded(!isContentExpanded);
  };

  return (
    <div>
      <RichTextRenderer
        className={cn({
          'mr-6': isCharacterLimitExceeded
        })}
        dangerouslySetInnerHTML={{
          __html:
            isCharacterLimitExceeded && !isContentExpanded
              ? // We add +3 to take into account the 3 ellipsis points added by the truncate function
                truncate(value, { length: Number(characterLimit) + 3 })
              : value
        }}
      />
      {isCharacterLimitExceeded && (
        <Button
          data-testid="cell-content-expand-button"
          className="absolute bottom-0 right-0 size-4"
          size="xs"
          intent="minimalStandalone"
          onClick={toogleContentExpansion}
        >
          <CollapsibleIcon size={16} />
        </Button>
      )}
    </div>
  );
}
