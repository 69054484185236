import { useRef } from 'react';

import { type ListView } from '@/types/schema/views/ListView';
import { type SearchListView } from '@/types/schema/views/SearchView';
import {
  formatViewRecordsResponse,
  type PagedViewRecords,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { useContainerQuery } from '@/hooks/useContainerQuery';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { DetailsOuterColumnsSortableWrapper } from '@/components/views/details/details-columns-sortable/DetailsOuterColumnsSortableWrapper';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { ViewFooterSection } from '@/components/views/view-footer/ViewFooterSection';

type ListViewContentProps = {
  pagedViewRecords?: PagedViewRecords;
  sourceData?: ViewRecordsResponse;
  view: ListView | SearchListView;
};

export function ListViewContent({ view, pagedViewRecords, sourceData }: ListViewContentProps) {
  const containerRef = useRef(null);

  const isSmallVersion = useContainerQuery(containerRef, { maxWidth: 750 });

  const parsedListData = sourceData ? formatViewRecordsResponse(sourceData) : pagedViewRecords;
  if (parsedListData?.records.length === 0 && isPageEditor()) {
    parsedListData.records = [{ values: {}, rawValues: {} }];
  }

  const { list_layout: listColumnLayout } = view;
  const totalPageNumber = pagedViewRecords?.totalPages || 0;

  const listColumnLayoutNumber: Record<string, number> = {
    'one-column': 1,
    'two-column': 2,
    'three-column': 3
  };

  return (
    <div ref={containerRef}>
      <div
        className={cn('mb-4 grid w-full gap-4 overflow-x-auto', {
          'grid-cols-1': listColumnLayoutNumber[listColumnLayout] === 1,
          'grid-cols-1 md:grid-cols-2': listColumnLayoutNumber[listColumnLayout] === 2,
          'grid-cols-1 md:grid-cols-3': listColumnLayoutNumber[listColumnLayout] === 3
        })}
      >
        {parsedListData?.records.length ? (
          parsedListData.records.map((record, recordIndex) =>
            isPageEditor() ? (
              <DetailsOuterColumnsSortableWrapper
                key={record.values.id}
                view={view}
                record={record}
                recordIndex={recordIndex}
                withNonSortableOverlay
              />
            ) : (
              <DetailsOuterColumns key={record.values.id} record={record} />
            )
          )
        ) : (
          <p>{view.no_data_text}</p>
        )}
      </div>
      <ViewFooterSection totalPageNumber={totalPageNumber} isSmallFooter={isSmallVersion} />
    </div>
  );
}
