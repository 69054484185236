import { useFormContext, type FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';
import { Input } from '@knack/asterisk-react';

import { type SearchViewFieldInput, type SearchViewFilters } from '@/types/schema/views/SearchView';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { SearchInstructions } from './SearchInstructions';

type KeywordSearchInputProps = {
  searchViewFieldInput: SearchViewFieldInput;
  searchViewCriteriaIndex: number;
  errors: FieldErrors<SearchViewFilters>;
};

export function KeywordSearchInput({
  searchViewFieldInput,
  searchViewCriteriaIndex,
  errors
}: KeywordSearchInputProps) {
  const [t] = useTranslation();

  const { register } = useFormContext<SearchViewFilters>();

  const fieldName = searchViewFieldInput.name || t('actions.search');
  const name = searchViewFieldInput.required ? `${fieldName} *` : fieldName;
  const isError = errors.rules && errors.rules[searchViewCriteriaIndex];

  return (
    <div className="flex flex-col gap-2">
      <div className={cn('font-bold', { 'text-destructive': isError })}>{name}</div>

      <div>
        <Input.Container>
          <Input
            className="h-9"
            intent={isError ? 'destructive' : 'default'}
            {...register(`rules.${searchViewCriteriaIndex}.value`)}
          />
          <Input.Icon>
            <SearchIcon size={14} className="shrink-0" />
          </Input.Icon>
        </Input.Container>

        {errors.rules && (
          <FormErrorMessage
            errors={errors}
            name={`rules.${searchViewCriteriaIndex}.value`}
            className="mt-1"
          />
        )}
        {searchViewFieldInput.instructions && (
          <SearchInstructions>{searchViewFieldInput.instructions}</SearchInstructions>
        )}
      </div>
    </div>
  );
}
