import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppView } from '@/types/schema/LiveAppView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isPageEditor, isPageEditorPreview } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';
import { type FormattedViewRecord, type ViewRecord } from './useViewRecordQuery';

export type GeoOrigin = {
  latitude: number;
  longitude: number;
};

export interface ViewRecordsResponse {
  geo_origin?: GeoOrigin;
  records: ViewRecord[];
  total_pages: number;
  current_page: number;
  total_records: number;
  location_fail?: boolean;
}

export type ViewRecordsQueryOptions = {
  format?: 'both' | 'raw';
  rowsPerPage?: string | null;
  page?: number | null;
  search?: string;
  sortField?: KnackFieldKey;
  sortOrder?: 'asc' | 'desc';
  filters?: string | null;
};

type GetViewDataParams = {
  activePage: LiveAppPage;
  activeRecordId: string | null;
  view: LiveAppView;
  options?: ViewRecordsQueryOptions;
  emptyResponse: boolean;
};

export type PagedViewRecords = {
  records: FormattedViewRecord[];
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  isSearchTermValid: boolean;
  geoOrigin?: GeoOrigin;
};

export const formatViewRecordsResponse = (data: ViewRecordsResponse): PagedViewRecords => {
  if (data.location_fail) {
    return {
      records: [],
      totalRecords: 0,
      totalPages: 0,
      currentPage: 0,
      isSearchTermValid: false,
      geoOrigin: data.geo_origin
    };
  }

  const formattedRecords = data.records.map((record) => {
    const formattedRecord: { values: ViewRecord; rawValues: ViewRecord } = {
      values: {
        id: record.id
      },
      rawValues: {
        id: record.id
      }
    };

    Object.keys(record).forEach((key) => {
      if (key.endsWith('_raw')) {
        const newKey = key.replace('_raw', '');
        formattedRecord.rawValues[newKey] = record[key];

        // If the field is a phone field
        if (record[key]?.area) {
          formattedRecord.rawValues[newKey] = record[key].formatted;
        }

        // If the field is a timer, we need to format the data to match the expected in the frontend
        if (record[key]?.times) {
          [formattedRecord.rawValues[newKey]] = record[key].times;
        }
      } else if (key === 'geo_distance') {
        formattedRecord.values[key] = record[key];
        formattedRecord.rawValues[key] = record[key];
      } else {
        formattedRecord.values[key] = record[key];
      }
    });

    return formattedRecord;
  });

  return {
    records: formattedRecords,
    totalRecords: data.total_records,
    totalPages: data.total_pages,
    currentPage: data.current_page,
    isSearchTermValid: true,
    geoOrigin: data.geo_origin
  };
};

async function getViewData({
  activePage,
  activeRecordId,
  view,
  options,
  emptyResponse
}: GetViewDataParams) {
  if (emptyResponse) {
    return {
      records: [],
      totalRecords: 0,
      totalPages: 0,
      currentPage: 0,
      isSearchTermValid: true
    };
  }

  const { rowsPerPage, page, sortOrder, sortField, filters, ...restOptions } = options || {};

  const queryParams = new URLSearchParams({
    ...restOptions,
    ...(rowsPerPage && { rows_per_page: rowsPerPage }),
    ...(page && { page: page.toString() }),
    ...(sortOrder && { sort_order: sortOrder }),
    ...(sortField && { sort_field: sortField }),
    ...(filters && filters !== 'null' && { filters }),

    // If the active page has a record id associated with it, we need to pass it as a query parameter (in the format `page-slug_id`)
    // in order to filter the records based on the connection between the view source and the record associated with the page, if there is one.
    ...(activeRecordId && { [`${activePage.slug}_id`]: activeRecordId })
  } as Record<string, string>);

  // If we are not in the page editor, we simply fetch the records from the view endpoint
  if (!isPageEditor() && !isPageEditorPreview()) {
    const { data } = await axios.get<ViewRecordsResponse>(
      `v1/scenes/${activePage.key}/views/${view.key}/records`,
      {
        params: queryParams,
        withCredentials: true,
        headers: getAppBasedRequestHeaders()
      }
    );

    return formatViewRecordsResponse(data);
  }

  // Otherwise, we call an endpoint that we pass the full `view` object to in the request body, that way the records
  // can be fetched based on the view fields being used in the preview, and new views that don't exist in the schema yet can display records.
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  // The endpoint uses a POST request to avoid the URL length limit when passing the view object
  const { data } = await axios.post<ViewRecordsResponse>(
    `/v1/live-app/${accountSlug}/${appSlug}/scenes/${activePage.key}/views/view-preview-records`,
    {
      view
    },
    {
      params: queryParams,
      withCredentials: true,
      headers: getAppBasedRequestHeaders()
    }
  );

  return formatViewRecordsResponse(data);
}

export function useViewMultipleRecordsQuery({
  view,
  enabled = true,
  options,
  emptyResponse = false,
  searchTerm = ''
}: {
  view: LiveAppView;
  enabled?: boolean;
  options?: ViewRecordsQueryOptions;
  emptyResponse?: boolean;
  searchTerm?: string; // Used for location in Map View
}) {
  const { activePage, activePageRecordId, recordIdFromParams } = usePageContext();

  if (!activePage) {
    throw new Error('useViewMultipleRecordsQuery must be used within a page context');
  }

  const activeRecordId = activePageRecordId || recordIdFromParams;
  const optionEntries = Object.entries(options || {}).map(([key, value]) => `${key}-${value}`);

  const queryKey = [
    queryKeys.viewData,
    view.id,
    ...optionEntries,
    searchTerm,
    { recordId: activePageRecordId }
  ];

  return useQuery({
    queryKey,
    queryFn: async () => getViewData({ activePage, activeRecordId, view, options, emptyResponse }),
    enabled,
    placeholderData: keepPreviousData
  });
}
