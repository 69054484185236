import { useMemo, useState, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, useToast } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { useDeleteRecordMutation } from '@/hooks/api/mutations/useDeleteRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { useViewSubmitRules } from '@/hooks/useViewSubmitRules';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { useViewContext } from '@/components/views/ViewContext';
import { ActionButton } from './ActionButton';
import { DeleteRecordConfirmDialog } from './DeleteRecordConfirmDialog';
import { FallbackActionButton } from './FallbackActionButton';

interface ViewActionButtonProps {
  input: DetailsViewInput;
  inputActionIndex: number;
  record: FormattedViewRecord | undefined;
  onAfterDeleteAction?: () => void;
}

export function ListActionButton({
  input,
  inputActionIndex,
  record,
  onAfterDeleteAction
}: ViewActionButtonProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { view, sourceTable } = useViewContext();
  const { getActivePagePathname } = usePageSegmentsHelpers();
  const { handleSubmitRuleAction } = useViewSubmitRules();

  const { mutate: deleteRecord } = useDeleteRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({ viewKey: view.key });

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const shouldRenderTextLinkButton = input.type === 'scene_link' && input.link_type === 'text';
  const shouldRenderFieldLinkButton = input.type === 'scene_link' && input.link_type === 'field';

  const sanitizedValue = useMemo(
    () => sanitizeHTML(record?.values?.[input.key]),
    [input.key, record?.values]
  );

  if (input.type === 'field') {
    return null;
  }

  const handleAction = (actionLinkIndex?: number) => {
    if (!record?.values.id) {
      return;
    }

    switch (input.type) {
      case 'delete':
        deleteRecord(
          {
            viewKey: view.key,
            recordId: record.values.id
          },
          {
            onSuccess: () => {
              presentToast({
                title: t('components.views.table.record_delete_success')
              });
              setIsDeleteConfirmationModalOpen(false);

              if (onAfterDeleteAction) {
                onAfterDeleteAction();
              }
            },
            onError: () => {
              presentToast({
                title: t('components.views.table.record_delete_error'),
                intent: 'destructive'
              });
            }
          }
        );
        break;
      default:
        updateRecord(
          {
            data: {
              id: record.values.id,
              action_link_index: inputActionIndex
            },
            recordId: record.values.id
          },
          {
            onSuccess: () => {
              if (actionLinkIndex === undefined || !input.action_rules) return;

              const applicableSubmitRule = input.action_rules[actionLinkIndex].submit_rules[0];

              if (!applicableSubmitRule) return;

              handleSubmitRuleAction({
                applicableRule: applicableSubmitRule,
                shouldShowToast: true
              });
            }
          }
        );
    }
  };

  if (shouldRenderTextLinkButton) {
    const linkTo = isPageEditor()
      ? ''
      : `${getActivePagePathname()}/${input.scene}/${record?.values.id}`;

    return <ActionButton icon={input.icon} label={input.link_text} linkTo={linkTo} />;
  }

  if (shouldRenderFieldLinkButton) {
    const linkTo = isPageEditor()
      ? ''
      : `${getActivePagePathname()}/${input.scene}/${record?.values.id}`;

    return (
      <span className="flex items-center">
        <LabelWithIcon icon={input.icon?.icon} iconPosition={input.icon?.align || 'left'}>
          <Button intent="link" data-testid="details-field-link-button" asChild>
            <Link to={linkTo} dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
          </Button>
        </LabelWithIcon>
      </span>
    );
  }

  if (input.type === 'action_link') {
    if (!input.action_rules) {
      return null;
    }

    // When adding a Dynamic Action from the Page Editor, we need to display a temporary button until the user adds text to it.
    const isPageEditorAddingNewDynamicAction = isPageEditor() && input.action_rules.length === 0;

    if (isPageEditorAddingNewDynamicAction) {
      return <FallbackActionButton />;
    }

    let actionLinkIndex: number = 0;

    if (isPageEditor()) {
      // Don't check the criteria in the Page Editor and return the first action rule button text
      return <FallbackActionButton label={input.action_rules[0].link_text} />;
    }

    if (!record?.values) {
      return null;
    }

    // Find the first set of rules that is met to show the button - we only display one button per cell
    const activeActionRule = input.action_rules.find((rule, index) => {
      actionLinkIndex = index;
      return (
        isEveryCriteriaMet(record.rawValues, sourceTable?.fields || [], rule.criteria) ||
        rule.criteria.length === 0
      );
    });

    if (!activeActionRule) {
      return null;
    }

    return (
      <ActionButton
        onClick={() => handleAction(actionLinkIndex)}
        icon={input.icon}
        label={activeActionRule.link_text}
      />
    );
  }

  if (input.type === 'delete') {
    return (
      <DeleteRecordConfirmDialog
        isOpen={isDeleteConfirmationModalOpen}
        onOpenChange={setIsDeleteConfirmationModalOpen}
        onConfirm={handleAction}
      >
        <ActionButton
          icon={input.icon}
          label={input.link_text}
          isDelete
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setIsDeleteConfirmationModalOpen(true);
          }}
        />
      </DeleteRecordConfirmDialog>
    );
  }

  return <ActionButton onClick={() => handleAction()} icon={input.icon} label={input.link_text} />;
}
