import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSupportAccessTool } from '@/hooks/useSupportAccessTool';
import { BREAKPOINTS } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { SupportAccessToolBanner } from '@/components/layout/SupportAccessToolBanner';
import { NavHorizontalContainer } from '@/components/main-navigation/horizontal/NavHorizontalContainer';
import { NavMobileContainer } from '@/components/main-navigation/mobile/NavMobileContainer';
import { NavVerticalContainer } from '@/components/main-navigation/vertical/NavVerticalContainer';
import { usePageContext } from '@/context/PageContext';
import { useThemingContext } from '@/context/ThemingContext';

export function PageLayout({ children }: { children?: React.ReactNode }) {
  const navBrandRef = useRef(null);
  const shouldUseMobileNav = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const { activePage } = usePageContext();
  const { theme } = useThemingContext();
  const navClasses = cn('w-full bg-navigation', {
    'mb-4 text-center': theme.mainNavContainer.layout === 'horizontal',
    'border-b border-subtle':
      theme.mainNavContainer.layout === 'horizontal' && theme.mainNavContainer.hasBorder,
    'border-r border-subtle':
      theme.mainNavContainer.layout === 'vertical' && theme.mainNavContainer.hasBorder,
    'p-4': theme.mainNavContainer.layout === 'horizontal' || shouldUseMobileNav,
    shadow: theme.mainNavContainer.hasShadow,
    'sticky top-0 z-20': theme.mainNavContainer.isSticky
  });
  const supportAccessTool = useSupportAccessTool();

  const navContainer =
    theme.mainNavContainer.layout === 'vertical' ? (
      <NavVerticalContainer containerclasses={navClasses} />
    ) : (
      <NavHorizontalContainer containerclasses={navClasses} navBrandRef={navBrandRef} />
    );

  const navComponent = shouldUseMobileNav ? (
    <NavMobileContainer containerclasses={navClasses} />
  ) : (
    navContainer
  );
  const mainNav = activePage?.pageMenuDisplay && navComponent;

  return (
    <div
      className={cn({
        flex: theme.mainNavContainer.layout === 'vertical',
        'flex-col': shouldUseMobileNav
      })}
    >
      {!supportAccessTool.isPending && supportAccessTool.isVisible && <SupportAccessToolBanner />}
      {mainNav}
      <div
        className={cn('kn-page-container p-4', {
          'container-fluid w-full': theme.appWidth === 'full',
          'container max-w-screen-xl': theme.appWidth === 'max'
        })}
      >
        <Breadcrumbs />
        <main className="kn-page flex flex-col gap-4" data-testid="page">
          {children || <Outlet />}
        </main>
      </div>
    </div>
  );
}
