import { useRef, type ComponentPropsWithoutRef, type CSSProperties } from 'react';
import { Chart } from '@knack/asterisk-react';
import {
  Bar,
  BarChart,
  LabelList,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

import type { ChartData, ReportViewChart } from '@/types/schema/views/ReportView';
import { getDefaultChartLayout } from '@/utils/schema/patch/reports';
import { ChartDataTable } from './shared/ChartDataTable';
import { ChartEmptyState } from './shared/ChartEmptyState';
import { ChartExportButton } from './shared/ChartExportButton';
import { ChartHeader } from './shared/ChartHeader';
import { useChart } from './shared/useChart';

interface BarChartHorizontalRenderProps extends ComponentPropsWithoutRef<'div'> {
  chart: ReportViewChart;
  chartData: ChartData;
  cssProps: CSSProperties;
}

export function BarChartHorizontalRender({
  chart,
  chartData,
  cssProps,
  ...rest
}: BarChartHorizontalRenderProps) {
  const { chartRecords, chartCalculationsFormat, chartKeys, chartConfig, hasMultipleGroups } =
    useChart({ chart, chartData });

  const chartRef = useRef<HTMLDivElement>(null);

  if (
    !chartData ||
    !chartData.records ||
    chartData.records.length === 0 ||
    chartRecords.length === 0
  ) {
    return <ChartEmptyState />;
  }

  const chartLayout = chart.layout || getDefaultChartLayout();
  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';
  const shouldStackCalculations = chartLayout.stacking === 'normal';
  const shouldTiltLabels = chartLayout.tilt_labels;
  const { shouldShowDataTable, export_links: shouldShowExportButton } = chart.options;

  const addBarBorderRadius = (index: number) => {
    const defaultBorderRadius = 5;

    if (!shouldStackCalculations) {
      return defaultBorderRadius;
    }

    if (index === 0) {
      const borderRadius: [number, number, number, number] = [
        0,
        defaultBorderRadius,
        defaultBorderRadius,
        0
      ];
      return borderRadius;
    }

    if (index === Object.keys(chartKeys).length - 1) {
      const borderRadius: [number, number, number, number] = [
        defaultBorderRadius,
        0,
        0,
        defaultBorderRadius
      ] as const;
      return borderRadius;
    }

    return undefined;
  };

  return (
    <div className="flex-col">
      <div className="kn-bar-horizontal-chart" data-testid="bar-horizontal-chart" {...rest}>
        <ChartHeader chart={chart} />
        {shouldShowExportButton && <ChartExportButton chart={chart} chartRef={chartRef} />}
        <Chart ref={chartRef} config={chartConfig} className="w-full" style={cssProps}>
          <BarChart
            accessibilityLayer
            style={cssProps}
            data={chartRecords}
            layout="vertical"
            margin={{
              left: 20,
              right: 20
            }}
          >
            <YAxis
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value}
              dataKey="group_0"
            />
            <XAxis
              type="number"
              hide
              {...(shouldTiltLabels ? { angle: -45, textAnchor: 'end' } : {})}
            />
            <RechartsTooltip cursor={false} content={<Chart.TooltipContent hideLabel />} />
            {chartLayout.legend !== 'none' && (
              <RechartsLegend
                content={<Chart.LegendContent />}
                layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
                verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
                align={
                  chartLayout.legend === 'bottom'
                    ? 'center'
                    : (chartLayout.legend as HorizontalAlignmentType)
                }
              />
            )}
            {Object.keys(chartKeys).map((key, index) => {
              if (hasMultipleGroups && key === 'calc_0') {
                return null;
              }
              return (
                <Bar
                  key={`${chart.id}-bar-${key}`}
                  dataKey={key}
                  fill={`var(--color-${key})`}
                  radius={addBarBorderRadius(index)}
                  stackId={shouldStackCalculations ? '1' : undefined}
                >
                  {chartLayout.data_labels && (
                    <LabelList
                      dataKey={key}
                      position={shouldStackCalculations ? 'inside' : 'right'}
                      fill={shouldStackCalculations ? '#FFF' : undefined}
                      formatter={(value: number) => `${chartCalculationsFormat}${value}`}
                      offset={8}
                      className="fill-foreground"
                      fontSize={12}
                    />
                  )}
                </Bar>
              );
            })}
          </BarChart>
        </Chart>
      </div>
      {shouldShowDataTable && (
        <ChartDataTable chart={chart} chartRecords={chartRecords} chartConfig={chartConfig} />
      )}
    </div>
  );
}
