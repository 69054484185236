import { useLocation } from 'react-router-dom';

import { type KnackObjectKey } from '@/types/schema/KnackObject';
import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getApplicationBasePath } from '@/utils/application';
import { usePageContext } from '@/context/PageContext';

export function usePageSegmentsHelpers() {
  const location = useLocation();

  const { activePage, pageSegments } = usePageContext();
  const { data: application } = useApplicationQuery();

  const getActivePageSegment = () => {
    if (pageSegments.length === 0) {
      return null;
    }

    return pageSegments[pageSegments.length - 1];
  };

  const getActivePagePathname = () => {
    if (!activePage) {
      return '/';
    }

    // If the current location has no page segments, it means it's the home page.
    // However, we still need the page's slug so we can generate the correct path when linking to child pages.
    if (location.pathname === '/') {
      return `/${activePage.slug}`;
    }

    return location.pathname;
  };

  const getPreviousPagePath = () => {
    // If there is only one or fewer page segments, there is no previous page
    if (pageSegments.length <= 1) {
      return '/';
    }

    let pathname = '';

    pageSegments.forEach((segment, index) => {
      // We don't want to include the last segment in the pathname
      if (index === pageSegments.length - 1) {
        return;
      }

      pathname += `/${segment.slug}`;

      if (segment.recordId) {
        pathname += `/${segment.recordId}`;
      }
    });

    return pathname;
  };

  const getPreviousPageFullUrl = () => {
    const previousPath = getPreviousPagePath();
    const applicationBasePath = getApplicationBasePath();
    return `${window.location.origin}${applicationBasePath}${previousPath}`;
  };

  const getPagePathFromSlug = (slug: string) => {
    const pages = application?.pages || [];

    const page = pages.find((p) => p.slug === slug);
    let parentPage = pages.find((p) => p.slug === page?.parentSlug);

    let pagePath = `/${slug}`;

    while (parentPage) {
      if (!parentPage || parentPage.type === 'authentication') {
        break;
      }

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const parentPageSegment = pageSegments.find((segment) => segment.slug === parentPage?.slug);

      // If the parent page is a current segment in the URL and it has a record ID, we need to include it in the path
      if (parentPageSegment?.recordId) {
        pagePath = `/${parentPage.slug}/${parentPageSegment.recordId}${pagePath}`;
      } else {
        pagePath = `/${parentPage.slug}${pagePath}`;
      }

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      parentPage = pages.find((p) => p.slug === parentPage?.parentSlug);
    }

    return pagePath;
  };

  // Gets the path of a child page to redirect to based on its slug. This is used when redirecting to a child page from a view (e.g. from a submit rule).
  const getRedirectToChildPagePathFromSlug = (slug: string, recordId?: string) => {
    const activeSegment = getActivePageSegment();
    let childPagePath = `${getActivePagePathname()}/${slug}`;

    // We need to include the relevant record ID in the path (if there is one).
    // The record ID could be the record ID of the active page segment or a record ID passed as an argument.
    const relevantRecordId = recordId || activeSegment?.recordId;
    if (relevantRecordId) {
      childPagePath += `/${relevantRecordId}`;
    }

    return childPagePath;
  };

  // Gets the path of a page to redirect to based on its slug. This is used when redirecting to a page from a view (e.g. from a submit rule)
  const getRedirectToPagePathFromSlug = (
    slug: string,
    viewSourceObjectKey: KnackObjectKey | undefined,
    recordId?: string
  ) => {
    const pages = application?.pages || [];
    const activeSegment = getActivePageSegment();

    let pagePath = `/${slug}`;

    const targetPage = pages.find((p) => p.slug === slug);

    const isSameSourceObject =
      targetPage?.sourceObjectKey && targetPage?.sourceObjectKey === viewSourceObjectKey;

    // If the source object of the target page is the same as the source object of the view, we need to include the relevant record ID in the path (if there is one).
    // The record ID could be the record ID of the active page segment or a record ID passed as an argument.
    const relevantRecordId = recordId || activeSegment?.recordId;
    if (relevantRecordId && isSameSourceObject) {
      pagePath += `/${relevantRecordId}`;
    }

    let parentPage = pages.find((p) => p.slug === targetPage?.parentSlug);

    while (parentPage) {
      if (!parentPage || parentPage.type === 'authentication') {
        break;
      }

      pagePath = `/${parentPage.slug}${pagePath}`;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      parentPage = pages.find((p) => p.slug === parentPage?.parentSlug);
    }

    return pagePath;
  };

  // Get the path of a specific segment from its index
  const getSegmentPath = (segmentIndex: number) => {
    let pathname = '';

    pageSegments.forEach((segment, index) => {
      if (segmentIndex < index) {
        return;
      }

      pathname += `/${segment.slug}`;

      if (segment.recordId) {
        pathname += `/${segment.recordId}`;
      }
    });

    return pathname;
  };

  // Gets the array of ancestor pages for a given page
  const getPageAncestorPages = (page: LiveAppPage) => {
    if (!page || !application?.pages) {
      return [];
    }

    let currentPage: LiveAppPage | null = page;
    const ancestorPages: LiveAppPage[] = [];

    while (currentPage) {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const parentPage = application.pages.find((p) => p.slug === currentPage?.parentSlug);

      if (parentPage && parentPage.type !== 'authentication') {
        ancestorPages.unshift(parentPage);
        currentPage = parentPage;
      } else {
        currentPage = null;
      }
    }

    return ancestorPages;
  };

  return {
    getActivePageSegment,
    getActivePagePathname,
    getPagePathFromSlug,
    getRedirectToPagePathFromSlug,
    getRedirectToChildPagePathFromSlug,
    getPreviousPagePath,
    getPreviousPageFullUrl,
    getSegmentPath,
    getPageAncestorPages
  };
}
