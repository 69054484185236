import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, RichTextRenderer } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import { ListActionButton } from '@/components/views/action-button/ListActionButton';

interface DetailsStaticInputProps {
  input: DetailsViewInput;
  inputActionIndex?: number;
  record: FormattedViewRecord | undefined;
  onAfterDeleteAction?: () => void;
}

export function DetailsStaticInput({
  input,
  record,
  inputActionIndex = 0,
  onAfterDeleteAction
}: DetailsStaticInputProps) {
  const [t] = useTranslation();

  const sanitizedInputCopyText = useMemo(() => sanitizeHTML(input.copy), [input.copy]);

  if (input.type === 'divider') {
    return <Divider className="w-full py-2" />;
  }

  if (input.type === 'special_title') {
    if (!input.value && !input.copy) {
      // If we are on the page editor and the input is empty, we show a placeholder
      if (isPageEditor()) {
        return <p className="py-2 text-subtle">{t('components.views.empty_static_input')}</p>;
      }

      return null;
    }

    return (
      <div>
        {input.value && <h3 className="mb-1 text-lg font-medium">{input.value}</h3>}
        {input.copy && (
          <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedInputCopyText }} />
        )}
      </div>
    );
  }

  if (input.type === 'action_link' || input.type === 'scene_link' || input.type === 'delete') {
    return (
      <div className="mr-1 inline-block" key={input.id}>
        <ListActionButton
          input={input}
          record={record}
          inputActionIndex={inputActionIndex}
          onAfterDeleteAction={onAfterDeleteAction}
        />
      </div>
    );
  }

  return null;
}
