import React, { useMemo } from 'react';
import { Table } from '@knack/asterisk-react';
import { type Row, type Table as TanstackTable } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { TableCellRender } from '@/components/views/common/table/cell/TableCellRender';
import { getZebraStripeClass } from '@/components/views/common/table/helper';
import { type InsertPositionState } from '@/components/views/common/table/sortable/SortableTableWrapper';
import { useThemingContext } from '@/context/ThemingContext';

interface TableRowRenderProps {
  row: Row<ViewRecord>;
  level?: number;
  table: TanstackTable<ViewRecord>;
  rowSpacingClasses: string;
  insertPositionState?: InsertPositionState | null;
  shouldEnableInlineEditing: boolean;
}

export function TableRowRender({
  row,
  level = 0,
  table,
  rowSpacingClasses,
  insertPositionState,
  shouldEnableInlineEditing
}: TableRowRenderProps) {
  const { theme } = useThemingContext();

  const canRowExpand = row.getCanExpand();
  const isRowExpanded = row.getIsExpanded();
  const isNestedRow = level > 0;
  const hasTableGrouping = table.getCanSomeRowsExpand();

  // When grouping is enabled, we can't use the native odd/even, because it counts the grouped rows as rows
  // We calculate manually where to apply the zebra stripe
  const zebraStripeClass = useMemo(
    () => getZebraStripeClass({ row, hasTableGrouping, canRowExpand }),
    [canRowExpand, hasTableGrouping, row]
  );

  if (isNestedRow) {
    return null;
  }

  return (
    <React.Fragment key={row.id}>
      <Table.Row
        className={cn('group border-default', {
          'first-of-type:border-b': canRowExpand,
          'border-y bg-subtle': canRowExpand && isRowExpanded,
          'bg-muted': theme.tables.hasAlternateRowColor && zebraStripeClass === 'even-row',
          'bg-card':
            (theme.tables.hasAlternateRowColor && zebraStripeClass === 'odd-row') || canRowExpand,
          'bg-subtle': canRowExpand && isRowExpanded,
          'odd:bg-card even:bg-muted': theme.tables.hasAlternateRowColor && !hasTableGrouping
        })}
        {...(canRowExpand && { onClick: row.getToggleExpandedHandler() })}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCellRender
            key={cell.id}
            cell={cell}
            className={rowSpacingClasses}
            insertPositionState={insertPositionState}
            shouldEnableInlineEditing={shouldEnableInlineEditing}
          />
        ))}
      </Table.Row>

      {isRowExpanded &&
        row.subRows?.map((subRow) => (
          <TableRowRender
            key={subRow.id}
            row={subRow}
            table={table}
            level={level + 1}
            rowSpacingClasses={rowSpacingClasses}
            insertPositionState={insertPositionState}
            shouldEnableInlineEditing={shouldEnableInlineEditing}
          />
        ))}
    </React.Fragment>
  );
}
