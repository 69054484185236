import DOMPurify from 'dompurify';

const defaultConfig: DOMPurify.Config = {
  ADD_TAGS: ['iframe'], // Allow iframes
  // Allow target attribute for links - Open in new window link settings
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'] // Allow safe attributes
};

const ALLOWED_IFRAME_ATTRS = new Set([
  'src',
  'width',
  'height',
  'style',
  'allow',
  'allowfullscreen',
  'frameborder',
  'scrolling'
]);

export function sanitizeHTML(value: string, config?: Partial<DOMPurify.Config>): string {
  const sanitizedContent = DOMPurify.sanitize(value, { ...defaultConfig, ...config });

  // early return if we don't have iframe in sanitizedContent
  if (!sanitizedContent.includes('<iframe')) {
    return sanitizedContent;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedContent, 'text/html');
  doc.querySelectorAll('iframe').forEach((iframe) => {
    Array.from(iframe.attributes).forEach((attr) => {
      // filter attr for iframe
      if (!ALLOWED_IFRAME_ATTRS.has(attr.name)) {
        iframe.removeAttribute(attr.name);
      }
    });
  });
  return doc.body.innerHTML;
}
