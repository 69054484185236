import { useMemo } from 'react';

import { type ViewFilters } from '@/types/schema/LiveAppView';
import { isInternalBuilderIframe } from '@/utils/iframe';
import {
  useViewQueryParamsContext,
  type ViewWithQueryParams
} from '@/components/views/ViewQueryParamsContext';

export function useViewFilters(view: ViewWithQueryParams) {
  const { params, actions } = useViewQueryParamsContext();

  // The visible filters are the filters that will be visually displayed in the UI (e.g. in the filters popover or chips).
  // This is needed because we don't want to display in the UI any filters using the "near" operator when the view is a map, although they are still present in the URL.
  const visibleFilters = useMemo(() => {
    // If we are inside the page editor, we want to use the preset filters from the view schema instead of the filters from the URL params
    if (isInternalBuilderIframe()) {
      if (view.type === 'map') {
        return {
          match: 'and',
          rules: view.details.preset_filters
        };
      }

      if (view.type !== 'search') {
        return {
          match: 'and',
          rules: view.preset_filters
        };
      }
    }

    if (view.type === 'map' && params.filters) {
      const nonLocationRules = params.filters.rules.filter((rule) => rule.operator !== 'near');

      if (nonLocationRules.length === 0) {
        return undefined;
      }

      return {
        ...params.filters,
        rules: params.filters.rules.filter((rule) => rule.operator !== 'near')
      };
    }

    if (params.filters && params.filters.rules.length > 0) {
      return params.filters;
    }

    return undefined;
  }, [params.filters, view]);

  const applyFilters = (filters: ViewFilters | undefined) => {
    if (view.type === 'map') {
      const newNonMapRules = filters?.rules.filter((rule) => rule.operator !== 'near') || [];
      const existingMapRules =
        params.filters?.rules.filter((rule) => rule.operator === 'near') || [];

      const newFilters = {
        match: filters ? filters.match : 'and',
        rules: [...newNonMapRules, ...existingMapRules]
      };

      actions.setViewQueryParam({ filters: newFilters, page: undefined });
      return;
    }

    actions.setViewQueryParam({ filters, page: undefined });
  };

  const resetFilters = () => {
    if (view.type === 'map' && params.filters) {
      const mapFiltersRules =
        params.filters?.rules.filter((rule) => rule.operator === 'near') || [];

      if (mapFiltersRules?.length > 0) {
        actions.setViewQueryParam({ filters: { ...params.filters, rules: mapFiltersRules } });
        return;
      }
    }

    actions.setViewQueryParam({ filters: undefined });
  };

  const resetMapViewLocationFilter = () => {
    if (view.type !== 'map' || !params.filters) {
      return;
    }

    if (params.filters.rules.length === 1) {
      actions.setViewQueryParam({ filters: undefined, rowsPerPage: undefined, page: undefined });
      return;
    }

    const nonMapFiltersRules = params.filters.rules.filter((rule) => rule.operator !== 'near');

    actions.setViewQueryParam({
      filters: { ...params.filters, rules: nonMapFiltersRules },
      rowsPerPage: undefined,
      page: undefined
    });
  };

  const removeFilter = (index: number) => {
    if (!params.filters) {
      return;
    }

    const mapFiltersRules = params.filters.rules.filter((rule) => rule.operator === 'near');

    if (params.filters.rules.length === 1) {
      if (view.type === 'map' && mapFiltersRules.length > 0) {
        actions.setViewQueryParam({ filters: { ...params.filters, rules: mapFiltersRules } });
      } else {
        actions.setViewQueryParam({ filters: undefined });
      }

      return;
    }

    const updatedFiltersRules = params.filters.rules.filter((_, i) => i !== index);

    if (updatedFiltersRules.length === 0) {
      if (view.type === 'map' && mapFiltersRules.length > 0) {
        actions.setViewQueryParam({ filters: { ...params.filters, rules: mapFiltersRules } });
      } else {
        actions.setViewQueryParam({ filters: undefined });
      }

      return;
    }

    actions.setViewQueryParam({ filters: { ...params.filters, rules: updatedFiltersRules } });
  };

  return {
    visibleFilters,
    applyFilters,
    resetFilters,
    resetMapViewLocationFilter,
    removeFilter
  };
}
