import { useEffect, useState } from 'react';
import { HiFunnel as FilterIcon } from 'react-icons/hi2';
import { Tabs } from '@knack/asterisk-react';

import { type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { useViewFilters } from '@/hooks/useViewFilters';
import { isInternalBuilderIframe, isPageEditor } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import {
  useViewQueryParamsContext,
  type ViewWithQueryParams
} from '@/components/views/ViewQueryParamsContext';

export function ViewMenuFilters({ menuFilters }: { menuFilters: KnackMenuFilter[] }) {
  const { view } = useViewContext<ViewWithQueryParams>();
  const { params } = useViewQueryParamsContext();
  const { applyFilters } = useViewFilters(view);

  const [tabComponentKey, setTabComponentKey] = useState(0);
  const [activeMenuFilter, setActiveMenuFilter] = useState<KnackMenuFilter | undefined>(() => {
    // If we are inside the page editor, we want to use the first menu filter from the view schema
    if (isInternalBuilderIframe()) {
      if (view.type === 'map') {
        return view.details.menu_filters[0];
      }

      if (view.type !== 'search') {
        return view.menu_filters[0];
      }
    }

    if (!params.filters?.rules.length) {
      return undefined;
    }

    const matchingFilter = menuFilters.find((menuFilter) =>
      params.filters?.rules.some(
        (rule) =>
          rule.field === menuFilter.field &&
          rule.value === menuFilter.value &&
          rule.operator === menuFilter.operator
      )
    );

    return matchingFilter;
  });

  const handleSaveMenuFilters = (menuFilter: KnackMenuFilter) => {
    const selectedMenuFilter = menuFilters.find((filter) => filter.key === menuFilter.key);

    if (!selectedMenuFilter) return;

    setActiveMenuFilter(menuFilter);
    applyFilters({
      match: 'and',
      rules: [
        {
          field: selectedMenuFilter.field,
          operator: selectedMenuFilter.operator,
          value: selectedMenuFilter.value
        }
      ]
    });
  };

  const handleSelectMenuFilter = (selectedMenuFilter: KnackMenuFilter) => {
    // Disable the filter selection on the page editor
    if (isPageEditor()) return;

    const shouldUnselectFilter =
      activeMenuFilter && selectedMenuFilter.key === activeMenuFilter.key;

    if (shouldUnselectFilter) {
      setActiveMenuFilter(undefined);
      applyFilters(undefined);
      setTabComponentKey(tabComponentKey + 1);
      return;
    }

    handleSaveMenuFilters(selectedMenuFilter);
  };

  const menuFiltersItems = menuFilters.map((filter) => ({
    value: filter.key,
    children: <span>{filter.text}</span>,
    onClick: () => handleSelectMenuFilter(filter)
  }));

  // We need to re-render the tabs component when the menu filters are updated in the page editor
  useEffect(() => {
    if (isPageEditor()) {
      setTabComponentKey(tabComponentKey + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuFilters]);

  return (
    // It's necessary to pass a unique key to the Tabs component to force a re-render when the menu filters or params change.
    // This is a limitation of the Tabs.List component that doesn't update its state when the items prop changes.
    <Tabs key={`${JSON.stringify(params)}-${tabComponentKey}`} value={activeMenuFilter?.key}>
      <Tabs.List items={menuFiltersItems} intent="default">
        <FilterIcon size={16} className="mr-1" />
      </Tabs.List>
    </Tabs>
  );
}
