import type { Active, Over } from '@dnd-kit/core';
import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { DetailsInputEditor } from './DetailsInputEditor';

export function getViewInsertPosition(
  active: Active | null,
  over: Over | null,
  outerColumn: DetailsViewOuterColumn,
  input: DetailsViewInput
) {
  // If this input is not being dragged, or the target input is the active input, we don't need to determine the insert position
  if (over?.id !== input.id || active?.id === input.id) {
    return null;
  }

  const activeColumnId: string | null = active?.data.current?.sortable.containerId ?? null;
  const overIndex: number = over.data.current?.sortable.index ?? -1;
  const activeIndex: number = active?.data.current?.sortable.index ?? -1;

  // If the input is being dragged within the same column
  if (activeColumnId === outerColumn.id) {
    return overIndex > activeIndex ? 'after' : 'before';
  }

  // If the input is being dragged from one column to another and the target input is the first one in the column,
  // we need to determine if the active input should be placed above or below the target input
  if (active && overIndex === 0) {
    const isBelowInput =
      over &&
      active?.rect.current.translated &&
      active?.rect.current.translated.top > over.rect.top + over.rect.height / 2;

    return isBelowInput ? 'after' : 'before';
  }

  return 'after';
}

export function SortableDetailsInput({
  input,
  outerColumn,
  record,
  isLastInGroup
}: {
  input: DetailsViewInput;
  outerColumn: DetailsViewOuterColumn;
  record: FormattedViewRecord;
  isLastInGroup?: boolean;
}) {
  const dndContext = useDndContext();

  const { attributes, setNodeRef, transform, transition, isSorting } = useSortable({
    id: input.id,
    data: {
      type: 'input'
    }
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  const insertPositionState = getViewInsertPosition(
    dndContext.active,
    dndContext.over,
    outerColumn,
    input
  );

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <DetailsInputEditor
        input={input}
        record={record}
        draggingOverInsertPosition={insertPositionState}
        isLastInGroup={isLastInGroup}
      />
    </div>
  );
}
