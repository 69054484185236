import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@knack/asterisk-react';

import { ZIP_CODE_MILES_RANGE_OPTIONS } from '@/types/schema/fields';
import { type ViewFilters } from '@/types/schema/LiveAppView';

interface FilterAddressRangeInputProps {
  criteriaIndex: number;
}

export function FilterAddressRangeInput({ criteriaIndex }: FilterAddressRangeInputProps) {
  const [t] = useTranslation();

  const { control, register, getValues, setValue } = useFormContext<ViewFilters>();

  // Ensure that the range has a valid default value
  useEffect(() => {
    const rangeType = getValues(`rules.${criteriaIndex}.range`);

    const defaultValue = ZIP_CODE_MILES_RANGE_OPTIONS[0];
    const isValidValue = !!(
      rangeType &&
      typeof rangeType === 'string' &&
      (ZIP_CODE_MILES_RANGE_OPTIONS as Readonly<string[]>).includes(rangeType)
    );

    if (!isValidValue) {
      setValue(`rules.${criteriaIndex}.range`, defaultValue);
    }
  }, [criteriaIndex, getValues, setValue]);

  return (
    <div className="flex w-full gap-2">
      <Input
        className="h-9"
        placeholder={t('components.view_filters.address.zip_code_placeholder')}
        {...register(`rules.${criteriaIndex}.value`)}
      />
      <Controller
        control={control}
        name={`rules.${criteriaIndex}.range`}
        defaultValue={ZIP_CODE_MILES_RANGE_OPTIONS[0]}
        render={({ field: { value, onChange } }) => {
          const fallbackValue = ZIP_CODE_MILES_RANGE_OPTIONS[0];

          return (
            <Select
              value={value !== undefined ? String(value) : fallbackValue}
              onValueChange={onChange}
            >
              <Select.Trigger className="h-9 min-w-fit" />
              <Select.Content className="min-w-fit">
                {ZIP_CODE_MILES_RANGE_OPTIONS.map((milesRange) => (
                  <Select.Item key={milesRange} value={milesRange}>
                    {t('components.view_filters.address.miles_range', {
                      count: Number(milesRange)
                    })}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          );
        }}
      />
    </div>
  );
}
