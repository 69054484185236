import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@knack/asterisk-react';
import truncate from 'lodash/truncate';

import { MAX_NUMBER_CHARACTERS } from './ActionButton';

export function FallbackActionButton({ label }: { label?: string }) {
  const [t] = useTranslation();

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <Button
          aria-label={t('components.views.action_button')}
          intent="secondary"
          size="sm"
          disabled={!label}
          className="kn-action-button h-auto min-h-8"
        >
          {truncate(label, { length: MAX_NUMBER_CHARACTERS }) || t('keywords.action')}
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>{label || t('keywords.action')}</Tooltip.Content>
    </Tooltip>
  );
}
