import { Table } from '@knack/asterisk-react';
import { flexRender, type Cell } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isIframedByBuilder, isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { EditableCellWrapper } from '@/components/views/common/table/inline-editing/EditableCellWrapper';
import { type InsertPositionState } from '@/components/views/common/table/sortable/SortableTableWrapper';
import {
  type ViewWithTable,
  type ViewWithTableColumn
} from '@/components/views/common/table/types';
import { useTableDisplayRules } from '@/components/views/table/useTableDisplayRules';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';

interface TableCellRenderProps {
  cell: Cell<ViewRecord, unknown>;
  className?: string;
  insertPositionState?: InsertPositionState | null;
  shouldEnableInlineEditing?: boolean;
}

export function TableCellRender({
  cell,
  className,
  insertPositionState,
  shouldEnableInlineEditing
}: TableCellRenderProps) {
  const { theme } = useThemingContext();
  const { view } = useViewContext<ViewWithTable>();

  const recordRawValues = cell.getContext().table.options.meta?.rawValues[
    cell.row.index
  ] as ViewRecord;
  const viewColumn = cell.column.columnDef.meta?.column;

  const {
    cellStyle: displayRuleActionCellStyle,
    icon,
    shouldHideContent
  } = useTableDisplayRules(recordRawValues, viewColumn?.rules);

  // If the cell is expandable, render the expand button
  if (cell.row.getCanExpand()) {
    return flexRender(cell.column.columnDef.cell, cell.getContext());
  }

  if (!viewColumn) {
    return null;
  }

  const shouldCenterContent = viewColumn.type !== 'field';
  const shouldRenderEditableCell =
    shouldEnableInlineEditing &&
    viewColumn.type === 'field' &&
    !viewColumn.ignore_edit &&
    !isIframedByBuilder();

  function renderCellContent(column: ViewWithTableColumn) {
    const getNestedPadding = () => {
      // If the cell is not nested, don't apply padding
      if (cell.row.depth === 0) {
        return undefined;
      }

      const paddingAmountInRem = cell.row.depth * 1.5;
      const isFirstCell = cell.column.getIndex() === 1;

      return isFirstCell
        ? {
            paddingLeft: `${paddingAmountInRem}rem`
          }
        : undefined;
    };

    const getPageEditorDndStyles = () => {
      if (!isPageEditor()) {
        return undefined;
      }

      if (insertPositionState?.overId === column.id && insertPositionState?.position === 'after') {
        return '!border-r-4  border-r-blue-500';
      }

      if (insertPositionState?.overId === column.id && insertPositionState?.position === 'before') {
        return '!border-l-4 border-l-blue-500';
      }

      if (insertPositionState?.activeId === column.id) {
        return '!border-x  border-dashed border-x-blue-500 *:opacity-20';
      }

      return undefined;
    };

    return (
      <Table.Cell
        data-testid={`table-cell-${column.id}`}
        style={{
          ...displayRuleActionCellStyle,
          ...getNestedPadding()
        }}
        className={cn('border-x-0', className, getPageEditorDndStyles(), {
          'border-r': theme.tables.border === 'rows-and-columns',
          'cursor-pointer border-x-0': shouldEnableInlineEditing
        })}
      >
        <div
          className={cn('relative flex min-h-5 items-center', {
            hidden: shouldHideContent,
            'justify-center': shouldCenterContent || column.align === 'center',
            'justify-start': column.align === 'left' && !shouldCenterContent,
            'justify-end': column.align === 'right' && !shouldCenterContent,
            'flex-col items-start': column.conn_separator === 'new_line',
            'text-muted': isPageEditor() && view.source.authenticated_user
          })}
        >
          <LabelWithIcon
            icon={icon?.icon}
            iconPosition={icon?.align || 'left'}
            iconStyle={{ color: icon.color }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </LabelWithIcon>
        </div>
      </Table.Cell>
    );
  }

  if (shouldRenderEditableCell) {
    return (
      <EditableCellWrapper cell={cell} viewColumn={viewColumn}>
        {renderCellContent(viewColumn)}
      </EditableCellWrapper>
    );
  }

  return renderCellContent(viewColumn);
}
