import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { type BooleanField } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';
import { booleanOptions } from '@/components/views/form/inputs/boolean/helper';

type BooleanInputProps = {
  field: BooleanField;
  intent: 'default' | 'destructive';
  formValueKey: string;
};

export function BooleanInput({ field, intent, formValueKey }: BooleanInputProps) {
  const { getValues } = useFormContext();

  const inputFormat = field.format;
  const inputValue = getValues(formValueKey);
  const booleanInputType = inputFormat?.input;

  if (booleanInputType === 'radios') {
    return (
      <Controller
        name={formValueKey}
        render={({ field: fieldForm }) => (
          <RadioGroup
            defaultValue={fieldForm.value}
            onValueChange={(val: string) => fieldForm.onChange(val)}
            className="flex gap-2"
          >
            {booleanOptions[inputFormat.format].map((option, index) => {
              const radioInputId = `boolean-${index}`;
              return (
                <RadioGroup.Container key={radioInputId}>
                  <RadioGroup.Item value={option} id={radioInputId} />
                  <Label htmlFor={radioInputId}>{option}</Label>
                </RadioGroup.Container>
              );
            })}
          </RadioGroup>
        )}
      />
    );
  }

  if (booleanInputType === 'checkbox') {
    const checkboxesInputId = `boolean-checkbox`;
    return (
      <div className="flex items-center gap-2">
        <Controller
          name={formValueKey}
          render={({ field: fieldForm }) => {
            const fieldValue = fieldForm.value ?? inputValue;
            return (
              <Checkbox
                intent={intent}
                checked={fieldValue}
                id={checkboxesInputId}
                onClick={() => fieldForm.onChange(!fieldValue)}
              />
            );
          }}
        />
        {inputFormat.text && (
          <Label
            htmlFor={checkboxesInputId}
            intent={intent === 'destructive' ? 'destructive' : undefined}
          >
            {inputFormat.text}
          </Label>
        )}
      </div>
    );
  }

  if (booleanInputType === 'dropdown') {
    return (
      <Controller
        name={formValueKey}
        render={({ field: fieldForm }) => (
          <Select
            defaultValue={fieldForm.value}
            value={fieldForm.value}
            onValueChange={(val: string) => fieldForm.onChange(val)}
          >
            <Select.Trigger
              className={cn('py-2', {
                'border-destructive': intent === 'destructive'
              })}
            />
            <Select.Content className="min-w-20">
              {booleanOptions[inputFormat.format].map((option, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Select.Item key={index} value={option}>
                  {option}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  return null;
}
