import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';
import { defaultAppTheme, type AppTheme, type Theming } from '@knack/asterisk-react';

type ThemingContextState = {
  theme: AppTheme;
  setTheme: (theme: AppTheme) => void;
} | null;

const ThemingContext = createContext<ThemingContextState>(null);

export function ThemingContextProvider({
  theming,
  children
}: {
  theming: Theming;
  children: ReactNode;
}) {
  const [theme, setTheme] = useState<AppTheme>(defaultAppTheme);

  useEffect(() => {
    const foundTheme = theming.appThemes.find(
      (themeItem) => themeItem.id === theming.activeThemeId
    );

    if (foundTheme) {
      setTheme(foundTheme);
    }
  }, [theming]);

  const contextValue = useMemo(
    () => ({
      theme,
      setTheme
    }),
    [theme, setTheme]
  );

  return <ThemingContext.Provider value={contextValue}>{children}</ThemingContext.Provider>;
}

export const useThemingContext = () => {
  const context = useContext(ThemingContext);
  if (!context) {
    throw new Error('useThemingContext must be used within a ThemingContextProvider');
  }
  return context;
};
