import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as ResetIcon, HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';
import { Button, Input } from '@knack/asterisk-react';

import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

export function ViewSearchInput() {
  const [t] = useTranslation();

  const { params, actions } = useViewQueryParamsContext();

  const [inputValue, setInputValue] = useState(params.search || '');

  const handleResetSearchInput = () => {
    actions.setViewQueryParam({ search: undefined });
    setInputValue('');
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        actions.setViewQueryParam({ search: inputValue, page: undefined });
      }}
    >
      <Input.Container>
        <Input
          data-testid="view-search-input"
          aria-label={t('components.views.table.table_search_input')}
          value={inputValue}
          placeholder={t('actions.search')}
          className="w-full"
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Input.Icon>
          {inputValue || params.search ? (
            <Button
              data-testid="clear-view-search-input"
              aria-label={t('components.views.table.table_search_reset_button')}
              intent="minimal"
              size="xs"
              onClick={handleResetSearchInput}
            >
              <ResetIcon size={14} className="shrink-0" />
            </Button>
          ) : (
            <Button
              intent="minimal"
              size="xs"
              aria-label={t('components.views.table.table_search_button')}
            >
              <SearchIcon size={14} className="shrink-0" />
            </Button>
          )}
        </Input.Icon>
      </Input.Container>
    </form>
  );
}
