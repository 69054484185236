import { DateTime } from 'luxon';

import { type ViewRecordsResponse } from '@/hooks/api/queries/useViewMultipleRecordsQuery';

// Create the start and end times for the first event: today at 11:00 AM to 12:00 PM.
const eventOneStart = DateTime.now().set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
const eventOneEnd = eventOneStart.plus({ hours: 1 });

// Create the start and end times for the second event: tomorrow at 3:00 PM to 4:00 PM.
const eventTwoStart = DateTime.now()
  .plus({ days: 1 })
  .set({ hour: 15, minute: 0, second: 0, millisecond: 0 });
const eventTwoEnd = eventTwoStart.plus({ hours: 1 });

export const calendarDemoData: ViewRecordsResponse = {
  total_pages: 1,
  current_page: 1,
  total_records: 2,
  records: [
    {
      id: 'event1',
      field_16: 'Meeting',
      field_16_raw: 'Meeting',
      field_17: `${eventOneStart.toFormat('dd/MM/yyyy HH:mm')} to ${eventOneEnd.toFormat('dd/MM/yyyy HH:mm')}`,
      field_17_raw: {
        date: eventOneStart.toFormat('MM/dd/yyyy'),
        date_formatted: eventOneStart.toFormat('dd/MM/yyyy'),
        hours: eventOneStart.toFormat('hh'),
        minutes: eventOneStart.toFormat('mm'),
        am_pm: eventOneStart.toFormat('a'),
        unix_timestamp: eventOneStart.toMillis(),
        iso_timestamp: eventOneStart.toISO({ suppressMilliseconds: true }),
        timestamp: eventOneStart.toFormat('MM/dd/yyyy hh:mm a'),
        time: eventOneStart.hour * 60 + eventOneStart.minute,
        proper_unix_timestamp: eventOneStart.plus({ hours: 5 }).toMillis(),
        proper_iso_timestamp: eventOneStart
          .plus({ hours: 5 })
          .toISO({ suppressMilliseconds: true }),
        to: {
          date: eventOneEnd.toFormat('MM/dd/yyyy'),
          date_formatted: eventOneEnd.toFormat('dd/MM/yyyy'),
          hours: eventOneEnd.toFormat('hh'),
          minutes: eventOneEnd.toFormat('mm'),
          am_pm: eventOneEnd.toFormat('a'),
          unix_timestamp: eventOneEnd.toMillis(),
          iso_timestamp: eventOneEnd.toISO({ suppressMilliseconds: true }),
          timestamp: eventOneEnd.toFormat('MM/dd/yyyy hh:mm a'),
          time: eventOneEnd.hour * 60 + eventOneEnd.minute,
          proper_unix_timestamp: eventOneEnd.plus({ hours: 5 }).toMillis(),
          proper_iso_timestamp: eventOneEnd.plus({ hours: 5 }).toISO({ suppressMilliseconds: true })
        }
      }
    },
    {
      id: 'event2',
      field_16: 'Workshop',
      field_16_raw: 'Workshop',
      field_17: `${eventTwoStart.toFormat('dd/MM/yyyy HH:mm')} to ${eventTwoEnd.toFormat('dd/MM/yyyy HH:mm')}`,
      field_17_raw: {
        date: eventTwoStart.toFormat('MM/dd/yyyy'),
        date_formatted: eventTwoStart.toFormat('dd/MM/yyyy'),
        hours: eventTwoStart.toFormat('hh'),
        minutes: eventTwoStart.toFormat('mm'),
        am_pm: eventTwoStart.toFormat('a'),
        unix_timestamp: eventTwoStart.toMillis(),
        iso_timestamp: eventTwoStart.toISO({ suppressMilliseconds: true }),
        timestamp: eventTwoStart.toFormat('MM/dd/yyyy hh:mm a'),
        time: eventTwoStart.hour * 60 + eventTwoStart.minute,
        proper_unix_timestamp: eventTwoStart.plus({ hours: 5 }).toMillis(),
        proper_iso_timestamp: eventTwoStart
          .plus({ hours: 5 })
          .toISO({ suppressMilliseconds: true }),
        to: {
          date: eventTwoEnd.toFormat('MM/dd/yyyy'),
          date_formatted: eventTwoEnd.toFormat('dd/MM/yyyy'),
          hours: eventTwoEnd.toFormat('hh'),
          minutes: eventTwoEnd.toFormat('mm'),
          am_pm: eventTwoEnd.toFormat('a'),
          unix_timestamp: eventTwoEnd.toMillis(),
          iso_timestamp: eventTwoEnd.toISO({ suppressMilliseconds: true }),
          timestamp: eventTwoEnd.toFormat('MM/dd/yyyy hh:mm a'),
          time: eventTwoEnd.hour * 60 + eventTwoEnd.minute,
          proper_unix_timestamp: eventTwoEnd.plus({ hours: 5 }).toMillis(),
          proper_iso_timestamp: eventTwoEnd.plus({ hours: 5 }).toISO({ suppressMilliseconds: true })
        }
      }
    }
  ]
};
