import { useSearchParams } from 'react-router-dom';

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageContext } from '@/context/PageContext';
import { isConnectionFieldWithKey } from './helper';

export function useConnectionInputPrepopulation(input: FormViewConnectionInput) {
  const [searchParams] = useSearchParams();
  const { activePage, activePageRecordId, recordIdFromParams } = usePageContext();

  const { view, sourceTable } = useViewContext<FormView>();

  const connectionInputField = sourceTable.fields.find((f) =>
    isConnectionFieldWithKey(f, input.field.key)
  );

  const pageSourceObjectKey = activePage?.sourceObjectKey || searchParams.get('objectKey');
  const pageRecordId = activePageRecordId || recordIdFromParams;

  // Check if we have multiple connections to the page source object key
  const isMultipleConnection =
    sourceTable.fields.filter(
      (f) => f.type === 'connection' && f.relationship?.object === pageSourceObjectKey
    ).length !== 1;

  // Check if the connection input field is connected to the page source object
  const isActiveConnection =
    !!connectionInputField?.relationship.object &&
    connectionInputField.relationship.object === pageSourceObjectKey;

  // If there is only one connection to prepopulate, we disable the input. Otherwise, we allow the user to select the record
  const isSinglePrepopulatedInput = !isMultipleConnection && isActiveConnection;

  const shouldPrepopulateValue =
    view.action === 'create' &&
    !!pageRecordId &&
    !!pageSourceObjectKey &&
    !!connectionInputField?.relationship.object &&
    connectionInputField.relationship.object === pageSourceObjectKey;

  return {
    shouldPrepopulateValue,
    isSinglePrepopulatedInput,
    pageRecordId
  };
}
