import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode
} from 'react';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type ViewFilters } from '@/types/schema/LiveAppView';
import { type MapView } from '@/types/schema/views/MapView';
import { useReverseGeocodeQuery } from '@/hooks/api/queries/useReverseGeocodeQuery';
import { useGeolocationCurrentPosition } from '@/hooks/useGeolocationCurrentPosition';
import { useViewFilters } from '@/hooks/useViewFilters';
import { isIframedByBuilder } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

function createNewMapFilters({
  location,
  range,
  filters,
  addressFieldKey
}: {
  location: string;
  range: string;
  filters: ViewFilters | undefined;
  addressFieldKey: KnackFieldKey;
}) {
  const filtersWithoutNearOperator =
    filters?.rules.filter((rule) => rule.operator !== 'near') || [];

  const mapFilterRule: KnackCriteria = {
    field: addressFieldKey,
    operator: 'near',
    value: location,
    range
  };

  return {
    match: filters?.match || 'and',
    rules: [...filtersWithoutNearOperator, mapFilterRule]
  };
}

const DEFAULT_LOCATION = '';
const DEFAULT_RANGE = '10';

type MapLocationContextType = {
  location: string;
  range: string;
  wasReset: boolean;
  isLoading: boolean;
  onLocationChange: (location: string, range?: string) => void;
  resetLocation: () => void;
  createNewMapFilters: typeof createNewMapFilters;
};

export const MapLocationContext = createContext<MapLocationContextType | null>(null);

export function MapLocationProvider({ children }: { children: ReactNode }) {
  const { view } = useViewContext<MapView>();
  const { params, actions } = useViewQueryParamsContext();
  const { resetMapViewLocationFilter } = useViewFilters(view);

  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [range, setRange] = useState(DEFAULT_RANGE);
  const [wasReset, setWasReset] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const userStartingAddress =
    !wasReset && view.starting_point === 'address' ? view.starting_address : DEFAULT_LOCATION;
  const isUserCurrentLocationEnabled = view.starting_point === 'location' && !wasReset;

  const { coordinates, isLoading: isLoadingUserCurrentLocation } = useGeolocationCurrentPosition({
    enabled: !isIframedByBuilder() && isUserCurrentLocationEnabled
  });

  const { data: userCurrentLocation, isLoading: isLoadingGeocoding } = useReverseGeocodeQuery({
    query: isUserCurrentLocationEnabled && coordinates ? coordinates : null,
    enabled: !isIframedByBuilder()
  });

  useEffect(() => {
    if (wasReset || !isInitializing) return;

    const locationFromFilters = params.filters?.rules.find((rule) => rule.operator === 'near');
    const locationRule = locationFromFilters?.value || DEFAULT_LOCATION;
    const rangeRule = locationFromFilters?.range || String(view.default_range) || DEFAULT_RANGE;

    if (locationRule || userStartingAddress) {
      const initialLocation = typeof locationRule === 'string' ? locationRule : userStartingAddress;
      setLocation(initialLocation);
      setRange(String(rangeRule));
      setIsInitializing(false);
      return;
    }

    if (isUserCurrentLocationEnabled && userCurrentLocation?.address) {
      setLocation(userCurrentLocation.address);
      setRange(String(rangeRule));
      setIsInitializing(false);
    }
  }, [
    isInitializing,
    wasReset,
    userStartingAddress,
    userCurrentLocation,
    isUserCurrentLocationEnabled,
    view.default_range,
    params.filters?.rules
  ]);

  useEffect(() => {
    if (wasReset) {
      actions.setViewQueryParam({
        rowsPerPage: undefined,
        page: undefined,
        filters: params.filters
      });
      setLocation(DEFAULT_LOCATION);
      return;
    }

    const addressFieldKey = view.address_field.key;
    const filters = location
      ? createNewMapFilters({
          location,
          range,
          filters: params.filters,
          addressFieldKey
        })
      : params.filters;

    actions.setViewQueryParam({ filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, range, wasReset, view.address_field.key]);

  useEffect(() => {
    setRange(String(view.default_range));
  }, [view.default_range]);

  useEffect(() => {
    if (view.starting_point !== 'address' || !view.starting_address) return;
    setLocation(view.starting_address);
  }, [view.starting_point, view.starting_address]);

  const onLocationChange = useCallback(
    (l: string, r: string = range) => {
      if (!l) return;
      setLocation(l);
      setRange(r);
      setWasReset(false);

      const addressFieldKey = view.address_field.key;
      const filters = createNewMapFilters({
        location: l,
        range: r,
        filters: params.filters,
        addressFieldKey
      });

      actions.setViewQueryParam({ page: 1, filters });
    },
    [range, view.address_field.key, params.filters, actions]
  );

  const resetLocation = useCallback(() => {
    setLocation(DEFAULT_LOCATION);
    setRange(DEFAULT_RANGE);
    setWasReset(true);
    resetMapViewLocationFilter();
  }, [resetMapViewLocationFilter]);

  const value = useMemo(
    () => ({
      location: wasReset ? '' : location,
      range,
      wasReset,
      isLoading: isLoadingUserCurrentLocation || isLoadingGeocoding,
      onLocationChange,
      resetLocation,
      createNewMapFilters
    }),
    [
      location,
      range,
      wasReset,
      isLoadingUserCurrentLocation,
      isLoadingGeocoding,
      onLocationChange,
      resetLocation
    ]
  );

  return <MapLocationContext.Provider value={value}>{children}</MapLocationContext.Provider>;
}

export function useMapLocationContext() {
  const context = useContext(MapLocationContext);
  if (!context) {
    throw new Error('useMapLocation must be used within a MapLocationProvider');
  }
  return context;
}
