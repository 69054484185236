import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { useViewQueryParamsContext } from '@/components/views/ViewQueryParamsContext';

export function RowsPerPageSelect() {
  const [t] = useTranslation();

  const { params, actions } = useViewQueryParamsContext();

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm text-subtle">{t('actions.show')}</span>
      <Select
        value={params.rowsPerPage || undefined}
        onValueChange={(value) =>
          actions.setViewQueryParam({
            rowsPerPage: value,
            page: 1
          })
        }
      >
        <Select.Trigger
          className="h-auto p-1 text-subtle"
          data-testid="rows-per-page-select-trigger"
        />
        <Select.Content className="min-w-36" align="end">
          <Select.Item value="10">
            {t('components.views.table.per_page', { rowsPerPage: 10 })}
          </Select.Item>
          <Select.Item value="25">
            {t('components.views.table.per_page', { rowsPerPage: 25 })}
          </Select.Item>
          <Select.Item value="50">
            {t('components.views.table.per_page', { rowsPerPage: 50 })}
          </Select.Item>
          <Select.Item value="100">
            {t('components.views.table.per_page', { rowsPerPage: 100 })}
          </Select.Item>
          <Select.Item value="500">
            {t('components.views.table.per_page', { rowsPerPage: 500 })}
          </Select.Item>
          <Select.Item value="1000">
            {t('components.views.table.per_page', { rowsPerPage: 1000 })}
          </Select.Item>
        </Select.Content>
      </Select>
    </div>
  );
}
