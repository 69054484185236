import { useFormContext } from 'react-hook-form';

import {
  type SearchView,
  type SearchViewCriteria,
  type SearchViewFieldInput,
  type SearchViewFilters
} from '@/types/schema/views/SearchView';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';
import { KeywordSearchInput } from './inputs/KeywordSearchInput';
import { SearchFieldInput } from './inputs/SearchFieldInput';
import { SearchInstructions } from './inputs/SearchInstructions';
import { SearchFieldOperators } from './SearchFieldOperators';

type SearchFieldOptionsProps = {
  fieldKey: string;
  searchViewCriteriaIndex: number;
  searchViewFieldInputsByFieldKey: Record<string, SearchViewFieldInput>;
  onCriteriaOperatorChange: (updatedKnackCriteria: SearchViewCriteria) => void;
};

export function SearchFieldOptions({
  fieldKey,
  searchViewCriteriaIndex,
  searchViewFieldInputsByFieldKey,
  onCriteriaOperatorChange
}: SearchFieldOptionsProps) {
  const {
    getValues,
    formState: { errors }
  } = useFormContext<SearchViewFilters>();

  const { sourceTable } = useViewContext<SearchView>();
  const { getBaseFieldOperators } = useFieldHelpers();

  const searchViewFieldInput = searchViewFieldInputsByFieldKey[fieldKey];

  if (fieldKey === 'keyword_search') {
    return (
      <div className="flex max-w-[650px] flex-col gap-2 sm:col-span-2 lg:col-span-3">
        <KeywordSearchInput
          searchViewCriteriaIndex={searchViewCriteriaIndex}
          errors={errors}
          searchViewFieldInput={searchViewFieldInput}
        />
      </div>
    );
  }

  const criteriaField = sourceTable.fields.find((field) => field.key === fieldKey);
  if (!criteriaField) {
    return null;
  }

  const operators = getBaseFieldOperators({
    field: criteriaField
  });
  if (!operators.includes('is any')) {
    operators.push('is any');
  }

  const fieldName = searchViewFieldInput.name || criteriaField.name;
  const name = searchViewFieldInput.required ? `${fieldName} *` : fieldName;
  const isError = errors.rules && errors.rules[searchViewCriteriaIndex];
  const isIgnoringOperators = searchViewFieldInput.ignore_operators;
  const criteriaOperator = getValues(`rules.${searchViewCriteriaIndex}.operator`);

  return (
    <div className="flex flex-col gap-2">
      <div className={cn('font-bold', { 'text-destructive': isError })}>{name}</div>
      <div className="flex flex-col gap-2 sm:flex-row">
        {!isIgnoringOperators && (
          <div>
            <SearchFieldOperators
              onCriteriaOperatorChange={onCriteriaOperatorChange}
              operators={operators}
              searchViewFieldInputsByFieldKey={searchViewFieldInputsByFieldKey}
              searchViewCriteriaIndex={searchViewCriteriaIndex}
            />
          </div>
        )}

        <div className="flex grow flex-col justify-center">
          <SearchFieldInput
            field={criteriaField}
            formValueKey={`rules.${searchViewCriteriaIndex}.value`}
            disabled={shouldHideValueBasedOnOperator(criteriaOperator)}
            intent={isError ? 'destructive' : 'default'}
            criteriaOperator={criteriaOperator}
            searchViewCriteriaIndex={searchViewCriteriaIndex}
          />
          {errors.rules && (
            <FormErrorMessage
              errors={errors}
              name={`rules.${searchViewCriteriaIndex}.value`}
              className="mt-1"
            />
          )}
          {searchViewFieldInputsByFieldKey[criteriaField.key].instructions && (
            <SearchInstructions>
              {searchViewFieldInputsByFieldKey[criteriaField.key].instructions}
            </SearchInstructions>
          )}
        </div>
      </div>
    </div>
  );
}
