import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

export type FormattedData = {
  crumbtrail: Record<string, string>;
  parent_url?: string;
  url?: string;
  [key: string]: unknown;
};

type FormData = {
  [key: string]: unknown;
};

interface AddOptionParams {
  data: FormData;
  viewKey: LiveAppViewKey;
  pageKey: LiveAppPage['key'];
  pageSlug: string;
  recordId: string | null;
  pageUrl?: string;
  parentPageUrl?: string;
}

type RecordMutationResponse = {
  record: ViewRecord;
  submit_key: string | boolean;
};

export async function addRecord({
  viewKey,
  pageKey,
  pageSlug,
  parentPageUrl,
  pageUrl,
  data,
  recordId
}: AddOptionParams) {
  const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/`;

  const formattedData: FormattedData = {
    // This property will be filled when adding a record from a connected child page e.g Details Page with Add Record Form
    crumbtrail: recordId ? { [`${pageSlug}_id`]: recordId } : {},

    // These two properties are used when processing email rules, since the rules might use template variables that refer to the page and parent page.
    parent_url: parentPageUrl,
    url: pageUrl,

    ...data
  };

  const { data: responseData } = await axios.post<RecordMutationResponse>(url, formattedData, {
    withCredentials: true
  });

  return responseData;
}

export function useAddRecordMutation() {
  const queryClient = useQueryClient();

  const { activePage, activePageRecordId, recordIdFromParams } = usePageContext();
  const { getPreviousPageFullUrl } = usePageSegmentsHelpers();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ viewKey, data }: { viewKey: LiveAppViewKey; data: FormData }) =>
      addRecord({
        pageKey: activePage.key,
        pageSlug: activePage.slug,
        viewKey,
        pageUrl: window.location.href,
        parentPageUrl: getPreviousPageFullUrl(),
        data,
        recordId: activePageRecordId || recordIdFromParams
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData]
      });
    }
  });
}
