import { useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiArrowDownOnSquare as ExportIcon,
  HiPlus as PlusIcon,
  HiRss as SubscribeIcon
} from 'react-icons/hi2';
import { type EventModel } from '@bryntum/calendar';
import { Button, InputSearch } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { isIframedByBuilder } from '@/utils/iframe';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageContext } from '@/context/PageContext';
import { handleFile } from './helper';
import { type CalendarUtilsProps } from './type';

const defaultButtonState: Required<NonNullable<CalendarUtilsProps['buttons']>> = {
  shouldEnableEvent: true,
  shouldEnableExport: true,
  shouldEnableSubscribe: true
};

export function CalendarUtils({
  onAddEventClick,
  shouldEnableSearch = true,
  buttons,
  calendarInstance
}: CalendarUtilsProps) {
  const { activePage } = usePageContext();
  const { data: application } = useApplicationQuery();
  const { view } = useViewContext<CalendarView>();
  const [t] = useTranslation('translation', { keyPrefix: 'components.views.calendar' });

  const [searchText, setSearchText] = useState('');

  const { shouldEnableEvent, shouldEnableExport, shouldEnableSubscribe } = {
    ...defaultButtonState,
    ...buttons
  };

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    const eventStore = calendarInstance?.eventStore;
    if (!eventStore) {
      return;
    }

    const searchValue = e.target.value;
    setSearchText(searchValue);

    // clear filter after every new input
    await eventStore.clearFilters();

    await eventStore.filter({
      filterBy: (data: EventModel) => data.name.toLowerCase().includes(searchValue.toLowerCase())
    });
  };

  const handleAddEventClick = async () => {
    if (!calendarInstance || isIframedByBuilder()) {
      return;
    }

    await calendarInstance?.eventStore.clearFilters();
    setSearchText('');

    // Open event form modal
    onAddEventClick();
  };

  const handleExport = async () => {
    if (activePage && application && !isIframedByBuilder()) {
      const downloadLink = `/v1/scenes/${activePage.key}/views/${view.key}/records/applications/${application.id}/ical`;
      await handleFile({ url: downloadLink, filename: 'calendar.ics', download: true });
    }
  };

  const handleSubscribe = async () => {
    if (activePage && application && !isIframedByBuilder()) {
      const downloadLink = `/v1/scenes/${activePage.key}/views/${view.key}/records/applications/${application.id}/rss`;
      await handleFile({ url: downloadLink });
    }
  };

  return (
    <div className="flex justify-between bg-card px-4 pb-4 pt-6">
      <div className="flex gap-2">
        {shouldEnableEvent && (
          <Button intent="primary" size="default" onClick={handleAddEventClick}>
            <Button.Icon icon={PlusIcon} />
            {t('add_event')}
          </Button>
        )}
        {shouldEnableExport && (
          <Button intent="secondary" size="default" onClick={handleExport}>
            <Button.Icon icon={ExportIcon} />
            {t('export')}
          </Button>
        )}
        {shouldEnableSubscribe && (
          <Button intent="secondary" size="default" onClick={handleSubscribe}>
            <Button.Icon icon={SubscribeIcon} />
            {t('subscribe')}
          </Button>
        )}
        <ViewToolbarSection className="mb-4" />
      </div>
      <div>
        {shouldEnableSearch && (
          <InputSearch placeholder={t('search')} onChange={handleSearch} value={searchText} />
        )}
      </div>
    </div>
  );
}
