import { usePageQuery } from '@/hooks/api/queries/usePageQuery';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { NotAuthorizedPage } from '@/pages/not-authorized/NotAuthorizedPage';
import { NotFoundPage } from '@/pages/not-found/NotFoundPage';
import { usePageContext } from '@/context/PageContext';
import { AuthFlowContextProvider } from './AuthFlowContext';
import { PageEditor } from './page-editor/PageEditor';
import { PageHelmet } from './PageHelmet';
import { PageRender } from './PageRender';

export function Page() {
  const { activePage, shouldRestrictAccess } = usePageContext();
  const { data: fullPage, isLoading } = usePageQuery({
    pageSlug: activePage?.slug,
    enabled: !shouldRestrictAccess
  });

  if (shouldRestrictAccess) {
    return <NotAuthorizedPage />;
  }

  if (!activePage) {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (!fullPage) {
    throw new Error(
      `Something went wrong loading the page with slug: ${activePage.slug} and key: ${activePage.key}`
    );
  }

  if (isInternalBuilderIframe()) {
    return (
      <AuthFlowContextProvider key={fullPage.key}>
        <PageEditor />
      </AuthFlowContextProvider>
    );
  }

  return (
    <AuthFlowContextProvider key={fullPage.key}>
      <PageRender page={fullPage} />
      <PageHelmet pageName={fullPage.name} />
    </AuthFlowContextProvider>
  );
}
