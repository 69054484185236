import { type FormViewBooleanInput } from '@/types/schema/views/form/Boolean';
import i18n from '@/i18n';

export const booleanOptions = {
  yes_no: [
    i18n.t('components.views.form.boolean_input.yes'),
    i18n.t('components.views.form.boolean_input.no')
  ],
  true_false: [
    i18n.t('components.views.form.boolean_input.true'),
    i18n.t('components.views.form.boolean_input.false')
  ],
  on_off: [
    i18n.t('components.views.form.boolean_input.on'),
    i18n.t('components.views.form.boolean_input.off')
  ]
};

// Default value always comes as boolean from the API, so we need to convert it to string to match the field settings e.g (Yes, No, On, Off...)
export const getFormattedBooleanDefaultValue = (
  value: boolean,
  inputFormat: FormViewBooleanInput['format']
) => (value ? booleanOptions[inputFormat.format][0] : booleanOptions[inputFormat.format][1]);

export const getBooleanValue = (
  value: string | boolean,
  inputFormat: FormViewBooleanInput['format']
) => {
  const formattedValue =
    typeof value === 'boolean'
      ? getFormattedBooleanDefaultValue(value, inputFormat).toLowerCase()
      : value.toLowerCase();
  const firstOption = booleanOptions[inputFormat.format]?.[0].toLowerCase();
  const secondOption = booleanOptions[inputFormat.format]?.[1].toLowerCase();

  if (formattedValue === firstOption) {
    return true;
  }

  if (formattedValue === secondOption) {
    return false;
  }

  return null;
};
