import RulesHelper from '@knack/rules-helper';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField } from '@/types/schema/KnackField';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { getBooleanValue } from '@/components/views/form/inputs/boolean/helper';

type OnCriteriaMet = ({
  recordRawValues,
  field,
  criteria
}: {
  recordRawValues: ViewRecord;
  field: KnackField;
  criteria: KnackCriteria;
}) => void;

export function isCriteriaMet(
  rawFieldValue: unknown,
  field: KnackField,
  criteria: KnackCriteria
): boolean {
  function getNormalizeFieldValueForRuleCheck() {
    if (field.type === 'connection' && Array.isArray(rawFieldValue) && rawFieldValue.length > 0) {
      return rawFieldValue.map((value) => value.id).join(',');
    }

    if (typeof rawFieldValue === 'string') {
      if (field.type === 'boolean') {
        return getBooleanValue(rawFieldValue, field.format);
      }

      return rawFieldValue.toLowerCase();
    }

    return rawFieldValue;
  }

  return RulesHelper.checkRule(criteria, getNormalizeFieldValueForRuleCheck(), field) as boolean;
}

function criteriaPredicate(
  recordRawValues: ViewRecord,
  fields: KnackField[],
  criteriaRule: KnackCriteria,
  onCriteriaMet?: OnCriteriaMet
): boolean {
  const { field: criteriaFieldKey } = criteriaRule;

  const field = fields.find((f) => f.key === criteriaFieldKey);
  if (!field) {
    return false;
  }

  const rawFieldValue: unknown = recordRawValues[criteriaFieldKey];
  if (rawFieldValue === undefined) {
    return false;
  }

  const isMet = isCriteriaMet(rawFieldValue, field, criteriaRule);

  if (isMet && onCriteriaMet) {
    onCriteriaMet({
      recordRawValues,
      field,
      criteria: criteriaRule
    });
  }

  return isMet;
}

export function isEveryCriteriaMet(
  recordRawValues: ViewRecord,
  fields: KnackField[],
  criteria?: KnackCriteria[],
  onCriteriaMet?: OnCriteriaMet
) {
  if (!criteria || criteria.length === 0) return false;
  return criteria.every((criteriaRule) =>
    criteriaPredicate(recordRawValues, fields, criteriaRule, onCriteriaMet)
  );
}

export function isSomeCriteriaMet(
  recordRawValues: ViewRecord,
  fields: KnackField[],
  criteria?: KnackCriteria[],
  onCriteriaMet?: OnCriteriaMet
) {
  if (!criteria || criteria.length === 0) return false;
  return criteria.some((criteriaRule) =>
    criteriaPredicate(recordRawValues, fields, criteriaRule, onCriteriaMet)
  );
}
