import { useMemo } from 'react';
import { Rating } from '@knack/asterisk-react';
import { type Cell, type Table as ReactTable } from '@tanstack/react-table';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isIframedByBuilder } from '@/utils/iframe';
import { sanitizeHTML } from '@/utils/sanitizeHTML';
import {
  type ViewWithTable,
  type ViewWithTableColumn
} from '@/components/views/common/table/types';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewRecordConnectionLink } from '@/components/views/ViewRecordConnectionLink';
import { ViewRecordFileField } from '@/components/views/ViewRecordFileField';
import { DefaultRecordCellContent } from './DefaultRecordCellContent';

interface RecordCellContentProps {
  table: ReactTable<ViewRecord>;
  cell: Cell<ViewRecord, unknown>;
  column: ViewWithTableColumn;
}

export function RecordCellContent({ cell, table, column }: RecordCellContentProps) {
  const { sourceTable } = useViewContext<ViewWithTable>();

  const recordValues = cell.row.original;
  const recordRawValues = table.options.meta?.rawValues[cell.row.index];

  const fieldKey = column.field.key;
  const field = sourceTable?.fields.find((f) => f.key === fieldKey);

  const sanitizedValue = useMemo(
    () => sanitizeHTML(recordValues[fieldKey]),
    [fieldKey, recordValues]
  );

  const isCharacterLimitExceeded = Boolean(
    column.truncate &&
      column.character_limit &&
      sanitizedValue &&
      sanitizedValue.length > column.character_limit
  );

  // Identify new columns to render a placeholder cell when adding new columns in the page editor
  if (isIframedByBuilder()) {
    const columnKey = column.field?.key;
    const associatedColumn = recordValues[columnKey];

    if (associatedColumn === undefined) {
      return '-';
    }
  }

  if (field?.type === 'rating') {
    return (
      <Rating
        ratingNumber={Number(recordValues[fieldKey])}
        maxRatingNumber={parseInt(field.format.max, 10)}
        allowHalf={field.format.allow_half}
      />
    );
  }

  if (field?.type === 'connection' && column.conn_link) {
    return (
      <ViewRecordConnectionLink
        rawValues={recordRawValues}
        fieldKey={fieldKey}
        connLink={column.conn_link}
        connSeparator={column.conn_separator}
      />
    );
  }

  if (field?.type === 'file') {
    return <ViewRecordFileField rawValues={recordRawValues} fieldKey={fieldKey} />;
  }

  return (
    <DefaultRecordCellContent
      value={sanitizedValue}
      isCharacterLimitExceeded={isCharacterLimitExceeded}
      characterLimit={column.character_limit}
    />
  );
}
